/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'

import {createBrowserHistory} from 'history'
import Swal from 'sweetalert2'
import {createReferal, login} from '../../../../modules/auth/core/_requests'
import {notification} from 'antd'
type Props = {
  closeCreateRentalModal: (arg: any) => void
}
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

const initialValues = {
  email: '',
  referalCode: '',
  role: '',
  name: '',
}

const Invite: React.FC<Props> = ({closeCreateRentalModal}) => {
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState('')
  // const {saveAuth, setCurrentUser} = useAuth()
  const browserHistory = createBrowserHistory()
  initialValues.referalCode = (Math.floor(Math.random() * 10000000) + 9).toString()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // let dat.a
      values.role = role
      try {
        const data = await createReferal(values)
        // console.log(data.data.data.token)

        setLoading(false)
        notification.success({
          message: data.data.message,
          onClick: () => {
            console.log('Notification Clicked!')
          },
          duration: 4,
        })
        closeCreateRentalModal(false)

        // localStorage.setItem('role', data.data.user.role)
      } catch (e: any) {
        // console.log(e.response.data.message)
        notification.error({
          message: e.response.data.message,
          onClick: () => {
            console.log('Notification Clicked!')
          },
          duration: 4,
        })

        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-left mb-11'>
        <h1 className='text-dark fw-bolder mb-1'>Invite Team Members</h1>
        <p className='text-muted'>
          If you need more info, please check out<a> FAQ Page</a>
        </p>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.name && formik.errors.name},
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
          type='text'
          name='name'
          autoComplete='off'
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.name}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div>
        <label className='col-form-label fw-bold fs-6'>
          <span className=''>Role</span>
        </label>
        <select
          name='role'
          className='form-select form-select-lg form-select-solid'
          onChange={(event) => setRole(event?.target.value)}
        >
          <option value=''>Choose Role</option>
          <option value='Customer'>Customer</option>

          <option value='Broker'>Broker</option>
          <option value='Employee'>Employee</option>
        </select>
      </div>
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Referal Code</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('referalCode')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.referalCode && formik.errors.referalCode},
            {
              'is-valid': formik.touched.referalCode && !formik.errors.referalCode,
            }
          )}
          type='text'
          name='referalCode'
          autoComplete='off'
        />
        {formik.touched.referalCode && formik.errors.referalCode && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.referalCode}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-flex my-5 gap-4'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-dark'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Send Invite</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='col-md-6'>
          <button type='button' className='btn btn-dark' onClick={closeCreateRentalModal}>
            <span className='indicator-label'>Cancel</span>
          </button>
        </div>
      </div>
      {/* end::Action */}
    </form>
  )
}
export {Invite}
