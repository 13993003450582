import {useEffect, useState} from 'react'
import {ConnectedAccounts} from '../../../../../modules/accounts/components/settings/cards/ConnectedAccounts'
import {DeactivateAccount} from '../../../../../modules/accounts/components/settings/cards/DeactivateAccount'
import {Notifications} from '../../../../../modules/accounts/components/settings/cards/Notifications'
import {getAllBrokerById, getAllCustomerById} from '../../../../../modules/auth/core/_requests'
import {AllLocation} from './alllocation'
import {AllPayment} from './allPayment'
import {ProfileDetails} from './ProfileDetails'
import {Prices} from '../../prices'

const CustomerProfileOverview = () => {
  const [customer, setCustomer] = useState<any>(null)

  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('user')
    console.log(user)
    if (user != null) {
      let dataTemp = JSON.parse(user)
      //  setUser(dataTemp)

      const data = await getAllCustomerById(dataTemp._id)

      // console.log(data)
      setCustomer(data.data.customer)
      // setData(data.data?.customers)
    }
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  return (
    <>
      {/* customer={customer} */}
      <ProfileDetails customer={customer} />
      <Prices customer={customer} />
      <AllPayment customer={customer} />
      <AllLocation customer={customer} />
      {/* <Notifications customer={customer} /> */}
      {/* <DeactivateAccount customer={customer} title='Delete Company' /> */}
    </>
  )
}

export default CustomerProfileOverview
