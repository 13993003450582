/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC, useState} from 'react'
import {Card, Modal} from 'react-bootstrap'
import Pagination from 'react-responsive-pagination'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {updateTrip} from '../../../../modules/auth/core/_requests'
import Spinner from '../../../brokers/components/BrokerTable/Spinner'
import {createBrowserHistory} from 'history'
type Props = {
  className: string
  data: any
  actual: any
  loading: boolean
  handlePageChange: (arg: number) => void
  activePage: number
}
const companyName = localStorage.getItem('companyName') as string
const AllTripsTable: FC<Props> = ({
  className,
  data,
  loading,
  handlePageChange,
  activePage,
  actual,
}) => {
  const browserHistory = createBrowserHistory()
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)
  const [ids, setId] = useState('')
  const [millage, setMillage] = useState('')
  const [status, setStatus] = useState('')
  const handleChange = (id: any) => {
    localStorage.setItem('id', id)
    browserHistory.push(`/${companyName}/trips/details`)
    window.location.reload()
  }
  const openCreateRentalModal = (id: any) => {
    setShowCreateRentalModal(true)
    setId(id)
  }
  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
    setId('')
  }
  const UpdateRental = async () => {
    // setLoading(true)
    let values = {
      millage: millage,
      status: status,
    }
    try {
      const {
        data: {data, message},
      } = await updateTrip(ids, values)

      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // actions.resetForm()
      closeCreateRentalModal()
      window.location.reload()
    } catch (error) {
      // console.error(error)
      Swal.fire({
        timer: 3000,
        text: 'Booking Not Added',
        icon: 'error',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }
  const download = (data: any) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], {type: 'text/csv'})

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')

    // Passing the blob downloading url
    a.setAttribute('href', url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'Trips.csv')

    // Performing a download with click
    a.click()
  }

  const csvmaker = (data: any) => {
    // console.log(data)

    let csvRows: any[] = []

    // const headers = Object.keys(data[0]);

    // csvRows.push(headers.join(','));
    const csvString = [
      [
        'Id',
        'status',
        'vehicle',
        'customer',
        'start Date',
        'end Date',
        'location',
        'millage',
        'total Cost',
        'createdAt',
      ],
      ...data.map((item: any) => [
        item._id,
        item.status,
        item.vechicleId?.year + item.vechicleId?.vehicleType,
        item.customerId.accountName,
        item.selectedStartDate,
        item.selectedEndDate,
        item.location,
        item.millage,
        item.createdAt,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n')

    // console.log(csvString);
    csvRows.push(csvString)
    return csvRows.join('\n')
  }
  const downloadCsv = () => {
    const csvdata = csvmaker(data)
    download(csvdata)
  }
  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-2 mb-1'>Most Recent Trips</span>
          </h3>
          <div className='card-toolbar'>
            <div className='me-4'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select text-gray-500 form-select-sm form-select-white bg-light w-125px'
                defaultValue='Active'
              >
                <option value='Active'>This Month</option>
                {/* <option value='Approved'>In Progress</option>
                <option value='Declined'>To Do</option>
                <option value='In Progress'>Completed</option> */}
              </select>
            </div>

            {/* begin::Menu */}
            <button
              onClick={downloadCsv}
              type='button'
              className='btn btn-sm btn-primary '
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              Download Report
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {/* begin::Table */}
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <div className='table-responsive'>
              {data.length === 0 ? (
                <Card style={{borderRadius: '8px'}}>
                  <Card.Body>
                    <Card.Text>
                      <h4>No Trips Available</h4>
                      <p className='text-muted'>
                        <a href='#' className='text-muted me-2 fw-bold' id='kt_user_follow_button'>
                          <span className='indicator-label'>
                            You can add Trips by clicking here{' '}
                            <Card.Link
                              href={`/${companyName}/vehicle/add-Rental`}
                              className=' px-4'
                            >
                              Create Vehicle
                            </Card.Link>
                          </span>
                        </a>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold bg-light'>
                      <th>Status</th>
                      <th>Vehicle</th>
                      <th>Customer</th>
                      <th>Timespan</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}

                  <tbody>
                    {data.map((item: any) => (
                      <tr onClick={() => handleChange(item._id)}>
                        <td>
                          <span className='badge badge-light-success'>{item.status}</span>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item.vechicleId?.year}&nbsp; {item.vechicleId?.make}{' '}
                            {item.vechicleId?.model}
                          </a>
                        </td>
                        <td>
                          <p className='text-dark fw-bold d-block mb-1 fs-6'>
                            {item.customerId?.accountName}
                          </p>
                        </td>
                        <td>
                          <p className='text-dark fw-bold d-block mb-1 fs-6'>
                            {moment(item.selectedStartDate).format('MMM Do YYYY')} to{' '}
                            {moment(item.selectedEndDate).format('MMM Do YYYY')}
                          </p>
                        </td>
                        <td className='d-flex flex-row justify-content-between align-items-center text-dark fw-bold'>
                          {/* <p className='d-inline-block text-truncate m-0'>{item.location}</p> */}
                          <Link
                            to='#'
                            onClick={() => openCreateRentalModal(item._id)}
                            className='btn btn-sm btn-dark me-3'
                          >
                            Action
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
          )}
          {/* end::Table */}
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}
      <div className='d-flex flex-stack flex-wrap'>
        <Pagination
          current={activePage}
          // itemsCountPerPage={10}
          // total={actual.length}
          total={Math.ceil(actual?.length / 10)}
          // pageRangeDisplayed={5}
          onPageChange={handlePageChange}
        />
      </div>
      {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModal} size='sm' centered>
          <Modal.Body>
            <div className='mb-3 fv-row'>
              <label className='form-label mb-3'>Milleage Covered</label>
              <input
                type='number'
                onChange={(event) => setStatus(event?.target.value)}
                // value='11,118 mi'
                className='form-control form-control-lg form-control-solid'
                name='milleage'
              />
            </div>
            <label className='form-label mb-3'>Update Status</label>
            <select
              className='form-select form-select-lg form-select-solid'
              onChange={(event) => setStatus(event?.target.value)}
            >
              <option value=''>Choose Mode</option>
              <option value='Booked'> Booked</option>
              <option value='Completed'>Completed</option>
              <option value='In Progress'>In Progress</option>
              <option value='Cancelled'>Cancelled</option>
            </select>
            <div className='mt-3 fv-row'>
              <Link to='#' onClick={UpdateRental} className='btn btn-sm btn-dark me-3'>
                Edit
              </Link>

              <Link to='#' onClick={closeCreateRentalModal} className='btn btn-sm btn-dark me-3'>
                Close
              </Link>
            </div>
            {/* <CreateRental /> */}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default AllTripsTable
