import React, {FC} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
type Props = {
  values: any
}
const Step2: FC<Props> = ({values}) => {
  console.log(values)
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Account Setting</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            FAQ Page
          </a>
          .
        </div>
      </div>

      {values.values.paymentType === 'wireTransfer' ? (
        <>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Name</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='name' />
            </div>
          </div>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Account Number</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='accountNumber'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='accountNumber' />
            </div>
          </div>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Routing Number</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='routing'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='routing' />
            </div>
          </div>

          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Address</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='address'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='address' />
            </div>
          </div>
        </>
      ) : values.values.paymentType === 'stripe' ? (
        <>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Your Stripe Publisher Key</label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='publisherKey'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='publisherKey' />
            </div>
          </div>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Your Stripe Secret Key</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='secretKey'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='secretkey' />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Name</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='name' />
            </div>
          </div>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Instructions</label>

            <Field
              type='text'
              as='textarea'
              row={4}
              className='form-control form-control-lg form-control-solid'
              name='instruction'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='instruction' />
            </div>
          </div>
          <div className='mb-10 fv-row'>
            <label className='form-label mb-3'>Billing Address</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='billingAddress'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='billingAddress' />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export {Step2}
