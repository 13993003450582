import {ErrorMessage, Field} from 'formik'
import {FC} from 'react'

const Step2: FC = () => {
  return (
    <div className='w-100'>
      <div className='text-gray-400 fw-bold fs-6  mb-5' style={{textAlign: 'right'}}>
        Having Issues?
        <a href='/dashboard' className='link-primary fw-bolder'>
          {' '}
          Get Help
        </a>
        .
      </div>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Company Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Already Have Account
          <a href='/login' className='link-primary fw-bolder'>
            {' '}
            Sign In
          </a>
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className=''>Company Name</span>
            </label>
            <Field
              type='text'
              // value=''
              className='form-control form-control-lg form-control-solid'
              name='companyName'
            />
            {/* <div className='text-danger mt-2'>
          <ErrorMessage name='accountName' />
        </div> */}
          </div>
        </div>
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Category</span>
          </label>
          <Field
            type='text'
            // value=''
            className='form-control form-control-lg form-control-solid'
            name='companyCategory'
          />
          {/* <div className='text-danger mt-2'>
          <ErrorMessage name='accountName' />
        </div> */}
        </div>
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Company Website</span>
          </label>
          <Field
            type='text'
            // value=''
            className='form-control form-control-lg form-control-solid'
            name='companysite'
          />
          {/* <div className='text-danger mt-2'>
          <ErrorMessage name='accountName' />
        </div> */}
        </div>

        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Fleet Size</span>
          </label>
          <Field
            type='text'
            // value=''
            className='form-control form-control-lg form-control-solid'
            name='fleetSize'
          />
          {/* <div className='text-danger mt-2'>
          <ErrorMessage name='accountName' />
        </div> */}
        </div>
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>EIN (Optional)</span>
          </label>
          <Field
            type='text'
            // value=''
            className='form-control form-control-lg form-control-solid'
            name='EIN'
          />
          {/* <div className='text-danger mt-2'>
          <ErrorMessage name='accountName' />
        </div> */}
        </div>
      </div>
    </div>
  )
}

export {Step2}
