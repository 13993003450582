/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getAllVehicleId} from '../../../modules/auth/core/_requests'
import {CreateRental} from '../CreateRental'
function addCommas(nStr: string) {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}
const companyName = localStorage.getItem('companyName') as string
const VehicleDetailHeader: React.FC = () => {
  const location = useLocation()
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)

  const openCreateRentalModal = () => {
    // setShowCreateRentalModal(true)
  }
  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
  }

  const [customer, setCustomer] = useState<any>(null)
  const [role, setRole] = useState<any>('')
  const handleClick = (id: any) => {
    localStorage.setItem('id', id)
  }
  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('id')
    const roleUser = localStorage.getItem('user')
    if (roleUser != null) {
      let dataTemp = JSON.parse(roleUser as string)
      setRole(dataTemp?.role)
    }
    const data = await getAllVehicleId(user)
    // console.log(data)
    setCustomer(data.data.vehicle)
    // setData(data.data?.customers)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])

  // console.log('customer', customer)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        {/* <div className='d-flex flex-wrap flex-sm-nowrap mb-3'> */}
        <div className='row'>
          <div className='col-sm-2'>
            {' '}
            <div className='w-100 h-100' style={{objectFit: 'contain'}}>
              {customer?.gallery[0] !== '' ? (
                <img
                  src={customer?.gallery[0]}
                  className='w-100 h-100'
                  alt=''
                  style={{borderRadius: '1rem'}}
                />
              ) : (
                <img
                  src={'/media/placeholderimage.png'}
                  //   className='rounded-circle'
                  alt=''
                  className='w-100 h-100'
                  style={{borderRadius: '1rem'}}
                />
              )}
              {/* <img src={customer?.vehicleImg} alt='Metornic' />
              <img src={customer?.vehicleImg} alt='Metornic' /> */}
            </div>
          </div>
          <div className='col-sm-10'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-3 fw-bolder me-1'>
                      {customer?.title}
                      {/* 2022 Lamborgini Huracan */}
                    </a>
                    <span className={`badge badge-light-primary fs-7 fw-bold`}>
                      {' '}
                      {customer?.status}
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-7 mb-0 pe-2'>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                      VIN: {customer?.vin}
                    </p>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <Link
                    onClick={() => handleClick(customer._id)}
                    to={`/${companyName}/vehicle/edit-Vehicle`}
                    className='btn btn-sm btn-light bg-muted me-3'
                  >
                    Edit Vehicle
                  </Link>

                  {role !== 'Broker' && (
                    <Link
                      to={`/${companyName}/vehicle/add-Rental`}
                      onClick={openCreateRentalModal}
                      className='btn btn-sm btn-dark me-3'
                    >
                      Create Rental
                    </Link>
                  )}
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
                    {/* <div className='fs-6 fw-bolder text-gray-700'>
                      {moment(customer?.availibityStartDate).format('MMM Do YYYY')}
                    </div>
                    <div className='fw-bold text-gray-400'>Next Available Date</div> 
                  </div> */}

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                      <div className='fs-6 fw-bolder text-gray-700'>
                        {' '}
                        {addCommas(customer?.milleage)}
                      </div>
                      <div className='fw-bold text-gray-400'>Mileage</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* </div> */}

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/vehicle/details/overview' && 'active')
                }
                to='/vehicle/details/overview'
              >
                Overview
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/${companyName}/vehicle/details/overview` && 'active')
                }
                to={`/${companyName}/vehicle/details/overview`}
              >
                Overview
              </Link>
            </li>
            {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/vehicle/details/availability' && 'active')
                }
                to='/vehicle/details/availability'
              >
                Availability
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/${companyName}/vehicle/details/gallery` && 'active')
                }
                to={`/${companyName}/vehicle/details/gallery`}
              >
                Gallery
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/${companyName}/vehicle/details/history` && 'active')
                }
                to={`/${companyName}/vehicle/details/history`}
              >
                History
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModal} size='xl'>
          <Modal.Header className='w-100'>
            <Modal.Title className='w-100'>
              <div className='d-flex flex-row align-items-center justify-content-between w-100'>
                <div className='d-flex align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo-dark.svg')}
                    className='h-15px'
                  />{' '}
                  &nbsp;
                </div>
                <div className='d-flex gap-3'>
                  <Button onClick={closeCreateRentalModal}>Cancel</Button>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateRental />
          </Modal.Body>
        </Modal>
      )} */}
    </div>
  )
}

export {VehicleDetailHeader}
