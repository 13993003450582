/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'

import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {updateProfilebyPayment} from '../../../../../modules/auth/core/_requests'
import {Horizontal} from './Horizontal'
import {HorizontalEdit} from './HorizontalEdit'
type Props = {
  customer: any
}

const AllPayment: React.FC<Props> = ({customer}) => {
  console.log(customer)
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)

  const openCreateRentalModal = () => {
    setShowCreateRentalModal(true)
  }
  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
  }
  const [showCreateRentalModalEdit, setShowCreateRentalModalEdit] = useState(false)

  const openCreateRentalModalEdit = () => {
    setShowCreateRentalModalEdit(true)
  }
  const closeCreateRentalModalEdit = () => {
    setShowCreateRentalModalEdit(false)
  }
  const [location, setLocation] = useState([])
  const [editLocationData, setEditLocationData] = useState(null)
  const [id, setId] = useState(0)
  const handleEdit = (row: any, index: number) => {
    setEditLocationData(row)
    setId(index)
    openCreateRentalModalEdit()
  }
  useEffect(() => {
    setLocation(customer?.paymentMethods)
  }, [])
  const handleDelete = async (id: any) => {
    let dataTemp
    const user = localStorage.getItem('user')
    console.log(user)
    if (user != null) {
      dataTemp = JSON.parse(user)
      //  setUser(dataTemp)
    }
    const locations = customer?.paymentMethods
    console.log(locations)
    const index = id
    locations.splice(index, 1)
    console.log(locations)
    setLocation(locations)
    let dataTo = {
      paymentMethods: locations,
    }
    try {
      const {
        data: {data, message},
      } = await updateProfilebyPayment(dataTemp._id, dataTo)
      // console.log(data)
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })

      //  browserHistory.push('/auth/welcomeScreen')
      window.location.reload()
    } catch (error) {}
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0 fs-4'>Payment Method</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          {customer?.paymentMethods.length === 0 ? (
            <img
              src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg'
              style={{margin: 'auto', display: 'block'}}
            />
          ) : (
            <>
              {customer?.paymentMethods.map((trip: any, index: number) => (
                <div
                  className='d-flex justify-content-between'
                  style={{border: ' 1px dotted lightgray', margin: '2rem'}}
                >
                  <div className='card-body border-top p-9'>
                    <div className='d-flex '>
                      {' '}
                      <h4 className='fs-4'>{trip.paymentType}</h4>&nbsp;&nbsp;&nbsp;
                      {index === 0 && <a className='    text-success me-2'>Primary</a>}
                    </div>
                    {trip.paymentType === 'WireTransfer' ? (
                      <>
                        {' '}
                        <span className='text-muted'>{trip.accountNumber}</span>
                        <div className='d-flex'>
                          {' '}
                          <span className='text-muted'>Checking Account</span>
                          {/* <span className='text-muted'>{trip.zipCode}</span> */}
                        </div>
                      </>
                    ) : trip.paymentType === 'Stripe' ? (
                      <>
                        <span className='text-muted'>{trip.accountNumber}</span>
                        <div className='d-flex justify-content-between'>
                          {' '}
                          <p className='text-muted'>Publisher Key</p>
                          <p className='text-muted'>{trip.publisherKey}</p>
                        </div>
                        <div className='d-flex justify-content-between'>
                          <p className='text-muted'>Secret Key</p>
                          {/* <p className='text-muted'>{trip.secretKey}</p> */}
                          <p className='text-muted'>***********</p>
                          {/* <span className='text-muted'>{trip.zipCode}</span> */}
                        </div>
                      </>
                    ) : (
                      <>
                        {' '}
                        <span className='text-muted'>{trip.instruction}</span>
                        <div className='d-flex'>
                          {' '}
                          {/* <span className='text-muted'>Checking Account</span> */}
                          {/* <span className='text-muted'>{trip.zipCode}</span> */}
                        </div>
                      </>
                    )}
                    {/* <span className='text-muted'>{trip.city}</span> */}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-muted   text-muted me-2'
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                    <button
                      className='btn btn-muted text-muted me-2'
                      onClick={() => handleEdit(trip, index)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
          <div className='card-footer d-flex justify-content-between py-6 px-9 align-item-center'>
            <div style={{float: 'left'}}>
              {' '}
              <p className='mb-1'>
                You have {customer?.paymentMethods.length} active accounts. You’ve reached the
                maximum amount
              </p>
              <p className='text-muted'>
                Extended Pro Package. Up to 100 Agents & 25 Projects. Make your business effective
              </p>
            </div>
            <button type='button' className='btn btn-primary h-100' onClick={openCreateRentalModal}>
              {'Add Payment '}
            </button>
          </div>
        </div>
      </div>
      {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModal} size='lg'>
          <Modal.Body>
            <Horizontal customer={customer} />
          </Modal.Body>
        </Modal>
      )}
      {showCreateRentalModalEdit && (
        <Modal show={showCreateRentalModalEdit} onHide={closeCreateRentalModalEdit} size='lg'>
          <Modal.Body>
            <HorizontalEdit
              closeCreateRentalModal={closeCreateRentalModalEdit}
              customer={customer}
              location={editLocationData}
              id={id}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
export {AllPayment}
