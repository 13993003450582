import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import Overview from './user'

const UserPageWizard = () => (
  <Routes>
    <Route
      element={
        <>
          {/* <LegalHeader /> */}
          <Outlet />
        </>
      }
    >
      <Route
        path='users'
        element={
          <>
            {/* <PageTitle breadcrumbs={profileBreadCrumbs}>Projects</PageTitle> */}
            <Overview />
          </>
        }
      />

      <Route index element={<Navigate to='/companys/users' />} />
    </Route>
  </Routes>
)

export default UserPageWizard
