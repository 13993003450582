/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {getAllCompanyById, resend} from '../../../../app/modules/auth/core/_requests'

import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'

export function HeaderWrapper() {
  const [user, setUser] = useState<any | null>(null)
  useEffect(() => {
    const data = localStorage.getItem('user')
    if (data != null) {
      let dataTemp = JSON.parse(data)
      setUser(dataTemp)
    }
  }, [])
  const [customer, setCustomer] = useState<any>([])

  const getAllCustomerByIds = async () => {
    const id = localStorage.getItem('companyName')
    const data = await getAllCompanyById(id)

    // console.log(data)
    setCustomer(data.data.customer)

    // setData(data.data?.customers)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  console.log(customer)
  const handleResend = async () => {
    const {
      data: {data, message},
    } = await resend(user.email)
    // console.log(data)
    Swal.fire({
      timer: 3000,
      text: message,
      icon: 'success',
      showCloseButton: false,
      showConfirmButton: false,
      showDenyButton: false,
    })
  }

  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <>
      {user != null
        ? user.status != 'Active' &&
          user.role != 'Admin' && (
            <h5
              style={{
                padding: '2rem',
                marginTop: '6rem',
                marginBottom: '-5rem',
                background: 'lightgrey',
              }}
            >
              Please Verify your Account. Check your Email. Don't Recieved?{' '}
              <a onClick={handleResend} className='menu-link px-5'>
                Resend Email
              </a>
            </h5>
          )
        : ''}
      <div
        id='kt_app_header'
        className='d-flex flex-row justify-content-around app-header bg-black'
      >
        <div
          id='kt_app_header_container'
          className={clsx(
            'app-container',
            classes.headerContainer.join(' '),
            config.app?.header?.default?.containerClass
          )}
        >
          {config.app.sidebar?.display && (
            <>
              <div
                className='d-flex align-items-center ms-n2 me-4'
                title='Show sidebar menu'
                style={{backgroundColor: '#212121'}}
              >
                {/* <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px d-lg-none'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
              </div> */}
                <div
                  className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'
                  style={{backgroundColor: '#212121'}}
                >
                  {customer.length > 0 ? (
                    <Link to='/dashboard'>
                      <img
                        alt='Logo'
                        src={customer[0]?.profileImg}
                        className='h-30px '
                        style={{backgroundColor: '#212121'}}
                      />
                    </Link>
                  ) : (
                    // <img alt='Logo'  className='h-100px mb-5' />

                    <Link to='/dashboard'>
                      {' '}
                      <img
                        alt='Logo'
                        style={{backgroundColor: '#212121'}}
                        src={toAbsoluteUrl('/media/logos/Logo.png')}
                        className='h-30px mb-5'
                      />
                    </Link>
                  )}
                </div>
              </div>
            </>
          )}

          {!config.app.sidebar?.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
              <Link to='/dashboard'>
                {config.layoutType !== 'dark-header' ? (
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default'
                  />
                ) : (
                  <>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/default.svg')}
                      className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                    />
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                      className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                    />
                  </>
                )}
              </Link>
            </div>
          )}

          <div
            id='kt_app_header_wrapper'
            className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
          >
            {config.app.header.default?.content === 'menu' &&
              config.app.header.default.menu?.display && (
                <div
                  className='app-header-menu app-header-mobile-drawer align-items-stretch'
                  data-kt-drawer='true'
                  data-kt-drawer-name='app-header-menu'
                  data-kt-drawer-activate='{default: true, lg: false}'
                  data-kt-drawer-overlay='true'
                  data-kt-drawer-width='225px'
                  data-kt-drawer-direction='end'
                  data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                  data-kt-swapper='true'
                  data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                  data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                >
                  <Header />
                </div>
              )}
          </div>
        </div>

        <Navbar />
      </div>
    </>
  )
}
