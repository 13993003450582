import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {updateProfile} from '../../../../auth/core/_requests'
import {
  INotificationAccount,
  INotifications,
  notifications as initialValues,
} from '../SettingsModel'

type Props = {
  customer: any
}

const Notifications: React.FC<Props> = ({customer}) => {
  // console.log(customer?.notification)
  // initialValues.notification =
  const [data, setData] = useState<INotificationAccount>(customer?.notification)

  const updateData = (fieldsToUpdate: Partial<INotificationAccount>) => {
    // console.log('ddk')
    // console.log(fieldsToUpdate)
    const updatedData = {...data, ...fieldsToUpdate}
    // console.log(updatedData)
    setData(updatedData)
  }
  // console.log(data)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    // console.log(notifications)
    // initialValues = customer?.accountsConnected
    setData(customer?.notification)
  }, [customer?.notification])
  console.log('notification', data)
  const click = async () => {
    setLoading(true)
    const user = localStorage.getItem('id')
    // console.log(values)
    let values = {
      notification: data,
    }

    try {
      const {
        data: {data, message},
      } = await updateProfile(user, values)
      // console.log(data)
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      setLoading(false)
      //  browserHistory.push('/auth/welcomeScreen')
      //  window.location.reload()
    } catch (e: any) {
      // stepper.current.goNext()
      // console.error(e:)
      Swal.fire({
        timer: 3000,
        text: e.response.data.message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_notifications'
        aria-expanded='true'
        aria-controls='kt_account_notifications'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Notifications</h3>
        </div>
      </div>

      <div id='kt_account_notifications' className='collapse show'>
        <form className='form'>
          <div className='card-body border-top px-9 pt-3 pb-4'>
            <div className='table-responsive'>
              <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
                <tbody className='fs-6 fw-bold'>
                  <tr>
                    <td className='min-w-250px fs-4 fw-bolder'>
                      Notifications {data?.newsletter?.email as boolean}
                    </td>
                    <td className='w-125px'>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          // value={}
                          id='kt_settings_notification_email'
                          defaultChecked={data?.newsletter?.email as boolean}
                          onChange={() =>
                            updateData({
                              newsletter: {
                                phone: data?.newsletter?.phone,
                                email: !data?.newsletter?.email,
                              },
                            })
                          }
                        />
                        <label
                          className='form-check-label ps-2'
                          htmlFor='kt_settings_notification_email'
                        >
                          Email
                        </label>
                      </div>
                    </td>
                    <td className='w-125px'>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='kt_settings_notification_phone'
                          defaultChecked={data?.newsletter?.phone as boolean}
                          onChange={() =>
                            updateData({
                              newsletter: {
                                phone: data?.newsletter?.phone,
                                email: !data?.newsletter?.email,
                              },
                            })
                          }
                        />
                        <label
                          className='form-check-label ps-2'
                          htmlFor='kt_settings_notification_phone'
                        >
                          Phone
                        </label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Trip Details</td>
                    <td>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          id='billing1'
                          defaultChecked={data?.tripDetails?.email as boolean}
                          onChange={() =>
                            updateData({
                              tripDetails: {
                                phone: data?.tripDetails?.phone,
                                email: !data?.tripDetails?.email,
                              },
                            })
                          }
                        />
                        <label className='form-check-label ps-2' htmlFor='billing1'>
                          Email
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='billing2'
                          defaultChecked={data?.tripDetails?.phone as boolean}
                          onChange={() =>
                            updateData({
                              tripDetails: {
                                phone: data?.tripDetails?.phone,
                                email: !data?.tripDetails?.email,
                              },
                            })
                          }
                        />
                        <label className='form-check-label ps-2' htmlFor='billing2'>
                          Phone
                        </label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Billing Updates</td>
                    <td>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          // value={data?.notification?.billingUpdates?.email}
                          id='team1'
                          defaultChecked={data?.billingUpdates?.email as boolean}
                          onChange={() =>
                            updateData({
                              billingUpdates: {
                                phone: data?.billingUpdates?.phone,
                                email: !data?.billingUpdates?.email,
                              },
                            })
                          }
                        />
                        <label className='form-check-label ps-2' htmlFor='team1'>
                          Email
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='team2'
                          defaultChecked={data?.billingUpdates?.phone as boolean}
                          onChange={() =>
                            updateData({
                              billingUpdates: {
                                phone: !data.billingUpdates.phone,
                                email: data.billingUpdates.email,
                              },
                            })
                          }
                        />
                        <label className='form-check-label ps-2' htmlFor='team2'>
                          Phone
                        </label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Aggrement Services Details</td>
                    <td>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='project1'
                          defaultChecked={data?.aggrementServices?.email as boolean}
                          onChange={() =>
                            updateData({
                              aggrementServices: {
                                phone: data?.aggrementServices?.phone,
                                email: !data?.aggrementServices?.email,
                              },
                            })
                          }
                        />
                        <label className='form-check-label ps-2' htmlFor='project1'>
                          Email
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='project2'
                          defaultChecked={data?.aggrementServices?.phone as boolean}
                          onChange={() =>
                            updateData({
                              aggrementServices: {
                                phone: !data?.aggrementServices?.phone,
                                email: data?.aggrementServices?.email,
                              },
                            })
                          }
                        />

                        <label className='form-check-label ps-2' htmlFor='project2'>
                          Phone
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className='btn btn-transparent text-muted me-2'>Discard</button>
            <button type='button' onClick={click} className='btn btn-primary'>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {Notifications}
