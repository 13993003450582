/* eslint-disable jsx-a11y/anchor-is-valid */
import {CaretRightOutlined, MinusCircleFilled, PlusCircleFilled} from '@ant-design/icons'
import {Collapse} from 'antd'

import {useEffect, useState} from 'react'
import {getAllVehicleId} from '../../auth/core/_requests'
import {DeactivateAccount} from '../../accounts/components/settings/cards/DeactivateAccount'
import {DeactivateVehicle} from '../../accounts/components/settings/cards/DeactivateVehicle'
const {Panel} = Collapse
const panelStyle = {
  marginBottom: 24,
  background: 'white',
  borderRadius: 'white',
  border: 'none',
  fontSize: '16px',
  lineHeight: '32px',
  fontWeight: 'bold',
}
export function Connections() {
  const [data, setData] = useState<any>([])
  const [addon, setAddon] = useState<any>([])
  const [user, setUser] = useState<any>(null)
  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('id')
    // console.log(user)
    // if (user != null) {
    // let dataTemp = JSON.parse(user)
    //  setUser(dataTemp)

    const data = await getAllVehicleId(user)
    console.log(data)
    setAddon(data.data.vehicle.addon)
    let array: any[] = []
    let images = data.data.vehicle
    console.log(images)
    setData(images)

    // setCustomer(data.data.vehicle)
    // setData(data.data?.customers)
  }
  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user != null) {
      let dataTemp = JSON.parse(user)
      console.log(dataTemp)
      setUser(dataTemp)
    }

    // setArr(inputArr)
    getAllCustomerByIds()
  }, [])
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='mb-3'>
            <div className='row mb-6'>
              {' '}
              <h1 className='fs-3'>Description</h1>
              <p className='fs-7'>{data?.description}</p>
            </div>
            <div className='row mb-6'>
              <div className='col-sm-6'>
                <h1 className='fs-3 mb-9'>Features</h1>
                {data?.feature?.split(',')?.map((item: any) => (
                  <h5 className='mb-9 fs-7'>{item}</h5>
                ))}
              </div>
              <div className='col-sm-6'>
                {' '}
                <h1 className='fs-3 mb-3'>Additional Services</h1>
                {addon?.map((item: any, index: string) => {
                  let price = '$' + item.price + '.00'
                  const header = item.title + '  ' + price
                  return (
                    <Collapse
                      defaultActiveKey={[index]}
                      // size='large'
                      bordered={false}
                      // accordion={true}
                      expandIcon={({isActive}) =>
                        !isActive ? (
                          <PlusCircleFilled style={{fontSize: '23px', color: 'lightgray'}} />
                        ) : (
                          <MinusCircleFilled style={{fontSize: '23px', color: 'lightblue'}} />
                        )
                      }
                    >
                      <Panel header={header} key={index} style={panelStyle}>
                        <p
                          style={{
                            fontWeight: 'normal',
                            fontSize: '14px',
                            marginLeft: '3rem',
                            color: '#7e8299',
                          }}
                        >
                          {item.description}
                        </p>
                      </Panel>
                    </Collapse>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeactivateVehicle title='Remove Vehicle' />
    </>
  )
  // <ProfileDetails />
}
