import {useState, CSSProperties, FC} from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: '#00A3FF',
}

interface Props {
  loading: boolean
}

const Spinner: FC<Props> = ({loading}) => {
  //   let [loading, setLoading] = useState(true)
  let [color, setColor] = useState('#00A3FF')

  return (
    <div className='sweet-loading p-3'>
      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={50}
        aria-label='Loading Spinner'
        data-testid='loader'
      />
    </div>
  )
}

export default Spinner
