import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {AddNewVehicle} from './AddVehicle'
import Overview from './Overview'
import {DetailTrips} from './Detail'

const TripsPage = () => (
  <Routes>
    <Route
      path={`overview`}
      element={
        <>
          <Overview />
        </>
      }
    />
    <Route
      path={`details`}
      element={
        <>
          <DetailTrips />
        </>
      }
    />

    <Route index element={<Navigate to={`/overview`} />} />
  </Routes>
)

export {TripsPage}
