/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../../_metronic/helpers'
// import {getCSSVariableValue} from '../../../../assets/ts/_utils'
// import {Dropdown1} from '../../content/dropdown/Dropdown1'
// import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
//
type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget7: React.FC<Props> = ({className, chartColor}) => {
  const [paymentMethod, setPayment] = useState('')
  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('user')
    console.log(user)
    if (user != null) {
      let dataTemp = JSON.parse(user)
      //  setUser(dataTemp)
      setPayment(dataTemp.payment)

      // setData(data.data?.customers)
    }
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  return (
    <div className='row g-5 g-xl-12  bg-white'>
      <div className='text-center'>
        <h4>Choose Your Plan</h4>
        <p>
          Save thousands to millions of bucks by using<a> Contact Our Support</a>
        </p>
      </div>
      {/* begin::Col */}
      <div className='col-xl-4'>
        <div className={`    ${className}`}>
          {/* begin::Beader */}
          <div className='card-header border-0 py-5 text-center'>
            <h3 className='card-title align-items-start flex-column'>
              <h4 className='card-label fw-bold fs-3 mb-1'>Startup</h4>
              <span className='text-muted fw-semibold fs-7'>Best Settings for Startups</span>{' '}
              <p style={{fontSize: '22px', marginTop: '1rem', color: 'blue'}}>
                <span className='text-muted fw-semibold fs-7'>$</span> 99{' '}
                <span className='text-muted fw-semibold fs-7'>/&nbsp;Mon</span>
              </p>
            </h3>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body d-flex flex-column'>
            <div className='pt-5'>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Up to 10 Active Users</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Up to 30 Project Integrations</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Keen Analytics Platform</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Error.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                {' '}
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Targets Timelines & Files</span>
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Error.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Unlimited Projects</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Error.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
            </div>
            <a href='#' className={`btn btn-${chartColor} align-self-center`}>
              {paymentMethod === 'personal' ? 'Selected' : 'Select'}
            </a>
          </div>
          {/* end::Body */}
        </div>
      </div>
      <div className='col-xl-4'>
        <div className={`    ${className}`}>
          {/* begin::Beader */}
          <div className='card-header border-0 py-5 text-center'>
            <h3 className='card-title align-items-start flex-column'>
              <h4 className='card-label fw-bold fs-3 mb-1'>Business</h4>
              <span className='text-muted fw-semibold fs-7'>Best Settings for Business</span>{' '}
              <p style={{fontSize: '22px', marginTop: '1rem', color: 'blue'}}>
                <span className='text-muted fw-semibold fs-7'>$</span> 199{' '}
                <span className='text-muted fw-semibold fs-7'>/&nbsp;Mon</span>
              </p>
            </h3>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body d-flex flex-column'>
            <div className='pt-5'>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Up to 10 Active Users</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Up to 30 Project Integrations</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Keen Analytics Platform</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                {' '}
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Targets Timelines & Files</span>
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Unlimited Projects</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Error.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
            </div>
            <a href='#' className={`btn btn-${chartColor} align-self-center`}>
              {paymentMethod === 'bussiness' ? 'Selected' : 'Select'}
            </a>
          </div>
          {/* end::Body */}
        </div>
      </div>
      <div className='col-xl-4'>
        <div className={`    ${className}`}>
          {/* begin::Beader */}
          <div className='card-header border-0 py-5 text-center'>
            <h3 className='card-title align-items-start flex-column'>
              <h4 className='card-label fw-bold fs-3 mb-1'>Enterprise</h4>
              <span className='text-muted fw-semibold fs-7'>Best Settings for Enterprise</span>{' '}
              <p style={{fontSize: '22px', marginTop: '1rem', color: 'blue'}}>
                <span className='text-muted fw-semibold fs-7'>$</span> 999{' '}
                <span className='text-muted fw-semibold fs-7'>/&nbsp;Mon</span>
              </p>
            </h3>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body d-flex flex-column'>
            <div className='pt-5'>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Up to 10 Active Users</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Up to 30 Project Integrations</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Keen Analytics Platform</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                {' '}
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Targets Timelines & Files</span>
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
              <p className='text-center fs-6 '>
                <div className='row '>
                  <div className='col-sm-10'>
                    <span>Unlimited Projects</span>{' '}
                  </div>
                  <div className='col-sm-2'>
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/art/Done.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </p>
            </div>
            <a href='#' className={`btn btn-${chartColor} align-self-center`}>
              {paymentMethod === 'enterprice' ? 'Selected' : 'Select'}
            </a>
          </div>
          {/* end::Body */}
        </div>
      </div>
    </div>
  )
}

export {MixedWidget7}
