import * as Yup from 'yup'

export interface ICreateAccount {
  payment: string
  accountName: string
  email: string
  confPassword: string
  password: string
  phone: string
  referalCode: string
  companysite: string
  role: string
  companyCode: string
  id: string
  status: string
  jobTitle: string
  dateofBirth: string
  companyName: string
  companyCategory: string
  fleetSize: string
  EIN: string
  paymentMethodSelected: string | undefined
  nameOnCard: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
}

const createAccountSchemas = [
  Yup.object().shape({
    // accountName: Yup.string().required('Account Name'),
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    password: Yup.string()
      .required('Password')
      .min(3, 'Minimum 3 symbols')
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      // )
      .max(50, 'Maximum 50 symbols'),
    confPassword: Yup.string().when('password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    }),
    // phone: Yup.string().required('Phone Number'),
    // role: Yup.string().required('Role'),
    // referalCode: Yup.string().required('referalCode'),
    // jobTitle: Yup.string().required('Job Title'),
  }),
]

const inits: ICreateAccount = {
  payment: '',
  accountName: '',
  email: '',
  id: '',
  password: '',
  confPassword: '',
  phone: '',
  role: '',
  companyCode: '',
  jobTitle: '',
  companyName: '',
  referalCode: '',
  dateofBirth: '',
  EIN: '',
  companysite: '',
  paymentMethodSelected: '',
  companyCategory: '',
  fleetSize: '',
  status: 'not Active',
  nameOnCard: '',
  cardNumber: '',
  cardExpiryMonth: '',
  cardExpiryYear: '',
  cardCvv: '',
}

export {createAccountSchemas, inits}
