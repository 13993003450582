import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Connections} from '../../../modules/profile/components/Connections'
import {Projects} from '../../../modules/profile/components/Projects'

import {CustomerProfileHeader} from './CustomerProfileHeader'
import Documents from './Documents'
import CustomerProfileOverview from './Overview'
const companyName = localStorage.getItem('companyName') as string
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Customers',
    path: '/customer/overview',
    isSeparator: false,
    isActive: false,
  },
]

const CustomerProfilePage = ({customer}: any) => (
  <Routes>
    <Route
      element={
        <>
          <CustomerProfileHeader />
          <Outlet />
        </>
      }
    >
      <Route
        path={`overview`}
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Customer Profile</PageTitle>
            <CustomerProfileOverview />
          </>
        }
      />
      <Route
        path={`activity`}
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Activity</PageTitle>
            <Projects />
          </>
        }
      />
      <Route
        path={`documents`}
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Customer Profile</PageTitle>
            <Documents />
          </>
        }
      />
      {/* <Route
        path='contact'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
            <Documents />
          </>
        }
      /> */}
      <Route
        path={`activity`}
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Connections</PageTitle>
            <Connections />
          </>
        }
      />
      <Route index element={<Navigate to={`/${companyName}/customer/profile/overview`} />} />
    </Route>
  </Routes>
)

export default CustomerProfilePage
