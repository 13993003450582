/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'

import {createBrowserHistory} from 'history'
import Swal from 'sweetalert2'
import {
  createReferal,
  login,
  updateProfileLocation,
} from '../../../../../modules/auth/core/_requests'
import {Modal} from 'react-bootstrap'
import {Location} from './location'
import {EditLocation} from './editLocation'
type Props = {
  customer: any
}

const AllLocation: React.FC<Props> = ({customer}) => {
  console.log(customer)
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)

  const openCreateRentalModal = () => {
    setShowCreateRentalModal(true)
  }
  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
  }
  const [showCreateRentalModalEdit, setShowCreateRentalModalEdit] = useState(false)

  const openCreateRentalModalEdit = () => {
    setShowCreateRentalModalEdit(true)
  }
  const closeCreateRentalModalEdit = () => {
    setShowCreateRentalModalEdit(false)
  }
  const [location, setLocation] = useState([])
  const [editLocationData, setEditLocationData] = useState(null)
  const [id, setId] = useState(0)
  const handleEdit = (row: any, index: number) => {
    setEditLocationData(row)
    setId(index)
    openCreateRentalModalEdit()
  }
  useEffect(() => {
    setLocation(customer?.locations)
  }, [])
  const handleDelete = async (id: any) => {
    let dataTemp
    const user = localStorage.getItem('user')
    console.log(user)
    if (user != null) {
      dataTemp = JSON.parse(user)
      //  setUser(dataTemp)
    }
    const locations = customer?.locations
    console.log(locations)
    const index = id
    locations.splice(index, 1)
    console.log(locations)
    setLocation(locations)
    let dataTo = {
      locations: locations,
    }
    try {
      const {
        data: {data, message},
      } = await updateProfileLocation(dataTemp._id, dataTo)
      // console.log(data)
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })

      //  browserHistory.push('/auth/welcomeScreen')
      window.location.reload()
    } catch (error) {
      // stepper.current.goNext()
      // console.error(error)
      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
    }
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Company Location</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          {customer?.locations.length === 0 ? (
            <img
              src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg'
              style={{margin: 'auto', display: 'block'}}
            />
          ) : (
            <>
              {customer?.locations.map((trip: any, index: number) => (
                <div
                  className='d-flex justify-content-between'
                  style={{border: ' 1px dotted lightgray', margin: '1rem'}}
                >
                  <div className='card-body border-top p-9'>
                    <div className='d-flex '>
                      {' '}
                      <h4>{trip.name}</h4>&nbsp;&nbsp;&nbsp;
                      {index === 0 && <a className='    text-success me-2'>Primary</a>}
                    </div>

                    <span className='text-muted'>{trip.address1}</span>
                    <div className='d-flex'>
                      {' '}
                      <span className='text-muted'>{trip.state}</span>&nbsp;,&nbsp;
                      <span className='text-muted'>{trip.zipCode}</span>
                    </div>

                    <span className='text-muted'>{trip.city}</span>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-muted   text-muted me-2'
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                    <button
                      className='btn btn-muted text-muted me-2'
                      onClick={() => handleEdit(trip, index)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
          <div className='card-footer d-flex justify-content-between py-6 px-9'>
            <div style={{float: 'left'}}> </div>
            <button type='button' className='btn btn-primary' onClick={openCreateRentalModal}>
              {'Add Location '}
            </button>
          </div>
        </div>
      </div>
      {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModal}>
          <Modal.Body>
            <Location closeCreateRentalModal={closeCreateRentalModal} customer={customer} />
          </Modal.Body>
        </Modal>
      )}
      {showCreateRentalModalEdit && (
        <Modal show={showCreateRentalModalEdit} onHide={closeCreateRentalModalEdit}>
          <Modal.Body>
            <EditLocation
              closeCreateRentalModal={closeCreateRentalModalEdit}
              customer={customer}
              location={editLocationData}
              id={id}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
export {AllLocation}
