/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {UsersListFilter} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListFilter'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {KTSVG} from '../../../helpers'
import {TablesWidget10} from './TablesWidget10'
import {TablesVehicle10} from './TableVehicle10'
import Pagination from 'react-responsive-pagination'
import {TablesVehicleList} from './TableVehicle10List'
import {Button} from 'react-bootstrap'

type Props = {
  className: string
  title?: string
  pagination?: boolean
  data: any
  length: number
  actual: any
  loading: boolean
  handlePageChange: () => void
  activePage: number
  handleFilter: (arg: number) => void
  searchTerm: string
  setSearchTerm: (arg: string) => void
}
// let enabled = 1
const TablesVehicle: React.FC<Props> = ({
  className,
  pagination,
  title,
  length,
  data,
  loading,
  actual,
  handlePageChange,
  activePage,
  handleFilter,
  searchTerm,
  setSearchTerm,
}) => {
  const [enabled, setEnabled] = useState(1)
  const download = (data: any) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], {type: 'text/csv'})

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')

    // Passing the blob downloading url
    a.setAttribute('href', url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'Vehicle.csv')

    // Performing a download with click
    a.click()
  }

  const csvmaker = (data: any) => {
    // console.log(data)

    let csvRows: any[] = []

    // const headers = Object.keys(data[0]);

    // csvRows.push(headers.join(','));
    const csvString = [
      [
        'Id',
        'vehicleType',
        'trim',
        'make',
        'year',
        'exteriorColor',
        'interiorColor',
        'model',
        'fuelType',
        'engine',
        'drivetrain',
        'transmission',
        'milleage',
        'insuranceProvider',
        'policyNumber',
        'coverageType',
        'expirationDate',
        'vin',
        'availibityStartDate',
        'availibityEndDate',
        'description',
        'status',
        'basePrice',
        'cleaningCost',
        // 'pricingOption',
      ],
      ...data.map((item: any) => [
        item._id,
        item.vehicleType,
        item.trim,
        item.make,
        item.year,
        item.exteriorColor,
        item.interiorColor,
        item.model,
        item.fuelType,
        item.engine,
        item.drivetrain,
        item.transmission,
        item.milleage,
        item.insuranceProvider,
        item.policyNumber,
        item.coverageType,
        item.expirationDate,
        item.vin,
        item.availibityStartDate,
        item.availibityEndDate,
        item.description,
        item.status,
        item.basePrice,
        item.cleaningCost,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n')

    // // console.log(csvString);
    csvRows.push(csvString)
    return csvRows.join('\n')
  }
  const downloadCsv = () => {
    const csvdata = csvmaker(data)
    download(csvdata)
  }
  const handleClick = (id: number) => {
    console.log(id)
    setEnabled(id)
    // console.log(enabled)
  }
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className='d-flex flex-row align-items-center justify-content-between w-100 w-md-auto gap-5'>
          <h3 className=''>
            {title}&nbsp;{length === 0 ? '' : length}
          </h3>
          <UsersListSearchComponent
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            className='bg-white w-md-425px py-2'
          />
        </div>

        <div className='d-flex w-100 w-sm-auto align-items-center my-2'>
          <button type='button' className='btn bg-white me-3 btn-sm' onClick={() => handleClick(1)}>
            {enabled === 1 ? (
              <KTSVG
                path='/media/icons/duotune/general/gen024.svg'
                className='svg-icon-2 mx-0 text-primary'
              />
            ) : (
              <KTSVG path='/media/icons/duotune/general/gen008.svg' className='svg-icon-2 mx-0' />
            )}
          </button>

          <button type='button' className='btn bg-white me-3 btn-sm' onClick={() => handleClick(2)}>
            {enabled === 2 ? (
              <KTSVG
                path='/media/icons/duotune/general/gen010.svg'
                className='svg-icon-2 mx-0 text-primary'
              />
            ) : (
              <KTSVG path='/media/icons/duotune/general/gen010.svg' className='svg-icon-2 mx-0' />
            )}
          </button>

          <UsersListFilter handleFilter={handleFilter} className='bg-white btn-sm' />

          {/* begin::Export */}
          <button type='button' className='btn bg-white btn-sm' onClick={downloadCsv}>
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Export
          </button>
          {/* end::Export */}
        </div>
      </div>
      {enabled === 1 ? (
        <TablesVehicle10 data={data} loading={loading} className='mb-5 mb-xl-8'></TablesVehicle10>
      ) : (
        <TablesVehicleList
          data={data}
          loading={loading}
          className='mb-5 mb-xl-8'
        ></TablesVehicleList>
      )}

      {/* {pagination && ( */}
      {/* <div className='d-flex flex-stack flex-wrap'> */}
      {/* <div className='d-flex flex-stack flex-wrap'> */}
      {actual.length > data.length && actual.length > 0 && (
        <Button className='w-100 bg-white text-black' onClick={handlePageChange}>
          Load More
        </Button>
      )}
      {/* <Pagination
            current={activePage}
            // itemsCountPerPage={10}

            total={Math.ceil(actual.length / 3)}
            // pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          /> */}
      {/* </div> */}
      {/* </div> */}
      {/* )} */}
    </>
  )
}

export {TablesVehicle}
