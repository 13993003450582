import {useEffect, useState} from 'react'
import {ConnectedAccounts} from '../../../../../modules/accounts/components/settings/cards/ConnectedAccounts'
import {DeactivateAccount} from '../../../../../modules/accounts/components/settings/cards/DeactivateAccount'
import {Notifications} from '../../../../../modules/accounts/components/settings/cards/Notifications'
import {getAllBrokerById} from '../../../../../modules/auth/core/_requests'
import {ProfileDetails} from './ProfileDetails'

const CustomerProfileOverview = () => {
  const [customer, setCustomer] = useState<any>(null)

  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('id')
    // console.log(user)
    // if (user != null) {
    // let dataTemp = JSON.parse(user)
    //  setUser(dataTemp)

    const data = await getAllBrokerById(user)

    // console.log(data)
    setCustomer(data.data.customer)
    // setData(data.data?.customers)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  return (
    <>
      <ProfileDetails customer={customer} />
      {/* <ConnectedAccounts customer={customer} /> */}
      <Notifications customer={customer} />
      <DeactivateAccount customer={customer} title='Delete Broker' />
    </>
  )
}

export default CustomerProfileOverview
