import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
type Props = {
  values: any
  data: any
  setFee: (arg: string) => void
  fee: string
  setTotal: (arg: number) => void
  total: number
}
function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}
let diffDays = 0
let days = ''
const Step3: FC<Props> = ({values, data, fee, setFee, setTotal, total}) => {
  // console.log('ssss', values?.values)
  // console.log('data', data)
  if (values?.values.selectedStartDate != '' && values?.values.selectedEndDate != '') {
    const date1 = new Date(values?.values.selectedStartDate)
    const date2 = new Date(values?.values.selectedEndDate)

    diffDays = dateDiffInDays(date1, date2)
    // console.log(diffDays)

    data?.forEach((element: any) => {
      if (element._id === values?.values.vehicle) {
        // console.log(element)
        element.pricingOption?.forEach((item: any) => {
          if (parseInt(item.time) <= diffDays) {
            days = item.price
            const pay = parseInt(item.price) * diffDays
            setFee(pay.toString())
          }
        })
      }
    })
  }
  if (diffDays != 0) {
    const val = parseInt(fee) + parseInt(values?.values.bookingfee)
    setTotal(val)
  }
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Projected Costs</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Faq Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row mb-10'>
        <p className='text-muted my-0'>Selected Vehicle</p>
        {data.map((item: any) => (
          <>
            {item._id === values?.values.vehicle && (
              <label className='form-label'>
                {item.year}&nbsp;{item.vehicleType}{' '}
              </label>
            )}
          </>
        ))}
      </div>

      <div className='fv-row mb-10'>
        <p className='text-muted my-0'>Booking Dates</p>
        <label className='form-label'>
          {values?.values.selectedStartDate} - {values?.values.selectedEndDate} ({diffDays} Day
          Rate)&nbsp;
          <span className='text-success'>${days}/days</span>
        </label>
      </div>

      <div className='fv-row mb-10'>
        <p className='text-muted my-0'>Fees:</p>
        <label className='form-label'>
          Booking and service fee <span className='text-success'>${values?.values.bookingfee}</span>
        </label>
      </div>

      <div className='fv-row mb-10'>
        <p className='text-muted my-0'>Deposit:</p>
        <label className='form-label'>${fee}</label>
      </div>

      <div className='fv-row mb-10'>
        <p className='text-muted my-0'>Subtotal:</p>
        <label className='form-label'>${total}</label>
      </div>
    </div>
  )
}

export {Step3}
