import * as Yup from 'yup'

export interface ICreateAccount {
  vehicle: string
  selectedMonth: string
  selectedStartDate: string
  seletedStartTime: string
  selectedEndDate: string
  seletedEndTime: string
  comments: string
  bookingfee: string
  fee: number
  deposit: number
  salesTax: number
  serviceTax: number
  payment: string
  total: number
  customerId: string
  location: string
  dailyFee: number
  brokerId: string
}

const createAccountSchemas = [
  Yup.object({
    vehicle: Yup.string().label('Vehicle Type'),
  }),

  Yup.object({
    // selectedMonth: Yup.string().label('selected Month'),
    selectedStartDate: Yup.string().label('selected Start Date '),
    seletedStartTime: Yup.string().label('seleted Start Time '),
    selectedEndDate: Yup.string().label('selected End Date '),
    seletedEndTime: Yup.string().label('seleted End Time '),
  }),
]

const inits: ICreateAccount = {
  vehicle: '',
  selectedMonth: '',
  selectedStartDate: '',
  seletedStartTime: '',
  selectedEndDate: '',
  deposit: 0,
  seletedEndTime: '',
  comments: '',
  bookingfee: '105',
  serviceTax: 0,
  salesTax: 0,
  fee: 0,
  total: 0,
  customerId: 'no',
  payment: 'Manual',
  dailyFee: 0,
  brokerId: '',
  location: '',
}

export {createAccountSchemas, inits}
