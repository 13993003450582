import moment from 'moment'
import {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  className: string
  data: any
}

function addCommas(nStr: string) {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}
const AllTripsTable: FC<Props> = ({className, data}) => {
  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>All Trips</span>
          </h3>
          <div className='card-toolbar'>
            <div className='me-4'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select text-gray-500 form-select-sm form-select-white bg-light w-125px'
                defaultValue='Active'
              >
                <option value='Active'>This Month</option>
                {/* <option value='Approved'>In Progress</option>
                <option value='Declined'>To Do</option>
                <option value='In Progress'>Completed</option> */}
              </select>
            </div>

            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-primary '
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              Download Report
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold bg-light'>
                  <th>Vehicle</th>
                  <th>Trip Cost</th>
                  <th>Milage</th>
                  <th>Timespan</th>
                  <th>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data?.map((item: any) => (
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {/* {item.vechicleId?.year}&nbsp; {item.vechicleId?.vehicleType} */}
                        {item.vechicleId?.year}&nbsp; {item.vechicleId?.make}{' '}
                        {item.vechicleId?.model}
                      </a>
                    </td>
                    <td>
                      <p className='text-dark fw-bold d-block mb-1 fs-6'>${item.total}</p>
                    </td>
                    <td>
                      <p className='text-dark fw-bold d-block mb-1 fs-6'>
                        {addCommas(item.vechicleId?.milleage)}
                      </p>
                    </td>
                    <td>
                      <p className='text-dark fw-bold d-block mb-1 fs-6'>
                        {moment(item.selectedStartDate).format('MMM Do YYYY')} to{' '}
                        {moment(item.selectedEndDate).format('MMM Do YYYY')}
                      </p>
                    </td>

                    <td className='d-flex flex-row justify-content-between align-items-center text-dark fw-bold'>
                      <span className='badge badge-light-success'>{item.status}</span>
                      {/* <a
                        href='#'
                        className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-1'
                      >
                        Action
                      </a> */}
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default AllTripsTable
