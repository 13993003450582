/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {UsersListFilter} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListFilter'
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {KTSVG} from '../../../helpers'
import {TablesWidget10} from './TablesWidget10'
import {TablesVehicle10} from './TableVehicle10'

type Props = {
  className: string
  title?: string
  pagination?: boolean
}

const TablesWidget13: React.FC<Props> = ({className, pagination, title}) => {
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className='d-flex flex-row align-items-center justify-content-between w-100 w-md-auto gap-5'>
          <h3 className='fw-bolder my-2'>{title}</h3>
          {/* <UsersListSearchComponent className='bg-white w-md-425px py-2' /> */}
        </div>

        <div className='d-flex w-100 w-sm-auto align-items-center my-2'>
          <button type='button' className='btn bg-white me-3 btn-sm'>
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 mx-0' />
          </button>
          {/* <UsersListFilter className='bg-white btn-sm' /> */}

          {/* begin::Export */}
          <button type='button' className='btn bg-white btn-sm'>
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Export
          </button>
          {/* end::Export */}
        </div>
      </div>

      <TablesWidget10 className='mb-5 mb-xl-8'></TablesWidget10>
      {pagination && (
        <div className='d-flex flex-stack flex-wrap'>
          <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

          <ul className='pagination'>
            <li className='page-item previous'>
              <a href='#' className='page-link'>
                <i className='previous'></i>
              </a>
            </li>

            <li className='page-item active'>
              <a href='#' className='page-link'>
                1
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                2
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                3
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                4
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                5
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                6
              </a>
            </li>

            <li className='page-item next'>
              <a href='#' className='page-link'>
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export {TablesWidget13}
