import {Field, Form, Formik, FormikValues} from 'formik'
import {createBrowserHistory} from 'history'
import {useEffect, useRef, useState} from 'react'
import Swal from 'sweetalert2'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../_metronic/helpers'
import {AddVehicle} from '../../../modules/auth/core/_requests'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelper'
import {Step1} from './steps/Step1'
import PlusOutlined from '@ant-design/icons'
import {Modal, Upload} from 'antd'
import type {RcFile, UploadProps} from 'antd/es/upload'
import type {UploadFile} from 'antd/es/upload/interface'
import {make, model} from '../../../../utils/consts'
import {Button} from 'react-bootstrap'

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const inputArr: any[] = []
let addonArray: any[] = []
const AddNewVehicle = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasEdit, setHasEdit] = useState(false)
  const [idEdit, setIdEdit] = useState(-1)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [arr, setArr] = useState<any>([])
  const [addon, setAddon] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [img, setImg] = useState(false)
  const browserHistory = createBrowserHistory()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const handleChangeTitle = (e: {target: {value: any}}, i: number) => {
    setArr((s: string | any[]) => {
      const newArr = s.slice()
      newArr[i].title = e.target.value

      return newArr
    })
  }
  const handleChangePrice = (e: {target: {value: any}}, i: number) => {
    // e.preventDefault();

    // const index = e.target.id;
    console.log(i)
    setArr((s: any) => {
      const newArr = s.slice()
      newArr[i].price = e.target.value

      return newArr
    })
  }
  const handleChangeDescription = (e: {target: {value: any}}, i: number) => {
    // e.preventDefault();

    // const index = e.target.id;
    console.log(i)
    setArr((s: any) => {
      const newArr = s.slice()
      newArr[i].description = e.target.value

      return newArr
    })
  }
  const handleChangeAddonTitle = (e: {target: {value: any}}, i: number) => {
    setAddon((s: any[]) => {
      const newArr = s.slice()
      newArr[i].title = e.target.value

      return newArr
    })
  }
  const handleChangeAddonPrice = (e: {target: {value: any}}, i: number) => {
    // e.preventDefault();

    // const index = e.target.id;
    console.log(i)
    setAddon((s: any) => {
      const newArr = s.slice()
      newArr[i].price = e.target.value

      return newArr
    })
  }
  const handleChangeAddonDescription = (e: {target: {value: any}}, i: number) => {
    // e.preventDefault();

    // const index = e.target.id;
    console.log(i)
    setAddon((s: any) => {
      const newArr = s.slice()
      newArr[i].description = e.target.value

      return newArr
    })
  }
  const handleCancel = () => setPreviewOpen(false)
  useEffect(() => {
    setArr(inputArr)
  }, [])
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
  }

  const handleChange: UploadProps['onChange'] = ({fileList: newFileList}) =>
    setFileList(newFileList)

  const uploadButton = (
    <div>
      {/* <PlusOutlined /> */}
      <div>Upload</div>
    </div>
  )
  useEffect(() => {
    setArr(inputArr)
  }, [])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    browserHistory.push('/vehicle/manage')
    window.location.reload()
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    let images: any[] = []
    fileList.forEach((item) => images.push(item.originFileObj))
    values.vehicleImages = images
    values.addons = addon
    // setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])
    console.log(images)

    setLoading(true)
    const formData = new FormData()
    formData.append('title', values.title)
    formData.append('description', values.description)
    formData.append('make', values.make)
    formData.append('model', values.model)
    formData.append('vin', values.vin)
    formData.append('classification', values.classification)
    formData.append('year', values.year)
    formData.append('dueDate', values.dueDate)
    formData.append('feature', values.feature)
    formData.append('type', values.type)
    formData.append('dailyPrice', values.dailyPrice)
    formData.append('status', values.status)
    values.vehicleImages.forEach((item, index) => {
      formData.append('vehicleImages', item)
    })
    // values.addons.forEach((item, index) => {
    //   formData.append('addon', item)
    // })
    formData.append('addon', JSON.stringify(values.addons))
    // formData.append('vehicleImages', JSON.stringify(values.vehicleImages))
    // values.addons.forEach((tag) => formData.append('addon', tag))
    // values.vehicleImages.forEach((tag) => formData.append('vehicleImages', tag))

    try {
      const {
        data: {data, message},
      } = await AddVehicle(formData)
      console.log(data)
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      browserHistory.push('/vehicle/manage')
      window.location.reload()
    } catch (error) {
      // console.error(error)
      Swal.fire({
        timer: 3000,
        text: 'Vechile Not Added',
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  const addInput = () => {
    setArr((s: any) => {
      return [
        ...s,
        {
          type: 'text',
          price: '',
          title: '',
          description: '',
          // cropVarieties: [],
        },
      ]
    })
  }
  const deleteField = (id: number) => {
    let data = arr.splice(id, 1)
    setArr(data)
  }
  const saveChanges = (item: any, id: number) => {
    console.log(item)
    addonArray.push(item)
    setAddon(addonArray)
    let data = arr.splice(id, 1)
    setArr([])
  }
  console.log(arr)
  const saveEditChanges = (item: any, id: number) => {
    console.log(addonArray)
    // let data = addon.splice(id, 1)
    let dataArray = addonArray.splice(id, 1)
    console.log(dataArray)

    addonArray.push(item)
    setAddon(addonArray)
    setIdEdit(-1)
    setHasEdit(false)
  }
  return (
    <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
      <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
        {() => (
          <Form className='py-20 w-100 w-xl-900px px-9' noValidate id='kt_create_account_form'>
            <div className='current' data-kt-stepper-element='content'>
              <div className='w-100'>
                <div className='pb-10 pb-lg-15'>
                  <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    {' '}
                    Vehicle Information
                  </h2>

                  <div className='text-gray-400 fw-bold fs-6'>
                    If you need more info, please check out
                    <a href='/faq' className='link-primary fw-bolder'>
                      {' '}
                      FAQ Page
                    </a>
                    .
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-lg-4'>
                    <label className=' col-form-label fw-bold fs-6'>
                      <span className=''>Main Image</span>
                    </label>
                  </div>
                  <div className='col-lg-8'>
                    <Upload
                      action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                      listType='picture-card'
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img alt='example' style={{width: '100%'}} src={previewImage} />
                    </Modal>
                  </div>
                </div>
                <div className='fv-row'>
                  <div className='row mb-3'>
                    <div className='col-lg-4'>
                      <label className='col-form-label fw-bold fs-6'>
                        <span className=''>Title</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        name='title'
                        className='form-control form-control-lg form-control-solid'
                      ></Field>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-lg-4'>
                      <label className='col-form-label fw-bold fs-6'>
                        <span className=''>Make</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        as='select'
                        name='make'
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option value=''>Choose Make</option>
                        {make.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Field>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-lg-4'>
                      <label className='col-form-label fw-bold fs-6'>
                        <span className=''>Model</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        name='model'
                        className='form-control form-control-lg form-control-solid'
                      ></Field>
                      {/* <Field
                        as='select'
                        name='model'
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option value=''>Choose Model</option>

                        {model.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Field> */}
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-lg-4'>
                      <label className='col-form-label fw-bold fs-6'>
                        <span className=''>Type</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      {/* <Field
                        name='model'
                        className='form-control form-control-lg form-control-solid'
                      ></Field> */}
                      <Field
                        as='select'
                        name='type'
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option value=''>Choose Type</option>

                        {model.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Field>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-lg-4'>
                      <label className='col-form-label fw-bold fs-6'>
                        <span className=''>Classification</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        as='select'
                        name='classification'
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option value=''>Choose Classification</option>
                        <option value={'Supercar'}>Supercar</option>
                        <option value={'Regular'}>Regular</option>
                        <option value={'Mid-Tier'}>Mid-Tier</option>
                      </Field>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-lg-4'>
                      <label className=' col-form-label fw-bold fs-6'>
                        <span className=''>Year</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        as='select'
                        name='year'
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option value=''>Choose Year</option>
                        <option value='2023'>2023</option>
                        <option value='2022'>2022</option>
                        <option value='2021'>2021</option>
                        <option value='2020'>2020</option>
                        <option value='2019'>2019</option>
                        <option value='2018'>2018</option>
                        <option value='2017'>2017</option>
                        <option value='2016'>2016</option>
                        <option value='2015'>2015</option>
                        <option value='2014'>2014</option>
                      </Field>
                    </div>
                  </div>
                  <div className='mb-3 row'>
                    <div className='col-lg-4'>
                      <label className=' col-form-label fw-bold fs-6'>Daily Price</label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        type='number'
                        // value='896975876897987503405235'
                        className='form-control form-control-lg form-control-solid'
                        name='dailyPrice'
                      />
                    </div>
                  </div>{' '}
                  <div className='mb-3 row'>
                    <div className='col-lg-4'>
                      <label className=' col-form-label fw-bold fs-6'>VIN Number</label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        type='text'
                        // value='896975876897987503405235'
                        className='form-control form-control-lg form-control-solid'
                        name='vin'
                      />
                    </div>
                  </div>{' '}
                  <div className='mb-3 row'>
                    <div className='col-lg-4'>
                      <label className=' col-form-label fw-bold fs-6'>Description </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        as='textarea'
                        rows={3}
                        type='text'
                        // value='896975876897987503405235'
                        className='form-control form-control-lg form-control-solid'
                        name='description'
                      />
                    </div>
                  </div>{' '}
                  <div className='mb-3 row'>
                    <div className='col-lg-4'>
                      <label className=' col-form-label fw-bold fs-6'>Feature</label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        type='text'
                        // value='896975876897987503405235'
                        className='form-control form-control-lg form-control-solid'
                        name='feature'
                      />
                    </div>
                  </div>{' '}
                  {/* <div className='mb-3 row'>
                    <div className='col-lg-4'>
                      <label className=' col-form-label fw-bold fs-6'>Due Date </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        type='date'
                        // value='896975876897987503405235'
                        className='form-control form-control-lg form-control-solid'
                        name='dueDate'
                      />
                    </div>
                  </div>{' '} */}
                  <div className='mb-3 row'>
                    <div className='col-lg-4'>
                      <label className=' col-form-label fw-bold fs-6'>Status </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        as='select'
                        name='status'
                        // value={item.time}
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option value=''></option>
                        <option value='Available'>Available</option>
                        <option value='Unavailable'>Unavailable</option>
                        <option value='Maintenance'>Maintenance</option>
                        <option value='Custom'>Custom</option>
                      </Field>
                    </div>
                  </div>{' '}
                </div>
              </div>
            </div>
            <div className='pt-10 d-flex justify-content-between mb-3'>
              <h2 className='fw-bolder d-flex align-items-center text-dark'>
                Additional Addons (Optional)
              </h2>

              <div className='text-gray-400 fw-bold fs-6'>
                <Button onClick={addInput}> New Addon</Button>
              </div>
            </div>
            {addon.map((item: any, i: number) => {
              return (
                <>
                  {' '}
                  {idEdit == i ? (
                    <div className='mb-3 row'>
                      <div className='col-lg-9'>
                        <div className='mb-3 row'>
                          <div className='col-lg-6'>
                            <Field
                              type={item.type}
                              placeholder='Type Addon Title Here'
                              value={item.title}
                              onChange={(event: any) => handleChangeAddonTitle(event, i)}
                              className='form-control form-control-lg form-control-solid'
                              name={item.title}
                              style={{display: idEdit == i ? 'block' : 'none'}}
                            />
                          </div>
                          <div className='col-lg-6'>
                            <Field
                              type={item.type}
                              placeholder='Type Addon Price Here'
                              value={item.price}
                              onChange={(event: any) => handleChangeAddonPrice(event, i)}
                              className='form-control form-control-lg form-control-solid'
                              name={item.price}
                              style={{display: idEdit == i ? 'block' : 'none'}}
                            />
                          </div>
                        </div>
                        <div className='mb-3 row'>
                          <div className='col-lg-12'>
                            <Field
                              type={item.type}
                              placeholder='Type Addon Description Here'
                              value={item.description}
                              style={{display: idEdit == i ? 'block' : 'none'}}
                              onChange={(event: any) => handleChangeAddonDescription(event, i)}
                              className='form-control form-control-lg form-control-solid'
                              name={item.description}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-2 d-flex justify-content-between align-self-center'>
                        <Button
                          onClick={() => deleteField(i)}
                          className='btn btn-lg btn-light me-3'
                          style={{background: '#d1d1d9', color: 'white'}}
                        >
                          Delete
                        </Button>
                        <Button onClick={() => saveEditChanges(item, i)}>Save Changes</Button>
                      </div>
                    </div>
                  ) : (
                    <div style={{padding: '3rem', border: '1px dotted lightgrey'}}>
                      <div className='mb-3 row'>
                        <div className='col-lg-9'>
                          <div className='mb-3 row'>
                            <div className='col-lg-6'>
                              {' '}
                              <span className='d-flex align-items-center'>
                                {' '}
                                <h2 className='fs-5'>{item.title}</h2>&nbsp;&nbsp;
                                <span className='text-success fs-4'>${item.price}</span>
                              </span>
                            </div>
                          </div>
                          <div className='mb-3 row'>
                            <p className='fs-6'>{item.description}</p>
                          </div>
                        </div>
                        <div className='col-lg-2 d-flex justify-content-between align-self-center'>
                          <button
                            onClick={() => deleteField(i)}
                            style={{background: '#d1d1d9', color: 'white'}}
                            className='btn btn-lg btn-muted me-3'
                          >
                            Delete
                          </button>
                          <button
                            className='btn btn-lg btn-primary '
                            // style={{background: '#d1d1d9', color: 'white'}}
                            onClick={() => {
                              setHasEdit(true)
                              setIdEdit(i) //<== set idEdit state here
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            })}
            {arr.map((item: any, i: number) => {
              return (
                <div style={{padding: '3rem', border: '1px dotted lightgrey'}}>
                  <div className='mb-3 row'>
                    <div className='col-lg-8'>
                      <div className='mb-3 row'>
                        <div className='col-lg-6'>
                          <Field
                            type={item.type}
                            placeholder='Type Addon Title Here'
                            value={item.title}
                            onChange={(event: any) => handleChangeTitle(event, i)}
                            className='form-control form-control-lg form-control-solid'
                            name={item.title}
                          />
                        </div>
                        <div className='col-lg-6'>
                          <Field
                            type={item.type}
                            placeholder='Type Addon Price Here'
                            value={item.price}
                            onChange={(event: any) => handleChangePrice(event, i)}
                            className='form-control form-control-lg form-control-solid'
                            name={item.price}
                          />
                        </div>
                      </div>
                      <div className='mb-3 row'>
                        <div className='col-lg-12'>
                          <Field
                            type={item.type}
                            placeholder='Type Addon Description Here'
                            value={item.description}
                            onChange={(event: any) => handleChangeDescription(event, i)}
                            className='form-control form-control-lg form-control-solid'
                            name={item.description}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 d-flex justify-content-between align-self-center'>
                      <button
                        onClick={() => deleteField(i)}
                        className='btn btn-lg btn-light-muted me-3'
                      >
                        Delete
                      </button>
                      <Button onClick={() => saveChanges(item, i)}>Save Changes</Button>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className='d-flex flex-stack pt-10'>
              <div className='mr-2'>
                <button
                  onClick={prevStep}
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                >
                  Cancel
                </button>
              </div>

              <div>
                <button type='submit' className='btn btn-lg btn-primary me-3'>
                  <span className='indicator-label'>
                    Add Vehicle
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export {AddNewVehicle}
