import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {TripByID, deposit, updateTrip} from '../../../modules/auth/core/_requests'
import {createBrowserHistory} from 'history'
import Swal from 'sweetalert2'
export function VerifyScreen() {
  const [loading, setLoading] = useState(false)
  const browserHistory = createBrowserHistory()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  let id = localStorage.getItem('id')
  const handlePayment = async () => {
    const values = {millage: 0, status: 'Booked'}
    const data = await updateTrip(id, values)
    Swal.fire({
      timer: 3000,
      text: 'Payment Done Successfully',
      icon: 'success',
      showCloseButton: false,
      showConfirmButton: false,
      showDenyButton: false,
    })
    const companyName = localStorage.getItem('companyName') as string
    browserHistory.push(`/${companyName}/dashboard/overview`)
    window.location.reload()
  }
  const handlePaymentDeposit = async (total: number) => {
    // event.preventDefault()

    // // const {stripe, elements} = props
    // if (!stripe || !elements) {
    //   return
    // }

    const values = {deposit: total}
    const data = await deposit(id, values)
    Swal.fire({
      timer: 3000,
      text: 'Payment Done Successfully',
      icon: 'success',
      showCloseButton: false,
      showConfirmButton: false,
      showDenyButton: false,
    })
    const companyName = localStorage.getItem('companyName') as string
    browserHistory.push(`/${companyName}/dashboard/overview`)
    window.location.reload()
  }
  const tripById = async () => {
    const data = await TripByID(id)
    let total =
      data.data.trips[0]?.vechicleId?.priceClassification +
      (data.data.trips[0]?.vechicleId?.priceClassification *
        data.data.trips[0]?.onwerId.prices.serviceFee) /
        100 +
      (data.data.trips[0]?.vechicleId?.priceClassification *
        data.data.trips[0]?.onwerId.prices.salesTax) /
        100
    if (data.data.trips[0]?.status === 'Pending') {
      handlePaymentDeposit(total)
    } else {
      handlePayment()
    }
  }
  useEffect(() => {
    tripById()
  }, [])
  return (
    <div className='d-flex flex-column  bgi-size-cover justify-content-center align-items-center p-10'>
      {/* begin::Content */}
      <div className='d-flex flex-column flex-center p-10'>
        {/* begin::Logo */}
        {/* <img alt='Logo' src={toAbsoluteUrl('/media/logo.png')} className='h-40px mb-5' /> */}
        {/* end::Logo */}

        {/* begin::Title */}

        <h3 className='text-white text-center mb-7 text-dark' style={{fontSize: '30px'}}>
          Payment Verified
        </h3>

        {/* end::Text */}
        <div className='h-500px'>
          <img alt='Logo' src={toAbsoluteUrl('/media/S_email.png')} className='h-500px' />
        </div>
      </div>
      {/* <div
          className='d-flex w-100 h-50 bgi-size-cover bgi-position-center'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/logos/s_present.png')})`}}
        ></div> */}
      {/* end::Content */}
    </div>
  )
}
