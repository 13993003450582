import React, {FC, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
type Props = {
  customer: any
  broker: any
  role: any
}
const Step2: FC<Props> = ({customer, broker, role}) => {
  // const role = localStorage.getItem('role')
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Rental Dates</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/faq' className='link-primary fw-bolder'>
            {' '}
            FAQ Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className=''>Selected Month</span>
            </label>

            <Field
              as='select'
              name='selectedMonth'
              className='form-select form-select-lg form-select-solid'
            >
              <option value=''>Choose Month</option>
              <option value='January'>January</option>
              <option value='February'>February</option>
              <option value='March'>March</option>
              <option value='April'>April</option>
              <option value='May'>May</option>
              <option value='June'>June</option>
              <option value='July'>July</option>
              <option value='August'>August</option>
              <option value='September'>September</option>
              <option value='October'>October</option>
              <option value='November'>November</option>
              <option value='December'>December</option>
            </Field>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <label className='col-lg-12 col-form-label fw-bold fs-6'>
              <span className=''>Select Start Day</span>
            </label>

            <Field
              className='form-control form-control-lg form-control-solid'
              type='date'
              name='selectedStartDate'
            />
          </div>
          <div className='col-md-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className=''>Select Time</span>
            </label>
            <Field
              as='select'
              name='seletedStartTime'
              className='form-select form-select-lg form-select-solid'
            >
              <option value=''>Choose Time</option>
              <option value='12:00 AM'>12:00 AM</option>
              <option value='12:30 AM'>12:30 AM</option>
              <option value='1:00 AM'>1:00 AM</option>
              <option value='1:30 AM'>1:30 AM</option>
              <option value='2:00 AM'>2:00 AM</option>
              <option value='2:30 AM'>2:30 AM</option>
              <option value='3:00 AM'>3:00 AM</option>
              <option value='3:30 AM'>3:30 AM</option>
              <option value='4:00 AM'>4:00 AM</option>
              <option value='4:30 AM'>4:30 AM</option>
              <option value='5:00 AM'>5:00 AM</option>
              <option value='5:30 AM'>5:30 AM</option>
              <option value='6:00 AM'>6:00 AM</option>
              <option value='6:30 AM'>6:30 AM</option>
              <option value='7:00 AM'>7:00 AM</option>
              <option value='8:00 AM'>8:00 AM</option>
              <option value='9:00 AM'>9:00 AM</option>
              <option value='10:00 AM'>10:00 AM</option>
              <option value='11:00 AM'>11:00 AM</option>
              <option value='12:00 AM'>12:00 AM</option>

              <option value='12:00 PM'>12:00 PM</option>
              <option value='12:30 PM'>12:30 PM</option>
              <option value='1:00 PM'>1:00 PM</option>
              <option value='1:30 PM'>1:30 PM</option>
              <option value='2:00 PM'>2:00 PM</option>
              <option value='2:30 PM'>2:30 PM</option>
              <option value='3:00 PM'>3:00 PM</option>
              <option value='3:30 PM'>3:30 PM</option>
              <option value='4:00 PM'>4:00 PM</option>
              <option value='4:30 PM'>4:30 PM</option>
              <option value='5:00 PM'>5:00 PM</option>
              <option value='5:30 PM'>5:30 PM</option>
              <option value='6:00 PM'>6:00 PM</option>
              <option value='6:30 PM'>6:30 PM</option>
              <option value='7:00 PM'>7:00 PM</option>
              <option value='8:00 PM'>8:00 PM</option>
              <option value='9:00 PM'>9:00 PM</option>
              <option value='10:00 PM'>10:00 PM</option>
              <option value='11:00 PM'>11:00 PM</option>
              <option value='12:00 PM'>12:00 PM</option>
            </Field>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='col-lg-6 col-form-label fw-bold fs-6'>
              <span className=''>Select Return Day</span>
            </label>
            <Field
              className='form-control form-control-lg form-control-solid'
              type='date'
              name='selectedEndDate'
            />
          </div>
          <div className='col-md-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className=''>Select Time</span>
            </label>
            <Field
              as='select'
              name='seletedEndTime'
              className='form-select form-select-lg form-select-solid'
            >
              <option value=''>Choose Time</option>
              <option value='12:00 AM'>12:00 AM</option>
              <option value='12:30 AM'>12:30 AM</option>
              <option value='1:00 AM'>1:00 AM</option>
              <option value='1:30 AM'>1:30 AM</option>
              <option value='2:00 AM'>2:00 AM</option>
              <option value='2:30 AM'>2:30 AM</option>
              <option value='3:00 AM'>3:00 AM</option>
              <option value='3:30 AM'>3:30 AM</option>
              <option value='4:00 AM'>4:00 AM</option>
              <option value='4:30 AM'>4:30 AM</option>
              <option value='5:00 AM'>5:00 AM</option>
              <option value='5:30 AM'>5:30 AM</option>
              <option value='6:00 AM'>6:00 AM</option>
              <option value='6:30 AM'>6:30 AM</option>
              <option value='7:00 AM'>7:00 AM</option>
              <option value='8:00 AM'>8:00 AM</option>
              <option value='9:00 AM'>9:00 AM</option>
              <option value='10:00 AM'>10:00 AM</option>
              <option value='11:00 AM'>11:00 AM</option>
              <option value='12:00 AM'>12:00 AM</option>

              <option value='12:00 PM'>12:00 PM</option>
              <option value='12:30 PM'>12:30 PM</option>
              <option value='1:00 PM'>1:00 PM</option>
              <option value='1:30 PM'>1:30 PM</option>
              <option value='2:00 PM'>2:00 PM</option>
              <option value='2:30 PM'>2:30 PM</option>
              <option value='3:00 PM'>3:00 PM</option>
              <option value='3:30 PM'>3:30 PM</option>
              <option value='4:00 PM'>4:00 PM</option>
              <option value='4:30 PM'>4:30 PM</option>
              <option value='5:00 PM'>5:00 PM</option>
              <option value='5:30 PM'>5:30 PM</option>
              <option value='6:00 PM'>6:00 PM</option>
              <option value='6:30 PM'>6:30 PM</option>
              <option value='7:00 PM'>7:00 PM</option>
              <option value='8:00 PM'>8:00 PM</option>
              <option value='9:00 PM'>9:00 PM</option>
              <option value='10:00 PM'>10:00 PM</option>
              <option value='11:00 PM'>11:00 PM</option>
              <option value='12:00 PM'>12:00 PM</option>
            </Field>
          </div>
        </div>
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Location</span>
          </label>
          <Field
            name='location'
            className='form-control form-control-lg form-control-solid'
          ></Field>
        </div>
        {/* {role != 'Customer' && ( */}
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Customer</span>
          </label>
          <Field
            as='select'
            name='customerId'
            className='form-select form-select-lg form-select-solid'
          >
            <option value=''>Choose Customer</option>
            {customer.map((item: any) => (
              <option value={item._id}>{item.accountName}</option>
            ))}
            {/* <option value='2'>Honada</option>
              <option value='3'>Civic</option> */}
          </Field>
        </div>
        {/* )} */}
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Broker</span>
          </label>
          <Field
            as='select'
            name='brokerId'
            className='form-select form-select-lg form-select-solid'
          >
            <option value=''>Choose Broker</option>
            {broker.map((item: any) => (
              <option value={item._id}>{item.accountName}</option>
            ))}
            {/* <option value='2'>Honada</option>
              <option value='3'>Civic</option> */}
          </Field>
        </div>

        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Comments (Optional)</span>
          </label>
          <Field
            name='comments'
            className='form-control form-control-lg form-control-solid'
          ></Field>
        </div>
      </div>
    </div>
  )
}

export {Step2}
