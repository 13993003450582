/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {ImagePickerConf} from 'react-image-picker-editor'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {getAllVehicleId, updateVechileImage} from '../../auth/core/_requests'
import './index.css'
let config2: ImagePickerConf = {
  borderRadius: '8px',
  language: 'en',
  width: '300px',
  height: '300px',
  objectFit: 'contain',
  compressInitial: null,
}
// const initialImage: string = '/assets/images/8ptAya.webp';
let initialImage = '/media/cars/tesla.svg'
const inputArr = [
  {
    image: '',
  },
]
export function Campaigns() {
  const [arr, setArr] = useState<any>([])
  const [images, setImages] = useState<any>([])
  const [user, setUser] = useState<any>(null)

  const [pdfFile, setPDfFile] = useState('')
  const [imgSelected, setImgSelected] = useState('')
  const [loading, setLoading] = useState(false)

  const handleImage = (event: any) => {
    console.log(event.target.files[0])
    setPDfFile(event.target.files[0])
  }

  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('id')
    // console.log(user)
    // if (user != null) {
    // let dataTemp = JSON.parse(user)
    //  setUser(dataTemp)

    const data = await getAllVehicleId(user)
    console.log(data)
    let array: any[] = []
    let images = data.data.vehicle?.gallery
    console.log(images)
    setImages(images)

    // setCustomer(data.data.vehicle)
    // setData(data.data?.customers)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const user = localStorage.getItem('id')
    const formData = new FormData()

    formData.append('vehicleImages', pdfFile)

    try {
      const {
        data: {data, message},
      } = await updateVechileImage(user, formData)
      // console.log(data)

      getAllCustomerByIds()
      closeCreateRentalModal()
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // setLoading(false)

      // browserHistory.push('/vehicle/details/settings')
      // window.location.reload()
    } catch (error) {
      // stepper.current.goNext()
      console.error(error)

      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }
  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user != null) {
      let dataTemp = JSON.parse(user)
      console.log(dataTemp)
      setUser(dataTemp)
    }

    // setArr(inputArr)
    getAllCustomerByIds()
  }, [])
  console.log(user)
  const [ids, setId] = useState('')
  const [status, setStatus] = useState('')
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)
  const openCreateRentalModal = (id: any) => {
    setShowCreateRentalModal(true)
    setId(id)
  }
  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
    setId('')
  }
  const [showCreateRentalModalImg, setShowCreateRentalModalImg] = useState(false)
  const openCreateRentalModalImg = (id: any) => {
    setShowCreateRentalModalImg(true)
    setImgSelected(id)
  }
  const closeCreateRentalModalImg = () => {
    setShowCreateRentalModalImg(false)
    setImgSelected('')
  }
  return (
    <>
      <div className='d-flex justify-content-end'>
        <Button className='bg-success mb-3 ' onClick={openCreateRentalModal}>
          {' '}
          Upload Image
        </Button>
      </div>
      {user?.role === 'Company' || user?.role === 'Employee' ? (
        <>
          <div className='me-7 mb-4 row'>
            {images.map((item: any, i: any) => (
              <div
                className='col-sm-4 col-md-4 col-lg-4 col-xs-12   mb-5'
                onClick={() => openCreateRentalModalImg(item)}
              >
                <img src={item} alt='Metornic' className='w-100 h-100 ' />
              </div>
            ))}
          </div>
          {/* <Button className='bg-dark' onClick={handleSubmit}>
            Save Changes
          </Button> */}
        </>
      ) : (
        <div className='me-7 mb-4 row'>
          {images.map((item: any, i: any) => {
            return (
              <div
                className='col-sm-6 col-md-6 col-lg-4 col-xs-12  mb-5'
                onClick={() => openCreateRentalModalImg(item)}
              >
                <img src={item} alt='Metornic' />
              </div>
            )
          })}
        </div>
      )}
      {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModal} size='lg' centered>
          <Modal.Body>
            <div className='w-100'>
              <div className=''>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                  Choose a file
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Billing is issued based on your selected account type'
                  ></i>
                </h2>
              </div>

              <div className='fv-row'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <input
                      type='radio'
                      className='btn-check'
                      name='accountType'
                      value='personal'
                      id='kt_create_account_form_account_type_personal'
                    />
                    <label
                      className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-2'
                      htmlFor='kt_create_account_form_account_type_personal'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com005.svg'
                        className='svg-icon-3x me-5'
                      />

                      <span className='d-block fw-bold text-start'>
                        <input
                          type='file'
                          onChange={(event) => handleImage(event)}
                          // accept='application/pdf,application'
                        />
                        {/* <span className='text-dark fw-bolder d-block fs-4 mb-2'>Upload from computer</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Drag you file here or click to browse
                </span> */}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-3 fv-row'>
              {/* onClick={UpdateRental} */}

              <button
                className='btn btn-sm btn-dark me-3'
                disabled={loading}
                onClick={handleSubmit}
              >
                {!loading && <span className='indicator-label'>Save</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* <Link to='#' className='btn btn-sm btn-dark me-3'>
                Save
              </Link> */}
              <Link to='#' onClick={closeCreateRentalModal} className='btn btn-sm btn-dark me-3'>
                Close
              </Link>
            </div>
            {/* <CreateRental /> */}
          </Modal.Body>
        </Modal>
      )}
      {showCreateRentalModalImg && (
        <Modal show={showCreateRentalModalImg} onHide={closeCreateRentalModalImg} centered>
          <Modal.Body>
            <img src={imgSelected} alt='Metornic' className='w-100 h-100 zoom' />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
