import {FC} from 'react'
import {number} from 'yup'
import AllTripsTable from './AllTripsTable'
type Props = {
  data: any
  actual: any
  loading: boolean
  handlePageChange: (arg: number) => void
  activePage: number
}
const AllTrips: FC<Props> = ({data, loading, handlePageChange, activePage, actual}) => {
  return (
    <AllTripsTable
      data={data}
      actual={actual}
      loading={loading}
      handlePageChange={handlePageChange}
      activePage={activePage}
      className='mb-5 mb-xl-8'
    ></AllTripsTable>
  )
}

export default AllTrips
