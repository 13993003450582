/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup} from '../../../../_metronic/layout/core'
import {ChartsWidget2, FeedsWidget4} from '../../../../_metronic/partials/widgets'
import {FeedsWidget7} from '../../../../_metronic/partials/widgets/feeds/FeedsWidget7'
import {
  getAllCustomerStat,
  getAllTripsStat,
  getAllTripStat,
  getAllVehicleStat,
  getYearStat,
} from '../../../modules/auth/core/_requests'
import AllTrips from './AllTripsTable'

const Manage: React.FC = () => {
  const [tab, setTab] = useState('Sidebar')
  const [config, setConfig] = useState<ILayout>(getLayoutFromLocalStorage())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [customer, setCustomer] = useState<any>([])
  const [months, setMonths] = useState<any>([])
  const [tripTotal, setTripTotal] = useState<any>([])
  const [data, setData] = useState<any>([])
  let [activePage, setActivePage] = useState(0)
  const getAllTrips = async () => {
    setLoading(true)
    try {
      const data = await getAllTripsStat()
      // let statistice = {
      //   availableCustomer: data.data?.customerListing.availableCustomer,
      //   blockListedCustomer: data.data?.customerListing.availableCustomer,
      //   notActiveCustomer: data.data?.customerListing.notActiveCustomer,
      //   totalCustomer: data.data?.customerListing.totalCustomer,
      //   newCustomerCount: data.data?.customers.newCustomerCount,
      //   newCustomerData: data.data?.customers.newCustomerData,
      // }
      // console.log(data)
      const startIndex = activePage
      const endIndex = startIndex + 10
      setData(data.data?.trips)
      setCustomer(data.data?.trips.slice(startIndex, endIndex))
      // setStatistics(statistice)
      // setData(data.data?.trips)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  const handlePageChange = (pageNumber: number) => {
    // console.log(`active page is ${pageNumber}`)
    const number = (pageNumber - 1) * 10
    setActivePage(pageNumber)
    const startIndex = number
    const endIndex = startIndex + 10

    setCustomer(data.slice(startIndex, endIndex))
  }
  useEffect(() => {
    getAllTrips()
  }, [])
  const updateConfig = () => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
      window.location.reload()
    } catch (error) {
      setConfig(getLayoutFromLocalStorage())
      setConfigLoading(false)
    }
  }

  const reset = () => {
    setResetLoading(true)
    setTimeout(() => {
      setConfig(getLayoutFromLocalStorage())
      setResetLoading(false)
    }, 1000)
  }

  const [updated, setUpdated] = useState(false)
  const [stat, setStatistics] = useState<any>(null)
  const getAllCustomerStatistics = async (val: number) => {
    const dataNew = {
      values: val,
    }
    const data = await getAllTripStat(dataNew)
    const yearData = await getYearStat()
    let yearlyData = yearData?.data.data[0]?.Monthly
    // console.log(yearlyData)
    const monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    setMonths(monthsArray)

    let totalExpenses: any[] = []
    const monthsNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    if (yearlyData !== undefined) {
      monthsNumbers.forEach((items: any, index: any) => {
        yearlyData.forEach((item: any) => {
          if (item.month === items) {
            totalExpenses.push(item.total)
          } else {
            totalExpenses.push(0)
          }
        })
      })
      setUpdated(!updated)
    }
    // console.log('totalExpenses', totalExpenses)
    setTripTotal(totalExpenses)
    let statistice = {
      completedTrip: data.data?.tripsListing?.completedTrip,
      cancelledTrips: data.data?.tripsListing?.cancelledTrips,
      inProgressTrips: data.data?.tripsListing?.inProgressTrips,
      // totalCustomer: data.data?.customerListing?.totalCustomer,
    }
    // console.log(statistice)
    setStatistics(statistice)
    setUpdated(!updated)
    // setData(data.data?.customers)
  }
  const [current, setCurrent] = useState(0.5)
  const handleChange = (val: number) => {
    setCurrent(val)
    getAllCustomerStatistics(val)
    setUpdated(!updated)
  }
  useEffect(() => {
    getAllCustomerStatistics(0.5)
  }, [])
  useEffect(() => {}, [updated])
  return (
    <>
      <div className='row'>
        <div className='col-lg-8'>
          <ChartsWidget2
            handleChange={handleChange}
            stat={stat}
            updated={updated}
            current={current}
            monthsArray={months}
            tripTotal={tripTotal}
            className='card-xl-stretch mb-xl-8'
          />
        </div>
        <div className='col-lg-4'>
          <FeedsWidget4 customer={customer} className='card-xl-stretch mb-8' />
          {/* <FeedsWidget7 className='card-xl-stretch mb-8' /> */}
        </div>
      </div>
      <AllTrips
        data={customer}
        loading={loading}
        handlePageChange={handlePageChange}
        activePage={activePage}
        actual={data}
      />
    </>
  )
}

export default Manage
