/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Card4} from '../../../../_metronic/partials/content/cards/Card4'
import {TablesWidget12} from '../../../../_metronic/partials/widgets'
import {getAllVehicleId} from '../../auth/core/_requests'
import Pagination from 'react-responsive-pagination'

export function Documents() {
  const [customer, setCustomer] = useState<any>([])
  const [data, setData] = useState<any>([])

  let [updated, setUpdated] = useState(false)
  let [loading, setLoading] = useState(true)
  let [activePage, setActivePage] = useState(0)

  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('id')
    // console.log(user)
    // if (user != null) {
    // let dataTemp = JSON.parse(user)
    //  setUser(dataTemp)

    const data = await getAllVehicleId(user)
    const startIndex = activePage
    const endIndex = startIndex + 10
    setData(data.data?.vehicle?.trips)
    setCustomer(data.data?.vehicle?.trips.slice(startIndex, endIndex))
    // console.log(data)
    // setCustomer(data.data.vehicle)
    // setData(data.data?.customers)
  }
  const handlePageChange = (pageNumber: number) => {
    // console.log(`active page is ${pageNumber}`)
    const number = (pageNumber - 1) * 10
    setActivePage(pageNumber)
    const startIndex = number
    const endIndex = startIndex + 10

    setCustomer(data.slice(startIndex, endIndex))
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          History
          <span className='fs-6 text-gray-400 fw-bold ms-2'>
            by Most Recent{' '}
            <KTSVG
              path='/media/icons/duotune/arrows/arr065.svg'
              className='svg-icon-3 svg-icon me-2'
            />
          </span>
        </h3>

        <div className='d-flex my-2 align-items-center gap-3'>
          <button type='button' className='btn btn-sm bg-transparent my-0 py-0'>
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 mx-0' />
          </button>
          <div className=''>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm border-0 form-select-white'
              defaultValue='Recently Updated'
            >
              <option value='Recently Updated'>Customer</option>
              <option value='Active'>Trip Cost</option>
              <option value='Approved'>Millage</option>
              <option value='Approved'>Millage</option>
              <option value='Declined'>Status</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>
        </div>
      </div>

      <TablesWidget12 data={customer} className='card-xl-stretch mb-xl-8'></TablesWidget12>
      <div className='d-flex flex-stack flex-wrap'>
        <Pagination
          current={activePage}
          // itemsCountPerPage={10}
          total={Math.ceil(data.length / 10)}
          // pageRangeDisplayed={5}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  )
}
