import {loadStripe} from '@stripe/stripe-js'
import {Navigate, Route, Routes} from 'react-router-dom'
import {AuthLayout} from './AuthLayout'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {LoginOther} from './components/LoginOther'
import {Registration} from './components/Registration'
import {ResetPassword} from './components/ResetPassword'
import {VerifyAccount} from './components/verifyAccount'
import {WelcomeScreen} from './components/welcome'
const stripePromise = loadStripe(
  'pk_test_51MeUlmEYhaMiCTEsWfeEuXBFcEWwtBTRZtgr2PAHRZby8D2Idm3opi7pmBV4tbVoVj3mROnIY55sZBIk4spbCLLC00cWlQiJKZ'
)

const AuthPage = () => (
  <Routes>
    {/* <Route
      path='registration'
      element={
        <Elements stripe={stripePromise}>
          <Registration />
        </Elements>
      }
    /> */}
    <Route path='welcomeScreen' element={<WelcomeScreen />} />
    {/* <Route path='verifyScreen' element={<VerificationScreen />} /> */}
    {/* <Route path='verify' element={<VerifyScreen />} /> */}
    {/* <Route path='dashboard' element={<DashboardPage />} /> */}
    {/* <Route path='dashboard' element={<DashboardPage />} /> */}
    <Route path='verify/:id' element={<VerifyAccount />} />
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      {/* <Route path='login' element={<Login />} /> */}
      <Route path='/:id/login' element={<LoginOther />} />
      {/* <Route path='login' element={<Login />} /> */}
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password/:id' element={<ResetPassword />} />
      {/* <Route index element={<Login />} /> */}
      {/* <Redirect to='/login' /> */}
      <Route path='/*' element={<Navigate to={'/login'} />} />
    </Route>
  </Routes>
)

export {AuthPage}
