/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {cars} from '../../../../utils/consts'
import {ICAR} from '../../../../utils/types'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
}

const TablesWidget10: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className=''></th>
                <th className=''>MODEL</th>
                <th className=''>MODEL</th>
                <th className=''>YEAR</th>
                <th className=''>VIN</th>
                <th className=''>PRICE</th>
                <th className=''>STATUS</th>
                <th className='text-end'>DETAILS</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {cars.map((car: any) => (
                <tr key={car.name}>
                  <td>
                    <div className='symbol symbol-70px me-5'>
                      <img src={toAbsoluteUrl(car.image)} alt='' />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link
                          to='/vehicle/details/availability'
                          className='text-dark fw-bold text-hover-primary fs-6'
                        >
                          {car.name}
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className='text-dark fw-bold d-block fs-6'>{car.model}</p>
                  </td>
                  <td className=''>
                    <p className='text-dark fw-bold d-block fs-6'>{car.year}</p>
                  </td>
                  <td>
                    <p className='text-dark fw-bold d-block fs-6'>{car.vin}</p>
                  </td>
                  <td>
                    <p className='text-dark fw-bold d-block fs-6'>{car.trips}</p>
                  </td>
                  <td>
                    <span
                      className={`badge ${
                        car.status === 'Maintenance'
                          ? 'badge-light-danger'
                          : car.status === 'Available'
                          ? 'badge-light-success'
                          : car.status === 'Ride in Progress'
                          ? 'badge-light-primary'
                          : ''
                      } fs-7 fw-semibold`}
                    >
                      {car.status}
                    </span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget10}
