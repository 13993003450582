import {MenuItem} from '../../../../_metronic/layout/components/header/header-menus/MenuItem'
import {MenuItemLegal} from '../../../../_metronic/layout/components/header/header-menus/MenuItemLegal'

const LegalHeader = () => {
  const handleState = (val: string) => {
    localStorage.setItem('legal', val)
  }
  const companyName = localStorage.getItem('companyName') as string
  return (
    // app-header-menu
    <div className='text-black'>
      <div className={`card mb-5 p-3`}>
        {/* begin::Body */}
        <div
          className='
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-stretch
        fw-semibold
        px-3 px-lg-0
        text-black
        bg-white
    '
          id='kt_app_header_menu'
          data-kt-menu='true'
        >
          <MenuItemLegal title='Rental Agreements' to={`rental-agreements`} />
          <MenuItemLegal title='Insurance Agreements' to={`insurance-agreements`} />
          <MenuItemLegal title='Refund Policy' to={`refund-policy`} />
          <MenuItemLegal title='Privacy Policy' to={`privacy-policy`} />
          <MenuItemLegal title='Terms of Service' to={`terms-of-service`} />
          <MenuItemLegal title='Custom' to={`custom`} />
        </div>
      </div>
    </div>
  )
}

export default LegalHeader
