import React, {useEffect, useState} from 'react'
import {Card4} from '../../../../_metronic/partials/content/cards/Card4'
import {getAllCustomerById} from '../../../modules/auth/core/_requests'

const Documents = () => {
  const [user, setUser] = useState<any | null>(null)
  const [customer, setCustomer] = useState<any>(null)

  const getAllCustomerByIds = async () => {
    const current = localStorage.getItem('user')
    if (current != null) {
      let dataTemp = JSON.parse(current)
      setUser(dataTemp)

      const data = await getAllCustomerById(dataTemp._id)

      // console.log(data)
      setCustomer(data.data.customer)
    }
    // setData(data.data?.customers)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  console.log(customer)
  return (
    <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
      {customer?.document.map((item: any) => (
        <div className='col-12 col-sm-12 col-xl-3'>
          <Card4 icon='/media/svg/files/pdf.svg' title='Documents' description='' link={item} />
        </div>
      ))}

      {/* <div className='col-12 col-sm-12 col-xl-3'>
        <Card4
          icon='/media/svg/files/doc.svg'
          title='PhotoId'
          description='2 days ago'
          link={customer?.document[0]}
        />
      </div> */}
    </div>
  )
}

export default Documents
