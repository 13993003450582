import React, {FC, useEffect, useRef, useState} from 'react'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {KTSVG} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelperLegal'
import {Step6} from '../../../pages/vehicle/CreateRental/steps/Step6'
import Swal from 'sweetalert2'
import {addLegal, AddVehicle} from '../../auth/core/_requests'
type Props = {
  value: string
}
const Horizontal: FC<Props> = ({value}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pdfFile, setPDfFile] = useState('')

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const handleImage = (event: any) => {
    console.log(event.target.files[0])
    setPDfFile(event.target.files[0])
  }
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    console.log(stepper.current.currentStepIndex)
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])
    console.log(values)
    values.key = value
    values.document = pdfFile
    const formData = new FormData()
    formData.append('title', values.title)
    formData.append('description', values.description)
    formData.append('key', values.key)
    formData.append('document', values.document)
    if (stepper.current.currentStepIndex === 3) {
      setLoading(true)
      // console.log(values)
      try {
        const {
          data: {data, message},
        } = await addLegal(formData)
        console.log(data)
        Swal.fire({
          timer: 3000,
          text: message,
          icon: 'success',
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        })
        // browserHistory.push('/vehicle/manage')
        window.location.reload()
      } catch (error) {
        // console.error(error)
        Swal.fire({
          timer: 3000,
          text: 'Vechile Not Added',
          icon: 'success',
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        })
        // setStatus('The Regsiter details are incorrect')
        // setSubmitting(false)
        setLoading(false)
      }
    }
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Upload File</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Document Description</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Completed</h3>
            </div>
          </div>

          {/* <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}> */}
          <Formik initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 handleImage={handleImage} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step6 />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Back
                    </button>
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {!isSubmitButton && 'Continue'}
                        {isSubmitButton && 'Submit'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {Horizontal}
