import React, {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

const Step2: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Document Description</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            FAQ Page
          </a>
          .
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Title</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='title'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='title' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Description</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='description'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='description' />
        </div>
      </div>
    </div>
  )
}

export {Step2}
