export interface IProfileDetails {
  profileImg: string
  firstName: string
  lastName: string
  companyName: string
  phone: string
  accountName: string
  companySite: string
  tagline: string
  country: string
  role: string

  timeZone: string
  status: string
  referrals: number
  preferedContact: {
    email: boolean
    phone: boolean
  }

  // notification: {
  //   newsletter: {
  //     email: Boolean
  //     phone: Boolean
  //   }
  //   tripDetails: {
  //     email: Boolean
  //     phone: Boolean
  //   }
  //   billingUpdates: {
  //     email: Boolean
  //     phone: Boolean
  //   }
  //   aggrementServices: {
  //     email: Boolean
  //     phone: Boolean
  //   }
  // }
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}
export interface AccountsConnected {
  facebook: boolean
  instagram: boolean
  twitter: boolean
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  facebook: boolean
  instagram: boolean
  twitter: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notification: {
    newsletter: {
      email: Boolean
      phone: Boolean
    }
    tripDetails: {
      email: Boolean
      phone: Boolean
    }
    billingUpdates: {
      email: Boolean
      phone: Boolean
    }
    aggrementServices: {
      email: Boolean
      phone: Boolean
    }
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  profileImg: '/media/avatars/profile.jpeg',
  firstName: 'Max',
  lastName: 'Smith',
  companyName: 'Keenthemes',
  phone: '044 3276 454 935',
  companySite: 'keenthemes.com',
  country: '',
  tagline: '',
  accountName: '',
  role: '',
  status: 'Active',
  timeZone: '',
  referrals: 0,
  // accountsConnected: {
  //   facebook: false,
  //   instagram: false,
  //   twitter: false,
  // },
  // notification: {
  //   newsletter: {
  //     email: true,
  //     phone: true,
  //   },
  //   tripDetails: {
  //     email: true,
  //     phone: true,
  //   },
  //   billingUpdates: {
  //     email: true,
  //     phone: false,
  //   },
  //   aggrementServices: {
  //     email: false,
  //     phone: true,
  //   },
  // },
  preferedContact: {
    email: false,
    phone: false,
  },
}
export const accountsConnected: AccountsConnected = {
  facebook: false,
  instagram: false,
  twitter: false,
}
export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}
export interface INotificationAccount {
  newsletter: {
    email: Boolean
    phone: Boolean
  }
  tripDetails: {
    email: Boolean
    phone: Boolean
  }
  billingUpdates: {
    email: Boolean
    phone: Boolean
  }
  aggrementServices: {
    email: Boolean
    phone: Boolean
  }
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  facebook: true,
  instagram: true,
  twitter: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notification: {
    newsletter: {
      email: true,
      phone: true,
    },
    tripDetails: {
      email: true,
      phone: true,
    },
    billingUpdates: {
      email: true,
      phone: false,
    },
    aggrementServices: {
      email: false,
      phone: true,
    },
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
