import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {Link} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {CreateRental} from '../CreateRental'
import {useEffect, useState} from 'react'
import {getAllVehicleId} from '../../../modules/auth/core/_requests'

// const events = [
//   { title: '2019 NISSAN GTR ', start: '2023-02-03' ,end:"2023-02-06",color:"green"},  { title: 'event 2', date: '2023-02-02' }
// ]

export function Calender() {
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)
  const [role, setRole] = useState('')
  const [events, setEvents] = useState([])
  const openCreateRentalModal = () => {
    setShowCreateRentalModal(true)
  }
  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
  }

  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('id')
    const roleUser = localStorage.getItem('user')
    if (roleUser != null) {
      let dataTemp = JSON.parse(roleUser)
      setRole(dataTemp?.role)
    }
    // console.log(user)
    // if (user != null) {
    // let dataTemp = JSON.parse(user)
    //  setUser(dataTemp)

    const data = await getAllVehicleId(user)
    // console.log(data)
    let allCustomer = data.data.vehicle?.trips
    let event = []
    allCustomer.forEach((item) => {
      const data = {
        title: item?.customerId?.accountName,
        start: item.selectedStartDate,
        end: item.selectedEndDate,
        color:
          item.status === 'Booked'
            ? 'purple'
            : item.status === 'In Progress'
            ? 'orange'
            : item.status === 'Booked'
            ? 'blue'
            : item.status === 'Completed'
            ? 'green'
            : item.status === 'Cancelled'
            ? 'blue'
            : '',
      }
      event.push(data)
    })
    setEvents(event)
    // setData(data.data?.customers)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  return (
    <>
      <div
        className='w-100 d-flex justify-content-between p-3 '
        style={{
          background: 'white',
          border: ' 1px solid transparent',
          padding: '1rem',
          borderBottom: '1px solid #72727296',
        }}
      >
        <h6>Booking Calender</h6>
        {role !== 'Broker' && (
          <Link to='#' onClick={openCreateRentalModal} className='btn btn-sm btn-dark me-3  w-auto'>
            Add Booking
          </Link>
        )}
      </div>

      <div>
        {/* <h1>Demo App</h1> */}

        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          weekends={true}
          events={events}
          eventContent={renderEventContent}
          style={{background: 'white', border: ' 1px solid transparent', padding: '2rem'}}
        />
      </div>
      {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModal} size='xl'>
          <Modal.Body>
            <CreateRental />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}
