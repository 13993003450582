/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../../_metronic/helpers'
import {getAllTeam} from '../../../modules/auth/core/_requests'
import {Link} from 'react-router-dom'
// import {getCSSVariableValue} from '../../../../assets/ts/_utils'
// import {Dropdown1} from '../../content/dropdown/Dropdown1'
// import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
//
type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const TeamMember: React.FC<Props> = ({className, chartColor}) => {
  const [team, setTeam] = useState([])
  const getAllCustomerByIds = async () => {
    const data = await getAllTeam()
    setTeam(data.data.data)
    console.log('data', data)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  const handleClick = (id: any) => {
    localStorage.setItem('id', id)
  }
  return (
    <div className='row g-5 g-xl-12  '>
      <h4>Team Members ( {team.length})</h4>
      {team.map((item: any) => (
        <div className='col-sm-3 text-center' style={{gap: '10px'}}>
          <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className='card-body'>
              {/* begin::Heading */}

              {/* begin:Image */}
              <div className='symbol symbol-100px  mb-3'>
                {/* <span className={clsx(`symbol-label`, `bg-${color}-light`)}> */}
                <img src={item?.profileImg} className='h-50 align-self-center' alt='' />
                {/* </span> */}
              </div>
              {/* end:Image */}

              {/* begin:Title */}
              <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                <Link
                  to='/broker/profile'
                  onClick={() => handleClick(item._id)}
                  className='text-dark fw-bold text-hover-primary fs-6'
                >
                  {item.accountName}
                </Link>
                {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-5'></a> */}

                {/* <span className='text-muted fw-semibold'>{date}</span> */}
              </div>
              <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                <a href='#' className='text-muted text-hover-primary fs-5'>
                  {item.jobTitle}
                </a>

                {/* <span className='text-muted fw-semibold'>{date}</span> */}
              </div>
              {/* begin:Menu */}
              <div className='ms-1 mt-3'>
                <a href='#' className={`btn btn-${chartColor} align-self-center`}>
                  {/* <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' /> */}
                  {item.status === 'Active' ? 'Verfied' : 'Pending Verfication'}
                </a>
                {/* <Dropdown1 /> */}
              </div>
              {/* end::Menu */}
              {/* end:Title */}
            </div>
            {/* begin:Info */}

            {/* end::Body */}
          </div>
        </div>
      ))}
    </div>
  )
}

export {TeamMember}
