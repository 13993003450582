import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Settings from './components/settings'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Brokers',
    path: '/broker',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardPage = () => (
  <Routes>
    <Route
      path='settings'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Brokers</PageTitle>
          <Settings />
        </>
      }
    />
    <Route
      path='blling'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Brokers</PageTitle>
          {/* <Overview /> */}
        </>
      }
    />
    <Route
      path='orders'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Brokers</PageTitle>
          {/* <Overview /> */}
        </>
      }
    />
    <Route
      path='security'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Brokers</PageTitle>
          {/* <Overview /> */}
        </>
      }
    />
    <Route
      path='refferals'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Brokers</PageTitle>
          {/* <Overview /> */}
        </>
      }
    />
    <Route
      path='api-keys'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Brokers</PageTitle>
          {/* <Overview /> */}
        </>
      }
    />
    <Route
      path='statement'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Brokers</PageTitle>
          {/* <Overview /> */}
        </>
      }
    />
    <Route index element={<Navigate to='/broker/settings' />} />
  </Routes>
)

export default DashboardPage
