/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {createBrowserHistory} from 'history'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {getAllCompanyById, login} from '../core/_requests'
import {notification} from 'antd'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  // const {saveAuth, setCurrentUser} = useAuth()
  const browserHistory = createBrowserHistory()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // let dat.a
      try {
        const data = await login(values.email, values.password)
        // console.log(data.data.data.token)
        localStorage.setItem('auth_token', data.data.data.token)
        localStorage.setItem('user', JSON.stringify(data.data.data.user))
        if (data.data.data.user.role === 'Company') {
          localStorage.setItem('companyName', data.data.data.user.companyName as string)
        }
        if (data.data.data.user.role !== 'Admin') {
          const company = await getAllCompanyById(data.data.data.user.companyName)
          let key = company.data?.customer[0].paymentMethods.filter(
            (item: any) => item.paymentType === 'Stripe'
          )
          console.log(key)
          if (key.length !== 0) {
            localStorage.setItem('publisherKey', key[0].publisherKey as string)
          }
          setLoading(false)
        }
        console.log('data.data.data.user.role', data.data.data.user.role)
        if (data.data.data.user.role === 'Admin') {
          browserHistory.push('/company/users')
          window.location.reload()
        } else {
          const companyName = localStorage.getItem('companyName') as string
          browserHistory.push(`/${companyName}/dashboard/overview`)
          window.location.reload()
        }
        // localStorage.setItem('role', data.data.user.role)
      } catch (e: any) {
        // console.log(e.response.data.message)
        notification.error({
          message: e.response.data.message,
          onClick: () => {
            console.log('Notification Clicked!')
          },
          duration: 4,
        })
        // saveAuth(undefined)
        // setStatus('The login details are incorrect')
        // setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-left mb-11'>
        <h1 className='text-dark fw-bolder mb-1'>Welcome to Corsa Dash</h1>
        <div className='text-muted fw-semibold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-dark fw-bold'>
            Request Access Now
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-5'>
        <div className='d-flex flex-row justify-content-between'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <Link to='/auth/forgot-password' className='link-dark fw-bold'>
            Forgot Password?
          </Link>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-light',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-flex my-5 gap-4'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-dark'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='col-md-6'>
          {/* begin::Google link */}
          {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}
          {/* end::Google link */}
        </div>
      </div>
      {/* end::Action */}
    </form>
  )
}
