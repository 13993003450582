import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import LegalHeader from './components/LegalHeader'
import {RentalAgreementTable} from './components/RentalAfgreements'
const companyName = localStorage.getItem('companyName') as string
const LegalPage = () => (
  <Routes>
    <Route
      element={
        <>
          <LegalHeader />
          <Outlet />
        </>
      }
    >
      <Route
        path={`rental-agreements`}
        element={
          <>
            <RentalAgreementTable className='mb-5' value='Rental Agreements' />
          </>
        }
      />
      <Route
        path={`insurance-agreements`}
        // path='insurance-agreements'
        element={
          <>
            <RentalAgreementTable className='mb-5' value='Insurance Agreements' />
          </>
        }
      />
      <Route
        path={`refund-policy`}
        element={
          <>
            <RentalAgreementTable className='mb-5' value='Refund Policy' />
          </>
        }
      />
      <Route
        path={`privacy-policy`}
        element={
          <>
            <RentalAgreementTable className='mb-5' value='Privacy Policy' />
          </>
        }
      />
      <Route
        path={`terms-of-service`}
        element={
          <>
            <RentalAgreementTable className='mb-5' value='Terms Of Service' />
          </>
        }
      />
      <Route
        path={`custom`}
        element={
          <>
            <RentalAgreementTable className='mb-5' value='Custom' />
          </>
        }
      />

      <Route index element={<Navigate to={`/${companyName}/legal/rental-agreements`} />} />
    </Route>
  </Routes>
)

export {LegalPage}
