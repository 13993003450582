import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MenuItem} from './MenuItem'
import {getAllCompanyById} from '../../../../../app/modules/auth/core/_requests'

export function MenuInner() {
  const [user, setUser] = useState<any | null>(null)
  const [companyName, setCompanyName] = useState('')
  const intl = useIntl()
  useEffect(() => {
    const data = localStorage.getItem('user')
    if ((localStorage.getItem('companyName') as string) !== undefined) {
      setCompanyName(localStorage.getItem('companyName') as string)
      // console.log(data)
    }
    if (data != null) {
      let dataTemp = JSON.parse(data)
      setUser(dataTemp)
    }
  }, [])
  console.log(companyName, 'companyName')
  return (
    <>
      {(user?.role === 'Company' || user?.role === 'Employee') && (
        <>
          <MenuItem
            title='Dashboard'
            // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            to={`/${companyName}/dashboard/overview`}
          />
          {/* <MenuInnerWithSub
            title='Vehicles'
            to='/vehicle'
            menuPlacement='bottom-start'
            menuTrigger={"{default:'click', lg: 'hover'}"}
          >
            <MenuItem title='Manage' to='/vehicle/manage' />
            <MenuItem title='Notification Center' to='/vehicle/auto-insurance' />
            <MenuItem title='My Tasks' to='/vehicle/saved-drafts' />
            <MenuItem title='Analytics' to='/vehicle/statements' />
            <MenuItem title='Help' to='/vehicle/help' />
          </MenuInnerWithSub> */}
          <MenuItem title='Vehicles' to={`/${companyName}/vehicle`} />
          <MenuItem title='Customers' to={`/${companyName}/customer`} />
          {/* <MenuInnerWithSub
            title='Customer'
            to='/customer'
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/customer/overview' title='Customers' />
            <MenuItem title='Create Customer' to='/customer/createCustomer' />
          </MenuInnerWithSub> */}
          <MenuItem title='Trips' to={`/${companyName}/trips/overview`} />
          {/* <MenuInnerWithSub
            title=''
            to='/trips'
            menuPlacement='bottom-start'
            menuTrigger={"{default:'click', lg: 'hover'}"}
          >
            <MenuItem title='Overview' to='/trips/overview' />
          </MenuInnerWithSub> */}
          <MenuItem title='Legal' to={`/${companyName}/legal`} />
          <MenuItem title='Settings' to={`${companyName}/setting/settings`} />
          {/* <MenuInnerWithSub
            title='Setting'
            to='/setting'
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/setting/settings' title='Settings' />
          </MenuInnerWithSub> */}
        </>
      )}
      {user?.role === 'Broker' && (
        <>
          <MenuItem
            title='Dashboard'
            // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            to={`/${companyName}/dashboard/overview`}
          />
          <MenuInnerWithSub
            title='Vehicles'
            to='/vehicle'
            menuPlacement='bottom-start'
            menuTrigger={"{default:'click', lg: 'hover'}"}
          >
            <MenuItem title='Manage' to='/vehicle/manage' />
            <MenuItem title='Notification Center' to='/vehicle/auto-insurance' />
            <MenuItem title='My Tasks' to='/vehicle/saved-drafts' />
            <MenuItem title='Analytics' to='/vehicle/statements' />
            <MenuItem title='Help' to='/vehicle/help' />
          </MenuInnerWithSub>

          <MenuInnerWithSub
            title='Brokers'
            to='/broker'
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/broker/settings' title='Settings' />
            {/* <MenuItem to='/broker/billing' title='Billing' /> */}
            {/* <MenuInnerWithSub
          title='Orders'
          to='/broker/orders'
          hasArrow
          menuPlacement='right-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          {/* <MenuItem title='Overview' to='/broker/orders/overview' /> */}
            {/* </MenuInnerWithSub> */}
            {/* <MenuItem to='/broker/security' title='Security' /> 
        <MenuItem to='/broker/refferals' title='Refferals' />
        <MenuItem to='/broker/api-keys' title='API Keys' />
        <MenuItem to='/broker/statements' title='Statement' /> */}
          </MenuInnerWithSub>
          <MenuItem title='Customer' to={`/${companyName}/customer/overview`} />

          <MenuInnerWithSub
            title='Trips'
            to='/trips'
            menuPlacement='bottom-start'
            menuTrigger={"{default:'click', lg: 'hover'}"}
          >
            <MenuItem title='Overview' to='/trips/overview' />
          </MenuInnerWithSub>
        </>
      )}
      {user?.role === 'Admin' && (
        <>
          <MenuItem
            title='Users'
            // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            to='/company/users'
          />
          <MenuItem title='Company Registration' to='/registration' />
        </>
      )}
      {user?.role === 'Customer' && (
        <>
          <MenuItem
            title='Dashboard'
            // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            to={`/${companyName}/dashboard/overview`}
          />
          <MenuItem title='Activity ' to={`/${companyName}/customers/actvity`} />
          <MenuItem title='Agreement ' to={`/${companyName}/customers/agreement`} />
          {/* <MenuItem title='Setting ' to={`/${companyName}/activity`} /> */}
        </>
      )}
    </>
  )
}
