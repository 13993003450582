import {useState} from 'react'

import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import {createBrowserHistory} from 'history'
import Swal from 'sweetalert2'
import {register} from '../core/_requests'
import {createAccountSchemas, ICreateAccount, inits} from './AddVehicle/CreateAccountWizardHelper'
export function Registration() {
  const [loading, setLoading] = useState(false)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [role, setRole] = useState('')
  // const {saveAuth, setCurrentUser} = useAuth()
  const browserHistory = createBrowserHistory()

  const onSubmit = async (values: ICreateAccount, actions: FormikValues) => {
    console.log('sks')
    setLoading(true)
    // let dat.a
    try {
      const {
        data: {data, message},
      } = await register(values)
      // console.log(data)
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })

      setLoading(false)
      browserHistory.push('/auth/login')
      window.location.reload()
      // localStorage.setItem('role', data.data.user.role)
    } catch (e: any) {
      // console.log(e.response.data.message)
      Swal.fire({
        timer: 3000,
        text: e.response.data.message,
        icon: 'error',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // saveAuth(undefined)

      setLoading(false)
    }
  }
  console.log(role)

  return (
    <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
      <Formik initialValues={initValues} validationSchema={currentSchema} onSubmit={onSubmit}>
        {(values: any) => {
          // console.log(values.values)
          setRole(values.values.role)
          return (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                  Personal Information{' '}
                </h2>

                <div className='text-gray-400 fw-bold fs-6'>
                  Already Have Account
                  <a href='/login' className='link-primary fw-bolder'>
                    {' '}
                    Sign In
                  </a>
                </div>
              </div>

              <div className='fv-row'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>Email</span>
                    </label>
                    <Field
                      type='email'
                      // value=''
                      className='form-control form-control-lg form-control-solid'
                      name='email'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='email' />
                    </div>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className=''>Role</span>
                  </label>
                  <Field
                    as='select'
                    name='role'
                    className='form-select form-select-lg form-select-solid'
                  >
                    <option value=''>Choose Role</option>
                    {/* <option value='Customer'>Customer</option> */}
                    <option value='Admin'>Admin</option>
                    <option value='Broker'>Broker</option>
                    <option value='Employee'>Employee</option>
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='role' />
                  </div>
                </div>
                {role !== 'Admin' && (
                  <div className='col-lg-12'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>Referal Code</span>
                    </label>
                    <Field
                      type='text'
                      // value=''
                      className='form-control form-control-lg form-control-solid'
                      name='referalCode'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='referalCode' />
                    </div>
                  </div>
                )}
                {role !== 'Employee' && (
                  <div className='col-lg-12'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>Job Title</span>
                    </label>
                    <Field
                      type='text'
                      // value=''
                      className='form-control form-control-lg form-control-solid'
                      name='jobTitle'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='jobTitle' />
                    </div>
                  </div>
                )}
                <div className='col-lg-12'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className=''>Password </span>
                  </label>
                  <Field
                    type='password'
                    // value=''
                    className='form-control form-control-lg form-control-solid'
                    name='password'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='password' />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className=''>Confirm Password </span>
                  </label>
                  <Field
                    type='password'
                    // value=''
                    className='form-control form-control-lg form-control-solid'
                    name='confPassword'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='confPassword' />
                  </div>
                </div>
                <div className='d-flex my-5 gap-4'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-dark'
                    // disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Sign Up</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
