/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field} from 'formik'
import {FC} from 'react'
type Props = {
  data: any
  values: any
}
const Step1: FC<Props> = ({data, values}) => {
  console.log('values', values.values)
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>Select Vehicle</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/faq' className='link-primary fw-bolder'>
            {' '}
            FAQ Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className=''>Vehicle</span>
            </label>

            <Field
              as='select'
              name='vehicle'
              value={values.values.vehicle}
              className='form-select form-select-lg form-select-solid'
            >
              <option value=''>Choose Vehicle</option>
              {data.map((item: any) => (
                <option value={item._id}>{item.vehicleType}</option>
              ))}
            </Field>
          </div>
        </div>
        {/* <div className='row'>
          <div className='col-md-6'>
            <label className='col-lg-12 col-form-label fw-bold fs-6'>
              <span className=''>Select Start Day</span>
            </label>
            <Field
              as='select'
              name='businessType'
              className='form-select form-select-lg form-select-solid'
            >
              <option value=''></option>
              <option value='1'>2022</option>
              <option value='2'>2021</option>
              <option value='3'>2020</option>
            </Field>
          </div>
          <div className='col-md-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className=''>Select Time</span>
            </label>
            <Field
              as='select'
              name='businessType'
              className='form-select form-select-lg form-select-solid'
            >
              <option value=''></option>
              <option value='1'>McLaren</option>
              <option value='2'>BMW</option>
              <option value='3'>Tesla</option>
            </Field>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='col-lg-6 col-form-label fw-bold fs-6'>
              <span className=''>Select Return Day</span>
            </label>
            <Field
              as='select'
              name='businessType'
              className='form-select form-select-lg form-select-solid'
            >
              <option value=''></option>
              <option value='1'>Urus</option>
              <option value='2'>BMW</option>
              <option value='3'>Tesla</option>
            </Field>
          </div>
        <div className='col-md-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Select Time</span>
          </label>
          <Field
            as='select'
            name='businessType'
            className='form-select form-select-lg form-select-solid'
          >
            <option value=''></option>
            <option value='1'>Base</option>
            <option value='2'>Trim</option>
            <option value='3'>Trim</option>
          </Field>
        </div>
        </div>
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Comments (Optional)</span>
          </label>
          <Field
            as='select'
            name='businessType'
            className='form-select form-select-lg form-select-solid'
          >
            <option value=''></option>
            <option value='1'>Base</option>
            <option value='2'>Trim</option>
            <option value='3'>Trim</option>
          </Field>
        </div> */}
      </div>
    </div>
  )
}

export {Step1}
