/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'

import {createBrowserHistory} from 'history'
import Swal from 'sweetalert2'
import {
  createReferal,
  login,
  updateProfile,
  updateProfileLocation,
} from '../../../../../modules/auth/core/_requests'
type Props = {
  closeCreateRentalModal: (arg: any) => void
  customer: any
}
const loginSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address1: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('ZipCode is required'),
  city: Yup.string().required('City is required'),
})

const initialValues = {
  name: '',
  address1: '',
  address2: '',
  state: '',
  zipCode: '',
  city: '',
}

const Location: React.FC<Props> = ({closeCreateRentalModal, customer}) => {
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState('')
  // const {saveAuth, setCurrentUser} = useAuth()
  const browserHistory = createBrowserHistory()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let dataTemp
      const user = localStorage.getItem('user')
      console.log(user)
      if (user != null) {
        dataTemp = JSON.parse(user)
        //  setUser(dataTemp)
      }
      const location = [
        {
          name: formik.values.name,
          address1: formik.values.address1,
          address2: formik.values.address2,
          city: formik.values.city,
          state: formik.values.state,
          zipCode: formik.values.zipCode,
        },
      ]
      console.log(customer)
      let dataUpdated = customer?.locations
      dataUpdated.push(location[0])
      console.log(dataUpdated)

      let dataTo = {
        locations: dataUpdated,
      }
      try {
        const {
          data: {data, message},
        } = await updateProfileLocation(dataTemp._id, dataTo)
        // console.log(data)
        Swal.fire({
          timer: 3000,
          text: message,
          icon: 'success',
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        })
        setLoading(false)
        //  browserHistory.push('/auth/welcomeScreen')
        window.location.reload()
      } catch (error) {
        // stepper.current.goNext()
        // console.error(error)

        // setStatus('The Regsiter details are incorrect')
        // setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-left mb-11'>
        <h1 className='text-dark fw-bolder mb-1'>Add a New Address</h1>
        <p className='text-muted'>
          If you need more info, please check out<a> FAQ Page</a>
        </p>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.name && formik.errors.name},
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
          type='text'
          name='name'
          autoComplete='off'
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.name}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Address 1</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('address1')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.address1 && formik.errors.address1},
            {
              'is-valid': formik.touched.address1 && !formik.errors.address1,
            }
          )}
          type='text'
          name='address1'
          autoComplete='off'
        />
        {formik.touched.address1 && formik.errors.address1 && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.address1}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Address 2 (Optional)</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('address2')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.address2 && formik.errors.address2},
            {
              'is-valid': formik.touched.address2 && !formik.errors.address2,
            }
          )}
          type='text'
          name='address2'
          autoComplete='off'
        />
        {formik.touched.address2 && formik.errors.address2 && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.address2}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>City</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('city')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.city && formik.errors.city},
            {
              'is-valid': formik.touched.city && !formik.errors.city,
            }
          )}
          type='text'
          name='city'
          autoComplete='off'
        />
        {formik.touched.city && formik.errors.city && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.city}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>State</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('state')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.state && formik.errors.state},
            {
              'is-valid': formik.touched.state && !formik.errors.state,
            }
          )}
          type='text'
          name='state'
          autoComplete='off'
        />
        {formik.touched.state && formik.errors.state && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.state}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>ZipCode</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('zipCode')}
          className={clsx(
            'form-control bg-light',
            {'is-invalid': formik.touched.zipCode && formik.errors.zipCode},
            {
              'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
            }
          )}
          type='number'
          name='zipCode'
          autoComplete='off'
        />
        {formik.touched.zipCode && formik.errors.zipCode && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.zipCode}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-flex my-5 gap-4'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-dark'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Add Address</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='col-md-6'>
          <button type='button' className='btn btn-dark'>
            <span className='indicator-label'>Cancel</span>
          </button>
        </div>
      </div>
      {/* end::Action */}
    </form>
  )
}
export {Location}
