import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import CustomerPage from './CustomerPage'

const CustomerWrapper = () => (
  <>
    <CustomerPage />
  </>
)

export default CustomerWrapper
