import * as Yup from 'yup'

export interface ICreateAccount {
  paymentType: string
  name: string
  accountNumber: string
  routing: string
  address: string
  billingAddress: string
  instruction: string
  publisherKey: string
  secretKey: string
}

const createAccountSchemas = [
  Yup.object({
    title: Yup.string().required().label('Title Is Required'),
  }),
  Yup.object({
    description: Yup.string().required().label('Description Is Required'),
  }),
]

const inits: ICreateAccount = {
  paymentType: '',
  name: '',
  accountNumber: '',
  routing: '',
  address: '',
  billingAddress: '',
  instruction: '',
  publisherKey: '',
  secretKey: '',
}

export {createAccountSchemas, inits}
