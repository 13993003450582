import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword, resetPassword, verify} from '../core/_requests'
import Swal from 'sweetalert2'
import {useParams} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
const initialValues = {
  password: '',
  confPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export function VerifyAccount() {
  const [loading, setLoading] = useState(false)
  const browserHistory = createBrowserHistory()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const queryParams = new URLSearchParams(window.location.search)
  const {id} = useParams()

  // console.log(id)
  const verifyAccount = async () => {
    setLoading(true)
    let data
    try {
      data = await verify(id)
      setLoading(false)
      Swal.fire({
        timer: 3000,
        text: data.data.message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
    } catch (error: any) {
      Swal.fire({
        timer: 3000,
        text: error.response.data.message,
        icon: 'error',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      setLoading(false)
    }
    // browserHistory.push('/auth/login')
    // window.location.reload()
  }
  useEffect(() => {
    verifyAccount()
  }, [])

  return (
    <>
      {loading ? (
        <h2>Please Wait Account is verifying</h2>
      ) : (
        <div className='d-flex flex-column  bgi-size-cover justify-content-center align-items-center p-10'>
          {/* begin::Content */}
          <div className='d-flex flex-column flex-center p-10'>
            {/* begin::Logo */}
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/logo.png')} className='h-40px mb-5' /> */}
            {/* end::Logo */}

            {/* begin::Title */}

            <h3 className='text-white text-center mb-7 text-dark' style={{fontSize: '30px'}}>
              Email Verified
            </h3>

            {/* begin::Text */}

            {/* end::Text */}
            <div className='h-500px'>
              <img alt='Logo' src={toAbsoluteUrl('/media/S_email.png')} className='h-500px' />
            </div>
          </div>
          {/* <div
          className='d-flex w-100 h-50 bgi-size-cover bgi-position-center'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/logos/s_present.png')})`}}
        ></div> */}
          {/* end::Content */}
        </div>
      )}
    </>
  )
}
