import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

import {AuthProvider, setupAxios} from './app/modules/auth'
import {AppRoutes} from './app/routing/AppRoutes'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/style.scss'

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        {/* <AuthProvider> */}
        <AppRoutes />
        {/* </AuthProvider> */}
      </MetronicI18nProvider>
    </QueryClientProvider>
  )
}
