import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {KTSVG} from '../../../../../../_metronic/helpers'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='text-gray-400 fw-bold fs-6  mb-5' style={{textAlign: 'right'}}>
        Having Issues?
        <a href='/dashboard' className='link-primary fw-bolder'>
          {' '}
          Get Help
        </a>
        .
      </div>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Package Selection </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Already Have Account
          <a href='/login' className='link-primary fw-bolder'>
            {' '}
            Sign In
          </a>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <Field
              type='radio'
              className='btn-check'
              name='payment'
              value='personal'
              id='kt_create_account_form_account_type_personal'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center '
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com005.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  StartUp Package - $99/monthly
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Access to the basic functionality. Click to learn more
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-12'>
            <Field
              type='radio'
              className='btn-check'
              name='payment'
              value='bussiness'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  Bussiness Package - $199.99/monthly
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Access to the entire Corsa Dash CRM. Click to learn more
                </span>
              </span>
            </label>
          </div>
          <div className='col-lg-12'>
            <Field
              type='radio'
              className='btn-check'
              name='payment'
              value='enterprice'
              id='kt_create_account_form_account_type_enterprice'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_enterprice'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com005.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  Enterprice Package - $999/monthly
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Access to the basic functionality. Click to learn more
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3}
