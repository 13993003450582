import {Navigate, Outlet, Route, Routes} from 'react-router-dom'

import {Projects} from '../../modules/profile/components/Projects'
import Overview from './Overview'

const ProfilePageWizard = () => (
  <Routes>
    <Route
      element={
        <>
          {/* <LegalHeader /> */}
          <Outlet />
        </>
      }
    >
      <Route
        path='settings'
        element={
          <>
            {/* <PageTitle breadcrumbs={profileBreadCrumbs}>Projects</PageTitle> */}
            <Overview />
          </>
        }
      />

      <Route index element={<Navigate to='/profile/settings' />} />
    </Route>
  </Routes>
)

export default ProfilePageWizard
