import {FC, useEffect, useState} from 'react'
import {CardsWidget7, ListsWidget26} from '../../../../_metronic/partials/widgets'
import {getAllCustomers, getAllCustomerStat} from '../../../modules/auth/core/_requests'
import {CustomerStatsCard} from './CustomerStatsCard'
import {CustomerTableWrapper} from './CustomerTableWrapper'
import {UsersListWrapper} from './users-list/UsersList'
const trips: any[] = []
const Overview: FC = () => {
  const [stat, setStatistics] = useState<any>(null)
  const [earning, setEarning] = useState<any>(null)
  const [data, setData] = useState<any>([])

  const getAllCustomerStatistics = async () => {
    const data = await getAllCustomerStat()
    let statistice = {
      availableCustomer: data.data?.customerListing.availableCustomer,
      blockListedCustomer: data.data?.customerListing.availableCustomer,
      notActiveCustomer: data.data?.customerListing.notActiveCustomer,
      totalCustomer: data.data?.customerListing.totalCustomer,
      newCustomerCount: data.data?.customers.newCustomerCount,
      newCustomerData: data.data?.customers.newCustomerData,
    }
    // console.log(statistice)
    setStatistics(statistice)

    const customer = await getAllCustomers()
    customer.data?.customers.forEach((item: any) => {
      if (item?.tripId !== undefined && item.tripId.length !== 0) {
        trips.push(item?.tripId[0])
      }
    })
    // console.log('sss', trips)
    let total = 0
    let totalCost: any[] = []
    trips.forEach((item) => {
      totalCost.push(item.total)
      total += item.total
    })
    let min = Math.min(...totalCost)
    let customerStatistice = {
      total: Number.isNaN(total) ? 0 : total,
      avgTotal: Number.isNaN(total / trips.length) ? 0 : total / trips.length,
      lowCost: Number.isNaN(min) ? 0 : min,
      // totalCustomer: data.data?.customerListing?.totalCustomer,
    }
    // console.log(customerStatistice)
    setEarning(customerStatistice)

    // setData(data.data?.customers)
  }
  useEffect(() => {
    getAllCustomerStatistics()
  }, [])

  return (
    <>
      {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
         
         <CustomerStatsCard stat={stat} className='h-md-100 mb-5 mb-xl-10' /> 
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
           <ListsWidget26 earning={earning} className='h-lg-100' /> 
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <CardsWidget7
            stat={stat}
            className='h-md-100 mb-5 mb-xl-10'
            description='Pending Customer Verifications'
            icon={false}
            stats={stat?.newCustomerCount}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
      {/* </div> */}
      {/* <UsersListWrapper /> */}
      <CustomerTableWrapper className='card-xl-stretch mb-xl-8' />
    </>
  )
}

export default Overview
