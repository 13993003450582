import React, {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {LegalPage} from './LegalPage'

const LegalPageWrapper: FC = () => {
  const tripsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Legal',
      path: '/legal',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={tripsBreadCrumbs}>Legal Documents</PageTitle>
      <LegalPage />
    </>
  )
}

export default LegalPageWrapper
