import {useEffect, useState} from 'react'
import {getAllCustomerById} from '../../../modules/auth/core/_requests'
import {ProfileDetails} from './ProfileDetails'

const CustomerProfileOverview = () => {
  const [customer, setCustomer] = useState<any>(null)

  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('user')
    console.log(user)
    if (user != null) {
      let dataTemp = JSON.parse(user)
      //  setUser(dataTemp)

      const data = await getAllCustomerById(dataTemp._id)

      // console.log(data)
      setCustomer(data.data.customer)
      // setData(data.data?.customers)
    }
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  return (
    <>
      <ProfileDetails customer={customer} />
    </>
  )
}

export default CustomerProfileOverview
