/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../_metronic/helpers'
import {deleteById, resend} from '../../../../modules/auth/core/_requests'
import Spinner from '../../../brokers/components/BrokerTable/Spinner'
import {Card} from 'react-bootstrap'

type Props = {
  className: string
  data: any
  loading: boolean
}
const companyName = localStorage.getItem('companyName') as string
const CustomerTable: React.FC<Props> = ({className, data, loading}) => {
  // console.log(data)
  const handleClick = (id: any) => {
    localStorage.setItem('id', id)
  }
  const sendVerification = async (email: any) => {
    console.log(email)
    const {
      data: {data, message},
    } = await resend(email)
    // console.log(data)
    Swal.fire({
      timer: 3000,
      text: message,
      icon: 'success',
      showCloseButton: false,
      showConfirmButton: false,
      showDenyButton: false,
    })
  }
  const handleDelete = (id: any) => {
    // console.log(val);
    Swal.fire({
      title: 'Do you want to Delete this Customer?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const withJWT = true
        try {
          const user = localStorage.getItem('user')
          if (user != null) {
            let dataTemp = JSON.parse(user)
            //  setUser(dataTemp)

            const data = await deleteById(id)
            // console.log(data.data.data.token)
            Swal.fire({
              timer: 3000,
              text: data.data.message,
              icon: 'success',
              showCloseButton: false,
              showConfirmButton: false,
              showDenyButton: false,
            })

            // browserHistory.push('/customer/overview')
            window.location.reload()
          }
          // localStorage.setItem('role', data.data.user.role)
        } catch (error) {
          console.error(error)
          // saveAuth(undefined)
        }
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {/* begin::Table */}
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <div className='table-responsive'>
            {data.length === 0 ? (
              <Card style={{borderRadius: '8px'}}>
                <Card.Body>
                  <Card.Text>
                    <h4>No Customer Available</h4>
                    <p className='text-muted'>
                      <a href='#' className='text-muted me-2 fw-bold' id='kt_user_follow_button'>
                        <span className='indicator-label'>
                          You can add vehicles by clicking here{' '}
                          <Card.Link
                            href={`/${companyName}/customer/createCustomer`}
                            className=' px-4'
                          >
                            Create Customer
                          </Card.Link>
                        </span>
                      </a>
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            ) : (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className=''>NAME</th>
                    <th className='d-flex align-items-center'>
                      JOIN DATE&nbsp;&nbsp;
                      <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5' />
                    </th>
                    <th className=''>TRIPS</th>
                    <th className=''>STATUS</th>
                    <th className='text-end'>DETAILS</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.map((car: any) => (
                    <tr key={car.accountName}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img src={car?.profileImg} className='rounded-circle' alt='' />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link
                              to={`/${companyName}/customer/profile/overview`}
                              onClick={() => handleClick(car._id)}
                              className='text-dark fw-bold text-hover-primary fs-4'
                            >
                              {car.accountName}
                            </Link>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {car.email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h5 className='text-dark fw-bold'>{car.createdAt.split('T')[0]}</h5>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className='text-dark fw-bold d-block '>
                          {car.tripId.length}&nbsp;Trips
                        </h5>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            car.status === 'Not Active'
                              ? 'badge-light-danger'
                              : car.status === 'Active'
                              ? 'badge-light-success'
                              : car.status === 'BlockListed'
                              ? 'badge-light-primary'
                              : ''
                          } fs-7 fw-semibold`}
                        >
                          {car.status}
                        </span>
                      </td>
                      <td className='text-end'>
                        {/* <a
                          href='#'
                          className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                        >
                          Edit
                        </a> */}
                        <button
                          // disabled={isLoading}
                          type='button'
                          className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          Action
                        </button>
                        {/* end::Filter Button */}
                        {/* begin::SubMenu */}
                        <div
                          className='menu menu-sub menu-sub-dropdown w-150px w-md-175px'
                          data-kt-menu='true'
                        >
                          {/* begin::Content */}
                          <div className='px-7 py-5' data-kt-user-table-filter='form'>
                            <div
                              // className='mb-10'
                              style={{borderBottom: ' 1px solid ', padding: '1rem'}}
                            >
                              <Link
                                to='/customer/profile'
                                onClick={() => handleClick(car._id)}
                                className='text-dark fw-bold text-hover-primary fs-6'
                              >
                                Edit
                              </Link>
                              {/* <label className='form-label fs-6 fw-bold'>Edit</label> */}
                            </div>
                            <div
                              // classNam e='mb-10'
                              style={{borderBottom: ' 1px solid ', padding: '1rem'}}
                              // onClick={() => handleFilter(2)}
                              onClick={() => sendVerification(car.email)}
                            >
                              <label className='form-label fs-6 fw-bold'>Send Verification</label>
                            </div>
                            <div
                              // className='mb-10'
                              style={{padding: '1rem'}}
                              onClick={() => handleDelete(car._id)}
                            >
                              <label className='form-label fs-6 fw-bold'>Remove</label>
                            </div>
                          </div>
                          {/* end::Content */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
          </div>
        )}
        {/* end::Table */}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {CustomerTable}
