/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Button} from 'react-bootstrap'

type Props = {
  className: string
  title: string
  description: string
  text: string
  amount: number
  openCreateRentalModal: (id: any) => void
}

const FeedsWidgetPay: React.FC<Props> = ({
  className,
  title,
  text,
  description,
  amount,
  openCreateRentalModal,
}) => {
  return (
    <div className={`card ${className}`} style={{height: '95%'}}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}

            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bold'>
                {title}
              </a>
              <span className='text-gray-400 fw-semibold fs-4'>{description}</span>
              <span className='text-gray-400 fw-semibold fs-4'>Total Amount: ${amount}</span>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}
        </div>
        {/* end::Header */}

        {/* begin::Post */}

        {/* end::Bottom */}

        {/* begin::Separator */}
        <div className='separator mb-4'></div>
        {/* end::Separator */}

        {/* begin::Reply input */}
        {/* edit::Reply input */}
      </div>
      <div style={{padding: '2.25rem'}}>
        <Button onClick={openCreateRentalModal} className='w-50'>
          {' '}
          {text}
        </Button>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {FeedsWidgetPay}
