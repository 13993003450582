/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useRef, useState} from 'react'
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
// import {loadStripe} from '@stripe/stripe-js'
// import {Elements} from '@stripe/react-stripe-js'
import {KTSVG} from '../../../_metronic/helpers'
import {Step3} from '../../modules/auth/components/AddVehicle/steps/Step3'
import {Step4} from '../../modules/auth/components/AddVehicle/steps/Step4'

// import {Step5} from '../../modules/auth/components/AddVehicle/steps/Step5'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues, useFormik, FormikContext} from 'formik'
import {
  ICreateAccount,
  createAccountSchemas,
  inits,
} from '../../modules/auth/components/AddVehicle/CreateAccountWizardHelper'
// import {Step6} from './AddVehicle/steps/Step6'
import {
  allUsers,
  getAllCompanyUsers,
  register,
  verfiyPayment,
} from '../../modules/auth/core/_requests'
import {Value} from 'sass'
import Swal from 'sweetalert2'
import {createBrowserHistory} from 'history'
import {Step6} from '../../modules/auth/components/AddVehicle/steps/Step6'

const EmailVerificationPaument = () => {
  const stripe = useStripe()
  const elements = useElements()
  const browserHistory = createBrowserHistory()
  const {id} = useParams()
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  const [role, setRole] = useState('')
  const [user, setUser] = useState([])
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [loading, setLoading] = useState(false)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    // setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])
    let name = values.accountName
    let email = values.email
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      // console.log('sjjs', stepper.current.currentStepIndex, stepper.current.totatStepsNumber)

      if (stepper.current.currentStepIndex === 2) {
        const paymentMethod = await stripe?.createPaymentMethod({
          type: 'card',
          card: elements?.getElement(CardElement)!,
          billing_details: {name, email},
        })
        values.paymentMethodSelected = paymentMethod?.paymentMethod?.id
        console.log(values)

        setLoading(true)
        try {
          const {
            data: {data, message},
          } = await verfiyPayment(values, id)
          // console.log(data)
          const confirmPayment = await stripe?.confirmCardPayment(data.clientSecret)

          if (confirmPayment?.error) {
            alert(confirmPayment.error.message)
          } else {
            Swal.fire({
              timer: 3000,
              text: 'Success! Check your email for the invoice.',
              icon: 'success',
              showCloseButton: false,
              showConfirmButton: false,
              showDenyButton: false,
            })

            // alert('Success! Check your email for the invoice.')
          }

          browserHistory.push('/auth/welcomeScreen')
          window.location.reload()

          // stepper.current.goNext()
        } catch (error) {
          console.error(error)

          // setStatus('The Regsiter details are incorrect')
          // setSubmitting(false)
          setLoading(false)
        }
        // stepper.current.goNext()

        // stepper.current.goNext()
      } else {
        stepper.current.goNext()
      }
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  // console.log(formik.)
  const handleUser = async () => {
    const data = await getAllCompanyUsers()
    setUser(data.data.customers)
    // console.log(data.data.data.token)
    // await allUsers()
  }
  useEffect(() => {
    handleUser()
  }, [])
  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column mt-5 d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <>
        <div className='d-flex flex-column w-lg-500px bgi-size-cover justify-content-center align-items-center bg-black'>
          {/* begin::Content */}

          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            {/* begin::Nav*/}
            <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
              {/* begin::Logo */}
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/Logo.png')}
                className='h-40px mb-5'
              />
            </div>
            <div className='stepper-nav'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Package Selection</h3>

                    <div className='stepper-desc '>Add Your Support Agent</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}

              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Make Payment</h3>
                    <div className='stepper-desc fw-semibold'>Use Debit or Credit Card</div>
                  </div>
                  {/* end::Label*/}

                  {/* end::Wrapper*/}
                </div>
                <div className='stepper-line h-40px'></div>
              </div>
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Completed</h3>
                    <div className='stepper-desc '>Completed</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>

              {/* end::Step 2*/}

              {/* end::Step 4*/}

              {/* end::Step 5*/}

              {/* end::Step 6*/}
            </div>
            {/* end:: validationSchema={currentSchema}  Nav*/}
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <Formik initialValues={initValues} onSubmit={submitStep}>
            {(values: any) => {
              // console.log(values.values)
              setRole(values.values.role)
              return (
                <Form
                  className='py-20 w-100 w-xl-700px px-9'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    {/* <p>{values}</p> */}
                    <Step3 />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step4 values={values} />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Step6 />
                  </div>
                  <div className='d-flex flex-stack pt-10'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! &&
                            stepper.current?.currentStepIndex !== 2 &&
                            'Next'}
                          {(stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber ||
                            stepper.current?.currentStepIndex === 2) &&
                            'Submit'}

                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                      {/* )} */}
                      {/**/}
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </>
    </div>
  )
}

export {EmailVerificationPaument}
