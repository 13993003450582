import {FC} from 'react'
import {Link, Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import Overview from './Overview'
import ProfilePage from './Profile'
import CreateCustomer from './Profile/Overview/CreateCustomer'
const companyName = localStorage.getItem('companyName') as string
const CustomerPage: FC = () => {
  const customerBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        path={`overview`}
        element={
          <>
            <div className='w-100 d-flex flex-row justify-content-end align-items-center mb-3'>
              <PageTitle breadcrumbs={customerBreadCrumbs}>Customers</PageTitle>
            </div>
            {/* <PageTitle breadcrumbs={customerBreadCrumbs}>Customers</PageTitle> */}

            <Overview />
          </>
        }
      />
      <Route
        path={`createCustomer`}
        element={
          <>
            {/* <PageTitle breadcrumbs={customerBreadCrumbs}>Customers</PageTitle> */}
            <CreateCustomer />
          </>
        }
      />
      <Route
        path={`profile`}
        element={
          <>
            <PageTitle breadcrumbs={customerBreadCrumbs}>Customer Profile</PageTitle>
            <ProfilePage />
          </>
        }
      />
      <Route index element={<Navigate to={`/${companyName}/customer/overview`} />} />
    </Routes>
  )
}

export default CustomerPage
