import {Form, Formik, FormikValues} from 'formik'
import {FC, useEffect, useRef, useState} from 'react'
import Swal from 'sweetalert2'
import {StepperComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {
  updateProfilebyPayment,
  updateProfileLocation,
} from '../../../../../modules/auth/core/_requests'
import {Step1} from './Steps/Step1'
import {Step2} from './Steps/Step2'
import {Step3} from './Steps/Step3'
import {createAccountSchemas, ICreateAccount, inits} from '../CreateAccountWizardHelperLegal'
type Props = {
  closeCreateRentalModal: (arg: any) => void
  location: any
  customer: any
  id: any
}
const HorizontalEdit: React.FC<Props> = ({closeCreateRentalModal, location, customer, id}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pdfFile, setPDfFile] = useState('')

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const [locationAvailable, setLocation] = useState([])
  useEffect(() => {
    initValues.paymentType = location.paymentType
    initValues.name = location.name
    initValues.accountNumber = location.accountNumber
    initValues.routing = location.routing
    initValues.address = location.address
    initValues.billingAddress = location.billingAddress
    initValues.instruction = location.instruction

    initValues.publisherKey = location.publisherKey
    initValues.secretKey = location.secretKey
    setLocation(customer?.paymentMethods)
  })
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    console.log(stepper.current.currentStepIndex)
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])
    if (stepper.current.currentStepIndex === 3) {
      let dataTemp
      const user = localStorage.getItem('user')
      console.log(user)
      if (user != null) {
        dataTemp = JSON.parse(user)
        //  setUser(dataTemp)
      }
      const location = [
        {
          paymentType: values.paymentType,
          name: values.name,
          accountNumber: values.accountNumber,
          routing: values.routing,
          instruction: values.instruction,
          address: values.address,
          billingAddress: values.billingAddress,
          publisherKey: values.publisherKey,
          secretKey: values.secretKey,
        },
      ]
      const locations = customer?.paymentMethods
      console.log(id)

      const index = id
      locations.splice(index, 1)
      locations.splice(id, id, location[0])

      console.log(locations)
      setLocation(locations)
      let dataTo = {
        paymentMethods: locations,
      }
      try {
        const {
          data: {data, message},
        } = await updateProfilebyPayment(dataTemp._id, dataTo)
        // console.log(data)
        Swal.fire({
          timer: 3000,
          text: message,
          icon: 'success',
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        })
        setLoading(false)
        //  browserHistory.push('/auth/welcomeScreen')
        window.location.reload()
      } catch (error) {
        // stepper.current.goNext()
        // console.error(error)

        // setStatus('The Regsiter details are incorrect')
        // setSubmitting(false)
        setLoading(false)
      }
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Payment Type</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Account Setting</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Completed</h3>
            </div>
          </div>

          {/* <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}> */}
          <Formik initialValues={initValues} onSubmit={submitStep}>
            {(values) => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 values={values} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 values={values} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3 />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Back
                    </button>
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {!isSubmitButton && 'Continue'}
                        {isSubmitButton && 'Submit'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {HorizontalEdit}
