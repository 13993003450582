import axios from 'axios'
import {ICreateAccount} from '../components/AddVehicle/CreateAccountWizardHelper'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000/api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const CUSTOMERSIGN_URL = `${API_URL}/auth/customersignup`
export const CREATEREFERAL_URL = `${API_URL}/referal/create`
export const CREATEPrice_URL = `${API_URL}/prices/create`
export const aLLReferalL_URL = `${API_URL}/referal/all`
export const VERIFYPAYMENT = `${API_URL}/auth/verfiyPayment`
export const VEHICLE_URL = `${API_URL}/vehicle/create`
export const LEGAL_URL = `${API_URL}/legal/create`
export const FilterVehicle_URL = `${API_URL}/vehicle/filterVehicle`
export const TRIP_URL = `${API_URL}/trip/create`
export const BOOKING_URL = `${API_URL}/booking/create`
export const TRIP_URL_EMBEDED = `${API_URL}/trip/createEmbeded`
export const PROFILE_URL = `${API_URL}/user`
export const PROFILELOCATION_URL = `${API_URL}/user/location`
export const PROFILEPAYMENT_URL = `${API_URL}/user/payment`
export const VEHICLEPRO_URL = `${API_URL}/vehicle`
export const CUSTOMERVERIFY = `${API_URL}/auth/customerVerify`
export const CUSTOMERVERIFYID = `${API_URL}/auth/customerVerifyID`
export const VEHICLEIMAGE_URL = `${API_URL}/vehicle/image`
export const UPLOADDOCUMENT = `${API_URL}/user/document`
export const VEHICLEBROKER_URL = `${API_URL}/vehicle/broker`
export const TRIPPRO_URL = `${API_URL}/trip`
export const BOOKINGPRO_URL = `${API_URL}/booking`
export const DEPOSIT_URL = `${API_URL}/trip/deposit`
export const GETTRIPBYID = `${API_URL}/trip`
export const STATUS_URL = `${API_URL}/user/status`
export const LOGIN_URL = `${API_URL}/auth/login`
export const GETALLBROKER = `${API_URL}/broker/get-all`
export const GETCompanyStat = `${API_URL}/user/getStat`
export const GETALLvehicle = `${API_URL}/vehicle/get-all`
export const GETALLEMBED = `${API_URL}/vehicle/get-embed`
export const GETALLLegal = `${API_URL}/legal/all`
export const StripeVerification = `${API_URL}/user/stripeVerification`
export const STRIPEPYAMENT = `${API_URL}/trip/create-payment-intent`
export const GETAllBrokerUsers = `${API_URL}/user/getAllBrokerUsers`
export const GETAllCompanyUsers = `${API_URL}/user/getAllCompanyUsers`
export const GETALLCUSTOMER = `${API_URL}/customer/get-all`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const RESENT_URL = `${API_URL}/auth/resent`
export const Verify_URL = `${API_URL}/auth/verify`
export const GetAllCustomerStat = `${API_URL}/customer/get-stat`
export const GetAllTrips = `${API_URL}/trip/get-all`
export const GetAllCustomerById = `${API_URL}/customer`
export const GetAllBOOKINGById = `${API_URL}/booking/getAll`
export const GetAllCompanyrById = `${API_URL}/customer/company`
export const GetAllBrokerById = `${API_URL}/broker`
export const GetAllvehicleById = `${API_URL}/vehicle`
export const GetAllBrokerStat = `${API_URL}/broker/get-stat`
export const GetAllvehicleStat = `${API_URL}/vehicle/get-stat`
export const GetAllTripStat = `${API_URL}/trip/get-stat`
export const GETISBOOKED = `${API_URL}/trip/get-booked`
export const GetAlltripStatDashBoard = `${API_URL}/trip/getAlltripStatDashBoard`
export const FetYearStat = `${API_URL}/trip/getYearStat`
export const DeleteUserById = `${API_URL}/user`
export const DeleteVehicleById = `${API_URL}/vehicle`
export const AllUsers = `${API_URL}/user/allUsers`
export const GetAllBrokerUsers = `${API_URL}/user/getAllBrokerUsers`
export const REST_PASSWORD_URL = `${API_URL}/auth/reset-password`

// export const REST_PASSWORD_URL = `${API_URL}/auth/reset-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}
export function resend(email: string) {
  return axios.post(RESENT_URL, {
    email,
  })
}

export function register(values: ICreateAccount) {
  return axios.post(REGISTER_URL, {
    ...values,
  })
}
export function customersignup(values: ICreateAccount) {
  return axios.post(CUSTOMERSIGN_URL, {
    ...values,
  })
}

export function createReferal(values: any) {
  return axios.post(CREATEREFERAL_URL, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function createPrices(values: any) {
  return axios.post(CREATEPrice_URL, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function verfiyPayment(values: ICreateAccount, token: string | null | undefined) {
  return axios.post(VERIFYPAYMENT, {
    ...values,
    token,
  })
}

// Server should return AuthModel

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}
export function checkBooking(values: any) {
  return axios.post(GETISBOOKED, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function resetPassword(newPassword: string, token: string | null | undefined) {
  return axios.post(REST_PASSWORD_URL, {
    newPassword,
    token,
  })
}
export function verify(token: string | null | undefined) {
  return axios.post(Verify_URL, {
    token,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function getAllCustomers() {
  return axios.get(GETALLCUSTOMER, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function allUsers() {
  return axios.get(AllUsers)
}
export function getAllTripsStat() {
  return axios.get(GetAllTrips, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllCustomerStat() {
  return axios.get(GetAllCustomerStat, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllCustomerById(id: any) {
  return axios.get(GetAllCustomerById + `/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllBookingById() {
  return axios.get(GetAllBOOKINGById, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllCompanyById(id: any) {
  return axios.get(GetAllCompanyrById + `/${id}`, {})
}
export function deleteById(id: any) {
  return axios.delete(DeleteUserById + `/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function deleteByVehicleId(id: any) {
  return axios.delete(DeleteVehicleById + `/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function getAllBrokers() {
  return axios.get(GETALLBROKER, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getStat() {
  return axios.get(GETCompanyStat, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllBrokerUsers() {
  return axios.get(GETAllBrokerUsers, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllCompanyUsers() {
  return axios.get(GETAllCompanyUsers, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function getAllVehicle() {
  return axios.get(GETALLvehicle, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllTeam() {
  return axios.get(aLLReferalL_URL, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getallEmbed(values: any) {
  return axios.post(GETALLEMBED, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getALLLegal() {
  return axios.get(GETALLLegal, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function stripeVerification(values: any) {
  return axios.post(StripeVerification, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function stripePayment(values: any) {
  return axios.post(STRIPEPYAMENT, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllBrokerStat() {
  return axios.get(GetAllBrokerStat, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function getAllVehicleStat() {
  return axios.get(GetAllvehicleStat, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function getAllTripStat(values: any) {
  // console.log(values)
  return axios.post(GetAllTripStat, values, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAlltripStatDashBoard() {
  // console.log(values)
  return axios.get(GetAlltripStatDashBoard, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function getYearStat() {
  // console.log(values)
  return axios.get(FetYearStat, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function getAllBrokerById(id: any) {
  return axios.get(GetAllBrokerById + `/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function getAllVehicleId(id: any) {
  return axios.get(GetAllvehicleById + `/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function updateProfile(id: any, values: any) {
  return axios.put(PROFILE_URL + `/${id}`, {
    values,
  })
}
export function updateProfileLocation(id: any, values: any) {
  return axios.put(PROFILELOCATION_URL + `/${id}`, {
    values,
  })
}
export function updateProfilebyPayment(id: any, values: any) {
  return axios.put(PROFILEPAYMENT_URL + `/${id}`, {
    values,
  })
}
export function updateVechile(id: any, values: any) {
  return axios.put(VEHICLEPRO_URL + `/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function customerVerify() {
  return axios.post(
    CUSTOMERVERIFY,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  )
}
export function customerVerifyID() {
  return axios.post(
    CUSTOMERVERIFYID,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }
  )
}
export function updateVechileImage(id: any, values: any) {
  return axios.put(VEHICLEIMAGE_URL + `/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function uploadDocuemnt(id: any, values: any) {
  return axios.put(UPLOADDOCUMENT + `/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function updateVechileByBroker(id: any, values: any) {
  return axios.put(VEHICLEBROKER_URL + `/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function AddVehicle(values: any) {
  return axios.post(VEHICLE_URL, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function addLegal(values: any) {
  return axios.post(LEGAL_URL, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function filterVehicle(obj: any) {
  return axios.post(FilterVehicle_URL, obj, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function AddTrip(values: any) {
  return axios.post(TRIP_URL, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function AddBooking(values: any) {
  return axios.post(BOOKING_URL, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function AddTripEmbeded(values: any) {
  return axios.post(TRIP_URL_EMBEDED, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}

export function updateTrip(id: any, values: any) {
  return axios.put(TRIPPRO_URL + `/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function updateBooking(id: any, values: any) {
  return axios.put(BOOKINGPRO_URL + `/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function deposit(id: any, values: any) {
  return axios.put(DEPOSIT_URL + `/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
export function TripByID(id: any) {
  return axios.get(GETTRIPBYID + `/${id}`, {})
}

export function updateStatus(id: any, values: any) {
  return axios.put(STATUS_URL + `/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  })
}
