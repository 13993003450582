import React, {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {VehiclePage} from './VehiclePage'

const VehiclePageWrapper: FC = () => {
  const vehicleBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={vehicleBreadCrumbs}>Vehicles</PageTitle>
      <VehiclePage />
    </>
  )
}

export default VehiclePageWrapper
