import {Button} from 'react-bootstrap'
import {Link, Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {AddNewVehicle} from './AddVehicle'
import {EditVehicle} from './AddVehicle/editVehicle'
import Manage from './Manage'
import {CreateRental} from './CreateRental'
import {AddRentalNew} from './CreateRental/newDesign'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
const companyName = localStorage.getItem('companyName') as string
const addNewVehicleBreadCrumbs: Array<PageLink> = [
  // {
  //   title: 'Home',
  //   path: '/dashboard',
  //   isSeparator: false,
  //   isActive: false,
  // },
  {
    title: 'Vehicle',
    path: '/vehicle/manage',
    isSeparator: false,
    isActive: true,
  },
]

const VehiclePage = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path={`manage`}
        element={
          <>
            <div className='w-100 d-flex flex-row justify-content-end align-items-center mb-3'>
              <PageTitle breadcrumbs={addNewVehicleBreadCrumbs}>Vehicles</PageTitle>

              <Link
                to={`/${companyName}/vehicle/add-new-vehicle`}
                className='btn btn-success btn-sm'
              >
                Add New Vehicle
              </Link>
            </div>
            <Manage />
          </>
        }
      />
      <Route
        path={`add-new-vehicle`}
        element={
          <>
            <PageTitle breadcrumbs={addNewVehicleBreadCrumbs}>Add New Vehicle</PageTitle>
            <AddNewVehicle />
          </>
        }
      />
      <Route
        path={`add-Rental`}
        element={
          <>
            <PageTitle breadcrumbs={addNewVehicleBreadCrumbs}>Add New Rental</PageTitle>
            <CreateRental />
          </>
        }
      />
      <Route
        path={`add-RentalNew`}
        element={
          <>
            <PageTitle breadcrumbs={addNewVehicleBreadCrumbs}>Add New Rental</PageTitle>
            <AddRentalNew />
          </>
        }
      />
      <Route
        path={`edit-Vehicle`}
        element={
          <>
            <PageTitle breadcrumbs={addNewVehicleBreadCrumbs}>Edit Vehicle</PageTitle>
            <EditVehicle />
          </>
        }
      />
    </Route>

    <Route index element={<Navigate to={`/${companyName}/vehicle/manage`} />} />
  </Routes>
)

export {VehiclePage}
