import {useEffect, useState} from 'react'

import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import {createBrowserHistory} from 'history'
import Swal from 'sweetalert2'
import {
  ICreateAccount,
  createAccountSchemas,
  inits,
} from '../../../../modules/auth/components/AddVehicle/CreateAccountWizardHelper'
import {customersignup, getAllCustomerById} from '../../../../modules/auth/core/_requests'
import './index.css'
export default function CreateCustomer() {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [role, setRole] = useState('')
  // const {saveAuth, setCurrentUser} = useAuth()
  const browserHistory = createBrowserHistory()
  const [customer, setCustomer] = useState<any>(null)

  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('user')
    // console.log(user)
    if (user != null) {
      let dataTemp = JSON.parse(user)
      console.log('dataTemp', dataTemp)
      //  setUser(dataTemp)
      if (dataTemp.role === 'Company') {
        const data = await getAllCustomerById(dataTemp._id)

        console.log('data', data)
        setCustomer(data.data.customer)
      } else {
        const data = await getAllCustomerById(dataTemp._id)
        const employee = await getAllCustomerById(data.data.customer?.referal?.onwerId)
        setCustomer(employee.data.customer)
      }
    }
    // setData(data.data?.customers)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  console.log(customer)
  const generate = () => {
    const randomPassword = Math.random().toString(30).slice(2) + Math.random().toString(30).slice(2)
    console.log(randomPassword)
    initValues.password = randomPassword
    setPassword(randomPassword)
  }
  const onSubmit = async (values: ICreateAccount, actions: FormikValues) => {
    console.log('sks')
    setLoading(true)
    values.id = customer._id
    // let dat.a
    values.companyName = customer.companyName
    values.password = password
    values.companyCode = customer.companuCode
    console.log(values.companyName)
    try {
      const {
        data: {data, message},
      } = await customersignup(values)
      // console.log(data)
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })

      setLoading(false)
      browserHistory.push('/')
      window.location.reload()
      // localStorage.setItem('role', data.data.user.role)
    } catch (e: any) {
      // console.log(e.response.data.message)
      Swal.fire({
        timer: 3000,
        text: e.response.data.message,
        icon: 'error',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // saveAuth(undefined)

      setLoading(false)
    }
  }

  return (
    <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
      <Formik initialValues={initValues} validationSchema={currentSchema} onSubmit={onSubmit}>
        {(values: any) => {
          console.log(values)
          setRole(values.values.role)
          values.values.password = initValues.password
          return (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>Create Customer</h2>
              </div>

              <div className='fv-row'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span className=''>Name</span>
                        </label>
                      </div>

                      <div className='col-lg-8'>
                        <Field
                          type='text'
                          // value=''
                          className='form-control form-control-lg form-control-solid'
                          name='accountName'
                        />
                        <div className='text-danger mt-2'>
                          {/* <ErrorMessage name='accountName' /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='row'>
                      <div className='col-lg-4'>
                        {' '}
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span className=''>Email</span>
                        </label>
                      </div>
                      <div className='col-lg-8'>
                        <Field
                          type='email'
                          // value=''
                          className='form-control form-control-lg form-control-solid'
                          name='email'
                        />
                        <div className='text-danger mt-2'>
                          {/* <ErrorMessage name='email' /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className=''>Mobile</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        type='text'
                        // value=''
                        className='form-control form-control-lg form-control-solid'
                        name='phone'
                      />
                      <div className='text-danger mt-2'>{/* <ErrorMessage name='phone' /> */}</div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className=''>Date Of Birth</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        type='date'
                        // value=''
                        className='form-control form-control-lg form-control-solid'
                        name='dateofBirth'
                      />
                      <div className='text-danger mt-2'>
                        {/* <ErrorMessage name='dateofBirth' /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className=''>Temporary Password </span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <div className='wrapper'>
                        <div className='icon' onClick={generate}>
                          {' '}
                          Generate
                        </div>
                        <Field
                          type='text'
                          value={password}
                          className='form-control form-control-lg form-control-solid input'
                          name='password'
                        />

                        {/* <div className='text-danger mt-2'>
                      <ErrorMessage name='password' />
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className=''>Status</span>
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field
                        as='select'
                        name='status'
                        className='form-select form-select-lg form-select-solid'
                      >
                        <option value=''>Choose Status</option>
                        <option value='not Active'>Pending</option>
                        <option value='Active'>Verified</option>
                        <option value='Blacklisted'>Blacklisted</option>
                      </Field>
                      <div className='text-danger mt-2'>{/* <ErrorMessage name='status' /> */}</div>
                    </div>
                  </div>
                </div>
                <div className='d-flex my-5 gap-4'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-dark'
                    // disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Create Customer</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
