/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Goals} from '../../../../utils/consts'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
}

const ListsWidget2: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>What's on the road?</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Total 482 participants</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-light text-gray-600 '
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            View All
          </button>
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        <div className='d-flex flex-row justify-content-between align-items-center pb-7'>
          {Array(10)
            .fill('*')
            .map((item, index) => (
              <span
                className={`d-flex flex-column justify-content-center py-4 px-3 w-48px ${
                  index === 1 && 'bg-primary'
                }`}
                style={{borderRadius: '50px'}}
              >
                <p className={`${index === 1 ? 'text-white' : 'text-gray-400'}  fs-6 lh-1 `}>Su</p>
                <p
                  className={`fs-5 fw-semibold ${
                    index === 1 ? 'text-white' : 'text-black'
                  } lh-1 m-0`}
                >
                  22
                </p>
              </span>
            ))}
        </div>

        {Goals.map((goal) => (
          <div className='d-flex align-items-center mb-8 gap-5'>
            <span className='bullet bullet-vertical h-70px bg-secondary'></span>
            <div className='flex-grow-1'>
              <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-3'>
                {goal.time_duration}{' '}
                <span className='text-gray-400 fs-6'>{goal.time_standard}</span>
              </a>
              <p className='fs-4 text-black fw-semibold m-0 '>{goal.description}</p>
              <span className='text-muted fw-semibold d-block'>
                Lead by <span className='text-primary'>{goal.lead_by}</span>
              </span>
            </div>
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget2}
