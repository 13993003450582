/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CardElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {StripeCardElement, StripePaymentElementOptions} from '@stripe/stripe-js'
import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {TripByID, deposit, updateTrip} from '../../../modules/auth/core/_requests'
import Swal from 'sweetalert2'
import {createBrowserHistory} from 'history'
import moment from 'moment'
import {PaymentIntent} from '@stripe/stripe-js'
function addCommas(nStr: string) {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}
const PayNow: React.FC = () => {
  const stripe = useStripe()
  const browserHistory = createBrowserHistory()
  const elements = useElements()
  let id = localStorage.getItem('id')
  const handlePayment = async () => {
    const values = {millage: 0, status: 'Booked'}
    const data = await updateTrip(id, values)
    Swal.fire({
      timer: 3000,
      text: 'Payment Done Successfully',
      icon: 'success',
      showCloseButton: false,
      showConfirmButton: false,
      showDenyButton: false,
    })
    const companyName = localStorage.getItem('companyName') as string
    browserHistory.push(`/${companyName}/dashboard/overview`)
    window.location.reload()
  }
  const handlePaymentDeposit = async () => {
    // event.preventDefault()

    // // const {stripe, elements} = props
    // if (!stripe || !elements) {
    //   return
    // }

    const values = {deposit: total}
    const data = await deposit(id, values)
    Swal.fire({
      timer: 3000,
      text: 'Payment Done Successfully',
      icon: 'success',
      showCloseButton: false,
      showConfirmButton: false,
      showDenyButton: false,
    })
    const companyName = localStorage.getItem('companyName') as string
    browserHistory.push(`/${companyName}/dashboard/overview`)
    window.location.reload()
  }
  const [trip, setTrip] = useState<any>([])
  const [total, setTotal] = useState(0)
  const [remintotal, setRemainTotal] = useState(0)
  const [remaining, setRemiaing] = useState(0)
  const tripById = async () => {
    const data = await TripByID(id)
    setTrip(data.data.trips)
    setRemiaing(data.data.trips[0].total - data.data.trips[0].deposit)
    setTotal(
      trip[0]?.vechicleId?.priceClassification +
        (trip[0]?.vechicleId?.priceClassification * trip[0]?.onwerId.prices.serviceFee) / 100 +
        (trip[0]?.vechicleId?.priceClassification * trip[0]?.onwerId.prices.salesTax) / 100
    )
    let val = data.data.trips[0].total - data.data.trips[0].deposit
    setRemainTotal(
      val +
        parseInt(data.data.trips[0].bookingfee) +
        (val * trip[0]?.onwerId.prices.serviceFee) / 100 +
        (val * trip[0]?.onwerId.prices.salesTax) / 100
    )
  }
  useEffect(() => {
    tripById()
  }, [total, remaining])

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: {preventDefault: () => void}) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_API_URL_PAY}`,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message as string)
    } else {
      setMessage('An unexpected error occurred.')
    }

    setIsLoading(false)
  }

  const paymentElementOptions = {
    layout: 'tabs',
  }

  return (
    <>
      <div>
        <div className='product-info'>
          <h3 className='product-title fs-6 text-muted'>Rental Deposit</h3>
          {trip[0]?.status === 'Pending' ? (
            <h1 className='product-title '>
              $ {addCommas(trip[0]?.vechicleId?.priceClassification)}.00
            </h1>
          ) : (
            <h1 className='product-title '>$ {addCommas(remaining.toString())}.00</h1>
          )}
        </div>
        <div className='row '>
          <div className='col-sm-8'>
            <div className='row'>
              <div className='col-sm-8 d-flex'>
                <img
                  src={trip[0]?.vechicleId?.gallery[0]}
                  className='symbol symbol-fixed  '
                  style={{height: '100px'}}
                  alt='no'
                />
                {trip[0]?.status === 'Pending' ? (
                  <div className='inline align-self-center'>
                    <h4>Deposit </h4>
                    <h5 className='text-muted'>One Time Payment </h5>
                  </div>
                ) : (
                  <div className='inline align-self-center'>
                    <h4>
                      {trip[0]?.vechicleId.year}&nbsp;{trip[0]?.vechicleId.make}&nbsp;
                      {trip[0]?.vechicleId.model}{' '}
                    </h4>
                    <h5 className='text-muted'>
                      {moment(trip[0]?.selectedStartDate).format('MMM Do YYYY')}--
                      {moment(trip[0]?.selectedEndDate).format('MMM Do YYYY')}
                    </h5>
                  </div>
                )}
              </div>
              <div className='col-sm-4 align-self-center'>
                {trip[0]?.status === 'Pending' ? (
                  <h4>$ {addCommas(trip[0]?.vechicleId?.priceClassification)}.00</h4>
                ) : (
                  <h4>$ {addCommas(trip[0]?.total)}.00</h4>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'></div>
              <div className='col-sm-6 '>
                {' '}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  {trip[0]?.status === 'Pending' ? (
                    <thead>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 '>Sub Total</td>

                        <td className='fw-bold fs-6 text-end '>
                          {' '}
                          $ {addCommas(trip[0]?.vechicleId?.priceClassification)}.00
                        </td>
                      </tr>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 text-muted'>
                          Sales Tax ({trip[0]?.onwerId.prices.salesTax}%)
                        </td>
                        <td className='fw-bold fs-6 text-muted  text-end'>
                          $
                          {(
                            (trip[0]?.vechicleId?.priceClassification *
                              trip[0]?.onwerId.prices.salesTax) /
                            100
                          ).toFixed(3)}
                        </td>
                      </tr>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 text-muted'>
                          Service Fee ({trip[0]?.onwerId.prices.serviceFee}%)
                        </td>
                        <td className='fw-bold fs-6 text-muted  text-end'>
                          $
                          {(
                            (trip[0]?.vechicleId?.priceClassification *
                              trip[0]?.onwerId.prices.serviceFee) /
                            100
                          ).toFixed(3)}
                        </td>
                      </tr>

                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 '>Total dues</td>
                        <td className='fw-bold fs-6  text-end'>${total}</td>
                      </tr>
                    </thead>
                  ) : (
                    <thead>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 '>Sub Total</td>

                        <td className='fw-bold fs-6 text-end '>
                          {' '}
                          $ {addCommas(remaining.toString())}.00
                        </td>
                      </tr>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 text-muted'>
                          Sales Tax ({trip[0]?.onwerId.prices.salesTax}%)
                        </td>
                        <td className='fw-bold fs-6 text-muted  text-end'>
                          ${((remaining * trip[0]?.onwerId.prices.salesTax) / 100).toFixed(3)}
                        </td>
                      </tr>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 text-muted'>
                          Service Fee ({trip[0]?.onwerId.prices.serviceFee}%)
                        </td>
                        <td className='fw-bold fs-6 text-muted  text-end'>
                          ${((remaining * trip[0]?.onwerId.prices.serviceFee) / 100).toFixed(3)}
                        </td>
                      </tr>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 text-muted'>Booking Fee </td>
                        <td className='fw-bold fs-6 text-muted text-end'>
                          ${addCommas(trip[0]?.bookingfee)}
                        </td>
                      </tr>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 text-muted'>Deposit </td>
                        <td className='fw-bold fs-6 text-muted text-end'>
                          ${addCommas(trip[0]?.deposit)}
                        </td>
                      </tr>
                      <tr style={{borderBottom: '1px solid black'}}>
                        <td className='fw-bold fs-6 '>Total dues</td>
                        <td className='fw-bold fs-6  text-end'>
                          ${addCommas(remintotal.toFixed(3))}
                        </td>
                      </tr>
                    </thead>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            {' '}
            <h3 className='fs-4'>Card Details</h3>
            <form id='payment-form'>
              {/* <LinkAuthenticationElement
                id='link-authentication-element'
                // onChange={(e) => setEmail(e.target.value as string)}
              /> */}
              <PaymentElement
                id='payment-element'
                options={paymentElementOptions as StripePaymentElementOptions}
              />
              {trip[0]?.status === 'Pending' ? (
                <div className='mt-3 fv-row'>
                  <Button
                    disabled={!stripe}
                    onClick={handleSubmit}
                    className='btn btn-sm btn-dark me-3'
                  >
                    Pay
                  </Button>
                </div>
              ) : (
                <div className='mt-3 fv-row'>
                  <Button
                    disabled={!stripe}
                    onClick={handleSubmit}
                    className='btn btn-sm btn-dark me-3'
                  >
                    Pay
                  </Button>
                </div>
              )}
              {/* Show any error or success messages */}
              {message && <div id='payment-message'>{message}</div>}
            </form>
            {/* <CardElement className='card form-control form-control-lg form-control-solid' /> */}
          </div>
        </div>
      </div>
    </>
  )
}

export {PayNow}
