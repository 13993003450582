import {FC} from 'react'
import {Col, Row} from 'react-bootstrap'
import {make, model, year} from '../../../../utils/consts'
import './index.css'
type Props = {
  className: string
  setYear: (arg: string) => void
  setMake: (arg: string) => void
  setDropOff: (arg: string) => void
  setModel: (arg: string) => void
  setEngine: (arg: string) => void
  setPickUP: (arg: string) => void
  handleFilter: () => void
}

const CarAvailabilityCard: FC<Props> = ({
  className,
  setYear,
  setDropOff,
  setMake,
  setModel,
  handleFilter,
  setPickUP,
  setEngine,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-4 mb-1'>Car Availability</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Search the availability of any vehicle in your fleet by pick up/drop off time.
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body d-flex py-3'>
        <Row className='w-100'>
          <Col xs={12} sm={12} md={2}>
            {' '}
            <label></label>
            <select
              className='form-select form-select-lg form-select-solid'
              onChange={(event) => setYear(event.target.value)}
            >
              <option value=''>Choose Year</option>

              {year.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </Col>
          <Col xs={12} sm={12} md={2}>
            <label></label>
            <select
              className='form-select form-select-lg form-select-solid'
              onChange={(event) => setMake(event.target.value)}
            >
              <option value=''>Choose Make</option>

              {make.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            {/* end::Menu */}
          </Col>
          <Col xs={12} sm={12} md={2}>
            <label></label>
            <select
              className='form-select form-select-lg form-select-solid'
              onChange={(event) => setModel(event.target.value)}
            >
              <option value=''>Choose Model</option>

              {model.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            {/* end::Menu */}
          </Col>
          {/* <Col> */}
          {/* <Col>
              <label></label>
              <select
                className='form-select form-select-lg form-select-solid'
                onChange={(event) => setEngine(event.target.value)}
              >
                <option value=''>Choose Engine</option>

                {egineType.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
      
            </Col> */}
          {/* </Col> */}
          <Col xs={12} sm={12} md={2}>
            <label></label>
            <input
              type='date'
              placeholder='Pick Up Time'
              // value='11,118 mi'
              onChange={(event) => setPickUP(event.target.value)}
              className='form-control form-control-lg form-control-solid'
              // name='milleage'
            />
          </Col>
          <Col xs={12} sm={12} md={2}>
            <label></label>
            <input
              type='date'
              // placeholder='Pick Up Time'
              // value='11,118 mi'
              onChange={(event) => setDropOff(event.target.value)}
              className='form-control form-control-lg form-control-solid'
              // name='milleage'
            />
          </Col>
          <Col sm={2} className='align-self-center'>
            <button
              type='button'
              onClick={handleFilter}
              className='btn btn-lg btn-primary me-3'
              style={{float: 'right'}}
            >
              <span className='indicator-label'>Filter</span>
            </button>
          </Col>
          <Row className='mt-3'> </Row>
        </Row>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default CarAvailabilityCard
