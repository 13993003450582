/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Card, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {updateStatus} from '../../../modules/auth/core/_requests'
import Spinner from '../../brokers/components/BrokerTable/Spinner'

type Props = {
  className: string
  data: any
  loading: boolean
}

const CustomerTable: React.FC<Props> = ({className, data, loading}) => {
  // console.log(data)
  //   const handleClick = (id: any) => {
  //     localStorage.setItem('id', id)
  //   }

  //   const handleDelete = (id: any) => {
  //     // console.log(val);
  //     Swal.fire({
  //       title: 'Do you want to Delete this Customer?',
  //       showDenyButton: false,
  //       showCancelButton: true,
  //       confirmButtonText: 'Delete',
  //     }).then(async (result) => {
  //       /* Read more about isConfirmed, isDenied below */
  //       if (result.isConfirmed) {
  //         const withJWT = true
  //         try {
  //           const user = localStorage.getItem('user')
  //           if (user != null) {
  //             let dataTemp = JSON.parse(user)
  //             //  setUser(dataTemp)

  //             const data = await deleteById(id)
  //             // console.log(data.data.data.token)
  //             Swal.fire({
  //               timer: 3000,
  //               text: data.data.message,
  //               icon: 'success',
  //               showCloseButton: false,
  //               showConfirmButton: false,
  //               showDenyButton: false,
  //             })

  //             // browserHistory.push('/customer/overview')
  //             window.location.reload()
  //           }
  //           // localStorage.setItem('role', data.data.user.role)
  //         } catch (error) {
  //           console.error(error)
  //           // saveAuth(undefined)
  //         }
  //       } else if (result.isDenied) {
  //         Swal.fire('Changes are not saved', '', 'info')
  //       }
  //     })
  //   }
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)
  const [ids, setId] = useState('')
  const [status, setStatus] = useState('')

  const openCreateRentalModal = (id: any) => {
    setShowCreateRentalModal(true)
    setId(id)
  }
  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
    setId('')
  }
  const UpdateRental = async () => {
    // setLoading(true)
    let values = {
      status: status,
    }
    try {
      const {
        data: {data, message},
      } = await updateStatus(ids, values)

      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // actions.resetForm()
      closeCreateRentalModal()
      window.location.reload()
    } catch (error) {
      // console.error(error)
      Swal.fire({
        timer: 3000,
        text: 'Api Not Working',
        icon: 'error',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }
  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {/* begin::Table */}
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <div className='table-responsive'>
              {data.length === 0 ? (
                <Card style={{borderRadius: '8px'}}>
                  <Card.Body>
                    <Card.Text>
                      <h4>No Vehicle Available</h4>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className=''>NAME</th>
                      <th className='d-flex align-items-center'>
                        JOIN DATE&nbsp;&nbsp;
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr072.svg'
                          className='svg-icon-5'
                        />
                      </th>
                      <th className=''>COMPANY SITE</th>
                      <th className=''>STATUS</th>
                      <th className='text-end'>Action</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {data.map((car: any) => (
                      <tr key={car.accountName}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <img src={car?.profileImg} className='rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {car.accountName}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {car.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <h5 className='text-dark fw-bold'>{car.createdAt.split('T')[0]}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className='text-dark fw-bold d-block '>{car.companySite}</h5>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              car.status === 'Not Active'
                                ? 'badge-light-danger'
                                : car.status === 'Active'
                                ? 'badge-light-success'
                                : car.status === 'BlockListed'
                                ? 'badge-light-primary'
                                : ''
                            } fs-7 fw-semibold`}
                          >
                            {car.status}
                          </span>
                        </td>
                        <td className='text-end'>
                          {/* <a
                          href='#'
                          className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                        >
                          Edit
                        </a> */}
                          <Link
                            to='#'
                            onClick={() => openCreateRentalModal(car._id)}
                            className='btn btn-sm btn-dark me-3'
                          >
                            Action
                          </Link>
                          {/* end::Filter Button */}
                          {/* begin::SubMenu */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
          )}
          {/* end::Table */}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModal} size='sm' centered>
          <Modal.Body>
            <div className='mb-3 fv-row'>
              <label className='form-label mb-3'>Update Status</label>
              <select
                className='form-select form-select-lg form-select-solid'
                onChange={(event) => setStatus(event?.target.value)}
              >
                <option value=''>Choose Mode</option>
                <option value='Active'> Accept</option>
                <option value='Not Active'>Deny</option>
              </select>
            </div>
            <div className='mt-3 fv-row'>
              <Link to='#' onClick={UpdateRental} className='btn btn-sm btn-dark me-3'>
                Save
              </Link>

              <Link to='#' onClick={closeCreateRentalModal} className='btn btn-sm btn-dark me-3'>
                Close
              </Link>
            </div>
            {/* <CreateRental /> */}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export {CustomerTable}
