/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {UsersListFilter} from '../../../../../app/modules/apps/user-management/users-list/components/header/UsersListFilter'
import {UsersListSearchComponent} from '../../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {KTSVG} from '../../../../../_metronic/helpers'
import {
  getAllCustomers,
  getAllCustomerStat,
  GetAllCustomerStat,
} from '../../../../modules/auth/core/_requests'
import Pagination from 'react-responsive-pagination'
import {initialQueryState, useDebounce} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../modules/apps/user-management/users-list/core/QueryRequestProvider'

import {CustomerTable} from './CustomerTable'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  title?: string
  pagination?: boolean
}
const companyName = localStorage.getItem('companyName') as string
const CustomerTableWrapper: React.FC<Props> = ({className, pagination, title}) => {
  const [data, setData] = useState<any>([])
  const [customer, setCustomer] = useState<any>([])
  const [stat, setStatistics] = useState<any>(null)

  let [updated, setUpdated] = useState(false)
  let [loading, setLoading] = useState(true)
  let [activePage, setActivePage] = useState(0)
  const allCustomers = async () => {
    setLoading(true)
    try {
      const data = await getAllCustomers()
      // console.log(data)
      const startIndex = activePage
      const endIndex = startIndex + 10
      setData(data.data?.customers)
      setCustomer(data.data?.customers.slice(startIndex, endIndex))
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  const handlePageChange = (pageNumber: number) => {
    // console.log(`active page is ${pageNumber}`)
    const number = (pageNumber - 1) * 10
    setActivePage(pageNumber)
    const startIndex = number
    const endIndex = startIndex + 10

    setCustomer(data.slice(startIndex, endIndex))
  }
  const handleFilter = (val: number) => {
    if (val === 1) {
      let dataNew = data.sort(function (a: any, b: any) {
        if (a.accountName < b.accountName) {
          return -1
        }
        if (a.accountName > b.accountName) {
          return 1
        }
        return 0
      })
      setCustomer(dataNew)
      setUpdated(!updated)
    } else if (val === 2) {
      let dataNew = data.sort(function (a: any, b: any) {
        if (a.accountName > b.accountName) {
          return -1
        }
        if (a.accountName < b.accountName) {
          return 1
        }
        return 0
      })
      setCustomer(dataNew)
      setUpdated(!updated)
    }
  }
  useEffect(() => {}, [updated])
  const getAllCustomerStatistics = async () => {
    const data = await getAllCustomerStat()
    let statistice = {
      availableCustomer: data.data?.customerListing.availableCustomer,
      blockListedCustomer: data.data?.customerListing.availableCustomer,
      notActiveCustomer: data.data?.customerListing.notActiveCustomer,
      totalCustomer: data.data?.customerListing.totalCustomer,
      newCustomerCount: data.data?.customers.newCustomerCount,
      newCustomerData: data.data?.customers.newCustomerData,
    }
    // console.log(statistice)
    setStatistics(statistice)
    // setData(data.data?.customers)
  }
  useEffect(() => {
    allCustomers()
    getAllCustomerStatistics()
  }, [])
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const handleSearch = (val: string) => {
    // console.log(val.length)
    // if (val !== '') {
    setSearchTerm(val)
    if (val.length !== 0) {
      let dataNew = data.filter((item: any) => item.accountName.includes(val))
      setCustomer(dataNew)
      setUpdated(!updated)
    } else if (val.length === 0) {
      // console.log('aya')
      const number = activePage * 10
      // setActivePage(pageNumber)
      const startIndex = number
      const endIndex = startIndex + 10

      setCustomer(data.slice(startIndex, endIndex))
      // setCustomer(customer)
      setUpdated(!updated)
    }

    // }
  }
  const download = (data: any) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], {type: 'text/csv'})

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')

    // Passing the blob downloading url
    a.setAttribute('href', url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'Customer.csv')

    // Performing a download with click
    a.click()
  }

  const csvmaker = (data: any) => {
    // console.log(data)

    let csvRows: any[] = []

    // const headers = Object.keys(data[0]);

    // csvRows.push(headers.join(','));
    const csvString = [
      [
        'Id',
        'email',
        'role',
        'accountName',
        'phone',
        'jobTitle',
        'firstName',
        'lastName',
        'description',
        'status',
        'createdAt',
      ],
      ...data.map((item: any) => [
        item._id,
        item.email,
        item.role,
        item.accountName,
        item.phone,
        item.jobTitle,
        item.firstName,
        item.lastName,
        item.description,
        item.status,
        item.createdAt,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n')

    // console.log(csvString);
    csvRows.push(csvString)
    return csvRows.join('\n')
  }
  const downloadCsv = () => {
    const csvdata = csvmaker(data)
    download(csvdata)
  }
  return (
    <>
      <div className='d-flex flex-wrap flex-stack p-5 bg-white'>
        <div className='d-flex flex-row align-items-center justify-content-between w-100 w-md-auto gap-5'>
          {/* <h3 className='fw-bolder my-2'>Customers ( {stat !== null ? stat.totalCustomer : ''})</h3> */}
          <UsersListSearchComponent
            searchTerm={searchTerm}
            setSearchTerm={handleSearch}
            className='bg-white py-2'
          />
        </div>

        <div className='d-flex w-100 w-sm-auto align-items-center my-2'>
          {/* <button type='button' className='btn bg-white me-3 btn-sm'>
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 mx-0' />
          </button> */}
          <UsersListFilter handleFilter={handleFilter} className='bg-white btn-sm' />

          {/* begin::Export */}
          <button type='button' className='btn bg-white btn-sm' onClick={downloadCsv}>
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Export
          </button>

          <Link to={`/${companyName}/customer/createCustomer`} className='btn btn-success btn-sm'>
            Create Customer
          </Link>
          {/* end::Export */}
        </div>
      </div>

      <CustomerTable className='' data={customer} loading={loading} />
      <div className='d-flex flex-stack flex-wrap justify-content-end bg-white p-4'>
        <Pagination
          current={activePage}
          // itemsCountPerPage={10}
          total={Math.ceil(data.length / 10)}
          // pageRangeDisplayed={5}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  )
}

export {CustomerTableWrapper}
