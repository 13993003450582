/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {Button, Card, Modal} from 'react-bootstrap'
import Pagination from 'react-responsive-pagination'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getALLLegal} from '../../../modules/auth/core/_requests'
import {Horizontal} from '../../../modules/wizards/components/Horizontal'
type Props = {
  className: string
  value: string
}

const RentalAgreementTable: React.FC<Props> = ({className, value}) => {
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [legal, setLegal] = useState<any>([])
  const [activePage, setActivePage] = useState(0)
  const [isUpdated, setIsUpdated] = useState(false)
  const [data, setData] = useState<any>([])

  const showAddModal = () => {
    console.log('toggle')
    setShowAddDocumentModal((prevVal) => !prevVal)
  }
  const allCustomers = async () => {
    setLoading(true)
    try {
      const data = await getALLLegal()

      const startIndex = activePage
      const endIndex = startIndex + 10
      setData(data.data?.data)
      const available = data.data?.data.filter((item: any) => item.key === value)
      console.log(available, value)
      setLegal(available.slice(startIndex, endIndex))
      setIsUpdated(!isUpdated)

      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  const handlePageChange = (pageNumber: number) => {
    // console.log(`active page is ${pageNumber}`)
    const number = (pageNumber - 1) * 10
    setActivePage(pageNumber)
    const startIndex = number
    const endIndex = startIndex + 10

    setLegal(data.slice(startIndex, endIndex))
  }
  useEffect(() => {
    allCustomers()
  }, [value])
  useEffect(() => {}, [isUpdated])
  console.log(legal)
  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{value}</span>
          </h3>
          <div className='card-toolbar'>
            <Button size='sm' color='primary' onClick={showAddModal}>
              Add Document
            </Button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {legal.length === 0 ? (
            <Card style={{borderRadius: '8px'}}>
              <Card.Body>
                <Card.Text>
                  <h4>No Vehicle Document</h4>
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 rounded-start'>Date Added</th>
                    <th className=''>Title</th>
                    <th className=''>Description</th>
                    <th className=''>Document</th>
                    <th className='text-end rounded-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {legal.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className='ps-4'>
                        <a href='#' className='text-gray-400 fw-bold d-block mb-1 fs-6'>
                          {item.createdAt}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item.title}
                        </a>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bold d-block mb-1 fs-6'>
                          {item.description}
                        </a>
                      </td>
                      <td>
                        <a
                          href={item.document}
                          target='_blank'
                          className='text-gray-400 btn btn-bg-light btn-sm me-1'
                        >
                          PDF
                        </a>
                      </td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='text-gray-400 btn btn-bg-light btn-active-color-primary btn-sm mx-2'
                        >
                          Delete
                        </a>
                        <a
                          href='#'
                          className='text-gray-400 btn btn-bg-light btn-active-color-primary btn-sm'
                        >
                          Share
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='d-flex flex-stack flex-wrap'>
        <Pagination
          current={activePage}
          // itemsCountPerPage={10}
          total={Math.ceil(data.length / 10)}
          // pageRangeDisplayed={5}
          onPageChange={handlePageChange}
        />
      </div>
      {showAddDocumentModal && (
        <Modal show={showAddDocumentModal} onHide={showAddModal} size='xl'>
          <Modal.Header className='w-100'>
            <Modal.Title className='w-100'>
              <div className='d-flex flex-row align-items-center justify-content-between w-100'>
                <div className='d-flex align-items-center'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo-dark.svg')}
                    className='h-15px'
                  />{' '}
                  &nbsp; Add Document
                </div>
                <div className='d-flex gap-3'></div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Horizontal value={value} />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export {RentalAgreementTable}
