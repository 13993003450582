import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Overview} from '../../../modules/profile/components/Overview'
import {Projects} from '../../../modules/profile/components/Projects'
import {Campaigns} from '../../../modules/profile/components/Campaigns'
import {Documents} from '../../../modules/profile/components/Documents'
import {Connections} from '../../../modules/profile/components/Connections'
import {VehicleDetailHeader} from './VehicleDetailHeader'
import {Calender} from './calender'
const companyName = localStorage.getItem('companyName') as string
const detailBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Vehicle',
    path: '/vehicle/manage',
    isSeparator: false,
    isActive: false,
  },
]

const VehicleDetailPage = () => (
  <Routes>
    <Route
      element={
        <>
          <VehicleDetailHeader />
          <Outlet />
        </>
      }
    >
      {/* <Route
        path='overview'
        element={
          <>
            <PageTitle breadcrumbs={detailBreadCrumbs}>Vehicle Details</PageTitle>
            <Overview />
          </>
        }
      /> */}
      <Route
        path={`availability`}
        element={
          <>
            <PageTitle breadcrumbs={detailBreadCrumbs}>Vehicle Details</PageTitle>
            <Calender />
          </>
        }
      />
      <Route
        path={`gallery`}
        element={
          <>
            <PageTitle breadcrumbs={detailBreadCrumbs}>Vehicle Details</PageTitle>
            <Campaigns />
          </>
        }
      />
      <Route
        path={`history`}
        element={
          <>
            <PageTitle breadcrumbs={detailBreadCrumbs}>Vehicle Details</PageTitle>
            <Documents />
          </>
        }
      />
      <Route
        path={`overview`}
        element={
          <>
            <PageTitle breadcrumbs={detailBreadCrumbs}>Vehicle Details</PageTitle>
            <Connections />
          </>
        }
      />
      <Route index element={<Navigate to={`/${companyName}/vehicle/details/overview`} />} />
    </Route>
  </Routes>
)

export default VehicleDetailPage
