/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
type Props = {
  values: any
}
const Step1: FC<Props> = ({values}) => {
  return (
    <div className='fv-row'>
      <div className='row'>
        <div className='col-lg-12'>
          <Field
            type='radio'
            className='btn-check'
            name='paymentType'
            value='Stripe'
            id='kt_create_account_form_account_type_personal'
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center '
            htmlFor='kt_create_account_form_account_type_personal'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com005.svg'
              className='svg-icon-3x me-5'
            />

            <span className='d-block fw-bold text-start'>
              <span className='text-dark fw-bolder d-block fs-4 mb-2'>Stripe Connect</span>
              <span className='text-gray-400 fw-bold fs-6'>Let Connect Your Stripe Account</span>
            </span>
          </label>
        </div>

        <div className='col-lg-12'>
          <Field
            type='radio'
            className='btn-check'
            name='paymentType'
            value='WireTransfer'
            id='kt_create_account_form_account_type_corporate'
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
            htmlFor='kt_create_account_form_account_type_corporate'
          >
            <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-3x me-5' />

            <span className='d-block fw-bold text-start'>
              <span className='text-dark fw-bolder d-block fs-4 mb-2'>Wire Transfer</span>
              <span className='text-gray-400 fw-bold fs-6'>
                Accept wire transfers from customers
              </span>
            </span>
          </label>
        </div>
        <div className='col-lg-12'>
          <Field
            type='radio'
            className='btn-check'
            name='paymentType'
            value='Other'
            id='kt_create_account_form_account_type_enterprice'
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
            htmlFor='kt_create_account_form_account_type_enterprice'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com005.svg'
              className='svg-icon-3x me-5'
            />

            <span className='d-block fw-bold text-start'>
              <span className='text-dark fw-bolder d-block fs-4 mb-2'>Other Method</span>
              <span className='text-gray-400 fw-bold fs-6'>
                Payment method not listed? E.g. Zelle, Wise & etc
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  )
}

export {Step1}
