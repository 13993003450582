import React from 'react'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'

const Documents = () => {
  return (
    <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
      <div className='col-12 col-sm-12 col-xl-3'>
        <Card4
          icon='/media/svg/files/pdf.svg'
          title='Rental Agreement_3-3'
          description='2 days ago'
          link=''
        />
      </div>
      <div className='col-12 col-sm-12 col-xl-3'>
        <Card4
          icon='/media/svg/files/doc.svg'
          title='9 Degree CRUD Req..'
          description='2 days ago'
          link=''
        />
      </div>
    </div>
  )
}

export default Documents
