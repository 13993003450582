import * as Yup from 'yup'

export interface ICreateAccount {
  make: string
  model: string
  classification: string
  vin: string
  description: string
  feature: string
  type: string
  gallery: any[]
  vehicleImages: any[]
  dailyPrice: string
  insurancePolicy: string
  title: string
  policyNumber: string
  coverageType: string
  expirationDate: string
  milleage: string
  transmission: string
  drivetrain: string
  dueDate: string
  engineType: string
  status: string
  fuelType: string
  brokerAssigns: []
  addons: any[]
  enteriorColor: string
  vehicleImg: string
  interiorColor: string
  vehicleType: string
  trim: string
  year: string
  availibityStartDate: string
  availibityEndDate: string
  basePrice: string
  cleaningCost: string
  pricingOption: []
  notification: {
    email: boolean
    phone: boolean
  }
}

const createAccountSchemas = [
  Yup.object({
    make: Yup.string().label('Make is Required'),
  }),
  Yup.object({
    model: Yup.string().label('Model is Required'),
  }),
  Yup.object({
    vin: Yup.string().label('VIN is Required'),
  }),

  Yup.object({
    year: Yup.string().label('Year is Required'),
  }),
]

const inits: ICreateAccount = {
  make: '',
  model: '',
  vin: '',
  feature: '',
  type: '',
  description: '',
  status: 'Available',
  insurancePolicy: '',
  policyNumber: '',
  classification: '',
  dueDate: '',
  coverageType: '',
  expirationDate: '',
  milleage: '',
  vehicleImg: '/media/cars/tesla.svg',
  transmission: '',
  brokerAssigns: [],
  drivetrain: '',
  title: '',
  engineType: '',
  gallery: [],
  addons: [],
  dailyPrice: '',
  fuelType: '',
  enteriorColor: '',
  interiorColor: '',
  vehicleType: '',
  trim: '',
  year: '',
  availibityStartDate: '',
  availibityEndDate: '',
  basePrice: '',
  cleaningCost: '',
  pricingOption: [],
  vehicleImages: [],
  notification: {
    email: false,
    phone: false,
  },
}

export {createAccountSchemas, inits}
