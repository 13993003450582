import Pagination from 'react-responsive-pagination'

import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {UsersListFilter} from '../../../../modules/apps/user-management/users-list/components/header/UsersListFilter'
import {UsersListSearchComponent} from '../../../../modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {getAllBrokers, getAllBrokerStat} from '../../../../modules/auth/core/_requests'
import BrokerTable from './BrokerTable'
import {initialQueryState, useDebounce} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../modules/apps/user-management/users-list/core/QueryRequestProvider'

// import 'bootstrap/less/bootstrap.less'
const BrokersTableWrapper = () => {
  const [data, setData] = useState<any>([])
  const [customer, setCustomer] = useState<any>([])
  const [stat, setStatistics] = useState<any>(null)
  let [loading, setLoading] = useState(true)
  let [updated, setUpdated] = useState(false)
  let [activePage, setActivePage] = useState(0)
  const allCustomers = async () => {
    setLoading(true)
    try {
      const data = await getAllBrokers()
      // setData([])
      const startIndex = activePage
      const endIndex = startIndex + 10

      setData(data.data?.customers)
      setCustomer(data.data?.customers.slice(startIndex, endIndex))
      // setData()
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  const handlePageChange = (pageNumber: number) => {
    // console.log(`active page is ${pageNumber}`)
    const number = (pageNumber - 1) * 10
    setActivePage(pageNumber)
    const startIndex = number
    const endIndex = startIndex + 10

    setCustomer(data.slice(startIndex, endIndex))
  }
  const handleFilter = (val: number) => {
    // console.log(val)
    if (val === 1) {
      let dataNew = data.sort(function (a: any, b: any) {
        if (a.accountName < b.accountName) {
          return -1
        }
        if (a.accountName > b.accountName) {
          return 1
        }
        return 0
      })
      // console.log(data)
      setUpdated(!updated)
      setCustomer(dataNew)
    } else if (val === 2) {
      let dataNew = data.sort(function (a: any, b: any) {
        if (a.accountName > b.accountName) {
          return -1
        }
        if (a.accountName < b.accountName) {
          return 1
        }
        return 0
      })
      setUpdated(!updated)
      setCustomer(dataNew)
    }
  }

  const getAllCustomerStatistics = async () => {
    const data = await getAllBrokerStat()
    // console.log(data)
    let statistice = {
      availableCustomer: data.data?.customerListing.availableCustomer,
      blockListedCustomer: data.data?.customerListing.availableCustomer,
      notActiveCustomer: data.data?.customerListing.notActiveCustomer,
      totalCustomer: data.data?.customerListing.totalCustomer,
      newCustomerCount: data.data?.Brokers.Brokers,
      newCustomerData: data.data?.Brokers.newBrokerData,
    }
    // console.log(statistice)
    setStatistics(statistice)
    // setData(data.data?.customers)
  }
  useEffect(() => {
    allCustomers()
    getAllCustomerStatistics()
  }, [])
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const handleSearch = (val: string) => {
    // console.log(val.length)
    // if (val !== '') {
    setSearchTerm(val)
    if (val.length !== 0) {
      let dataNew = data.filter((item: any) => item.accountName.includes(val))
      setData(dataNew)
      setUpdated(!updated)
    } else if (val.length === 0) {
      // console.log('aya')
      const number = activePage * 10
      // setActivePage(pageNumber)
      const startIndex = number
      const endIndex = startIndex + 10

      setCustomer(data.slice(startIndex, endIndex))
      // setCustomer(customer)
      setUpdated(!updated)
    }

    // }
  }
  useEffect(() => {}, [updated])
  const download = (data: any) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], {type: 'text/csv'})

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')

    // Passing the blob downloading url
    a.setAttribute('href', url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'Broker.csv')

    // Performing a download with click
    a.click()
  }

  const csvmaker = (data: any) => {
    // console.log(data)

    let csvRows: any[] = []

    // const headers = Object.keys(data[0]);

    // csvRows.push(headers.join(','));
    const csvString = [
      [
        'Id',
        'email',
        'role',
        'accountName',
        'phone',
        'jobTitle',
        'firstName',
        'lastName',
        'description',
        'status',
        'createdAt',
      ],
      ...data.map((item: any) => [
        item._id,
        item.email,
        item.role,
        item.accountName,
        item.phone,
        item.jobTitle,
        item.firstName,
        item.lastName,
        item.description,
        item.status,
        item.createdAt,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n')

    // console.log(csvString);
    csvRows.push(csvString)
    return csvRows.join('\n')
  }
  const downloadCsv = () => {
    const csvdata = csvmaker(data)
    download(csvdata)
  }
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className='d-flex flex-row align-items-center justify-content-between w-100 w-md-auto gap-5'>
          <h3 className='fw-bolder my-2'>Broker ( {stat !== null ? stat.totalCustomer : ''}) </h3>
          <UsersListSearchComponent
            searchTerm={searchTerm}
            setSearchTerm={handleSearch}
            className='bg-white w-md-425px py-2'
          />
        </div>

        <div className='d-flex w-100 w-sm-auto align-items-center my-2'>
          <button type='button' className='btn bg-white me-3 btn-sm'>
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 mx-0' />
          </button>
          <UsersListFilter handleFilter={handleFilter} className='bg-white btn-sm' />

          {/* begin::Export */}
          <button type='button' className='btn bg-white btn-sm' onClick={downloadCsv}>
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Export
          </button>
          {/* end::Export */}
        </div>
      </div>

      <BrokerTable data={customer} loading={loading} className='mb-5 mb-xl-8'></BrokerTable>
      <div className='d-flex flex-stack flex-wrap'>
        {/* <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of {data.length} entries</div> */}

        <Pagination
          current={activePage}
          // itemsCountPerPage={10}
          total={Math.ceil(data.length / 10)}
          // pageRangeDisplayed={5}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  )
}

export default BrokersTableWrapper
