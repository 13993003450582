import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTSVG, initialQueryState, useDebounce} from '../../../../_metronic/helpers'
import {TablesVehicle} from '../../../../_metronic/partials/widgets/tables/tableVehicle'
import {useQueryRequest} from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import {
  TripByID,
  customerVerify,
  customerVerifyID,
  filterVehicle,
  getALLLegal,
  getAllCompanyById,
  getAllCompanyUsers,
  getAllCustomerById,
  getAllTripsStat,
  getAllVehicle,
  getAlltripStatDashBoard,
  requestPassword,
  stripePayment,
  stripeVerification,
  updateTrip,
  uploadDocuemnt,
} from '../../../modules/auth/core/_requests'
import './index.css'

import {Stripe, StripeCardElement, StripeElementsOptions, loadStripe} from '@stripe/stripe-js'
import {createBrowserHistory} from 'history'
import {StatisticsWidget5} from '../../../../_metronic/partials/widgets'
import AllTrips from '../../trips/Overview/AllTripsTable'
import CarAvailabilityCard from './CarAvailabilityCard'
import {FeedsWidget6} from './FeedsWidget6'
import {FeedsWidgetUpload} from './FeedsWidget6Upload'
import {FeedsWidgetLink} from './FeedsWidgetLinks'
import {FeedsWidgetPay} from './FeedsWidgetPay'
import {CustomerTable} from './companyTable'
import {PayNow} from './paynow'
import {FeedsWidgetReset} from './FeedsWidgetReset'
type Props = {
  stripePromise: Promise<Stripe | null>
}
const Ovewview: FC<Props> = ({stripePromise}) => {
  const stripe = useStripe()
  const elements = useElements()
  const browserHistory = createBrowserHistory()
  const [data, setData] = useState<any>([])
  const [legal, setLegal] = useState<any>([])
  const [stripes, setStripe] = useState<any>({})
  const [company, setCompany] = useState<any>([])
  const [singleCompanies, setCompanies] = useState<any>([])
  const [vehicle, setVehicle] = useState<any>([])
  const [vechilePage, setVehiclePage] = useState<any>([])
  const [trip, setTrips] = useState<any>([])
  const [statistice, setStatistice] = useState<any>(null)
  const [totalCost, setTotalIncome] = useState<any>([])
  const [loading, setLoading] = useState(false)
  let [activePageVehicle, setActivePageVehicle] = useState(1)
  let [activePageTripe, setActivePageTrips] = useState(0)
  let [updated, setUpdated] = useState(false)
  let urlId = localStorage.getItem('companyName') as string
  const stripePromisePayNow = loadStripe(localStorage.getItem('publisherKey') as string)
  const getAllCustomerStatistics = async () => {
    const legal = await getALLLegal()
    console.log(legal)
    setStripe(await stripePromise)
    setLegal(legal.data.data)
    setLoading(true)
    try {
      const data = await getAllTripsStat()

      const vehicle = await getAllVehicle()
      // console.log(vehicle)
      let income = 0
      setVehicle(vehicle.data?.vehicles)
      const startIndex = activePageVehicle
      const endIndex = startIndex + 10

      setVehiclePage(vehicle.data?.vehicles.slice(startIndex, endIndex))
      // setStatistics(statistice)
      setData(data.data?.trips)
      const startIndexTrips = activePageTripe
      const endIndexTrips = startIndexTrips + 10

      setTrips(data.data?.trips.slice(startIndexTrips, endIndexTrips))
      data.data?.trips.forEach((item: any) => {
        income += item.total
      })
      setTotalIncome(income)
      const trips = await getAlltripStatDashBoard()
      const companies = await getAllCompanyUsers()
      console.log(companies)
      setCompany(companies.data.customers)
      urlId = localStorage.getItem('companyName') as string
      const company = await getAllCompanyById(urlId)
      console.log('s', company)
      setCompanies(company.data.customer)
      let statistice = {
        totalTripsCost: trips.data?.tripsListing.totalTripsCost,
        avgTrips: trips.data?.tripsListing.avgTrips,
        totalTrips: trips.data?.tripsListing.totalTrips,
      }
      // console.log(statistice)
      setStatistice(statistice)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAllCustomerStatistics()
  }, [])
  console.log('s', urlId)
  const [clientScret, setClientSecret] = useState('')
  const StripePhoto = async () => {
    let key = singleCompanies[0].paymentMethods.filter((item: any) => item.paymentType === 'Stripe')
    console.log(key)
    const values = {stripe: key[0]?.secretKey}
    const data = await stripeVerification(values)
    console.log(data)
    setClientSecret(data.data.data)
    console.log(clientScret)
    const {error} = await stripes.verifyIdentity(clientScret)

    if (error) {
      console.log('[error]', error)
    } else {
      const customer = await customerVerify()
      Swal.fire({
        timer: 3000,
        text: customer.data.message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      console.log('Verification submitted!')
      window.location.reload()
    }
    // setShowCreateRentalModal(true)
    // setId(id)
  }
  const StripeCustomerInsurance = async () => {
    let key = singleCompanies[0].paymentMethods.filter((item: any) => item.paymentType === 'Stripe')
    console.log(key)
    const values = {stripe: key[0]?.secretKey}
    const data = await stripeVerification(values)
    console.log(data)
    setClientSecret(data.data.data)
    console.log(clientScret)
    const {error} = await stripes.verifyIdentity(clientScret)

    if (error) {
      console.log('[error]', error)
    } else {
      const customer = await customerVerifyID()
      Swal.fire({
        timer: 3000,
        text: customer.data.message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      console.log('Verification submitted!')
      window.location.reload()
    }
    // setShowCreateRentalModal(true)
    // setId(id)
  }
  const handlePageChangeVehicle = () => {
    // console.log(`active page is ${pageNumber}`)
    let num = 0
    const number = activePageVehicle * 10
    setActivePageVehicle(activePageVehicle + 1)
    const startIndex = number
    const endIndex = startIndex + 10

    setVehicle(data.slice(num, endIndex))

    setVehiclePage(vehicle.slice(startIndex, endIndex))
  }
  const handlePageChangeTrip = (pageNumber: number) => {
    // console.log(`active page is ${pageNumber}`)
    const number = (pageNumber - 1) * 10
    setActivePageTrips(pageNumber)
    const startIndex = number
    const endIndex = startIndex + 10

    setTrips(data.slice(startIndex, endIndex))
  }
  useEffect(() => {}, [updated])
  const handleFilter = (val: number) => {
    if (val === 1) {
      let data = vehicle.sort(function (a: any, b: any) {
        if (a.vehicleType < b.vehicleType) {
          return -1
        }
        if (a.vehicleType > b.vehicleType) {
          return 1
        }
        return 0
      })
      setVehiclePage(data)
      setUpdated(!updated)
    } else if (val === 2) {
      let data = vehicle.sort(function (a: any, b: any) {
        if (a.vehicleType > b.vehicleType) {
          return -1
        }
        if (a.vehicleType < b.vehicleType) {
          return 1
        }
        return 0
      })
      setVehiclePage(data)
      setUpdated(!updated)
    }
  }
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const handleSearch = (val: string) => {
    // console.log(val.length)
    // if (val !== '') {
    setSearchTerm(val)
    if (val.length !== 0) {
      let data = vehicle.filter((item: any) => item.vehicleType.includes(val))
      setVehiclePage(data)
      setUpdated(!updated)
    } else if (val.length === 0) {
      // console.log('aya')
      const number = activePageVehicle * 10
      // setActivePage(pageNumber)
      const startIndex = number
      const endIndex = startIndex + 10

      setVehiclePage(vehicle.slice(startIndex, endIndex))
      // setCustomer(customer)
      setUpdated(!updated)
    }

    // }
  }
  const [user, setUser] = useState<any | null>(null)
  useEffect(() => {
    const data = localStorage.getItem('user')
    if (data != null) {
      let dataTemp = JSON.parse(data)

      setUser(dataTemp)
    }
  }, [])

  const [year, setYear] = useState<string>('')
  const [make, setMake] = useState<string>('')
  const [model, setModel] = useState<string>('')
  const [engine, setEngine] = useState<string>('')
  const [pickUp, setPickUP] = useState<string>('')
  const [DropOff, setDropOff] = useState<string>('')
  type Org = {[key: string]: string}
  const handleFiltersAll = async () => {
    const obj: Org = {}

    if (year !== '') {
      obj['year'] = year
      // obj.mode = mode
    }
    if (model !== '') {
      obj['model'] = model
      // obj.mode = mode
    }
    if (make !== '') {
      obj['make'] = make
      // obj.mode = mode
    }
    if (engine !== '') {
      obj['engine'] = engine
      // obj.mode = mode
    }
    if (pickUp !== '') {
      obj['availibityStartDate'] = pickUp
      // obj.mode = mode
    }
    if (DropOff !== '') {
      obj['availibityEndDate'] = DropOff
      // obj.mode = mode
    }
    // console.log(obj)
    const data = await filterVehicle(obj)
    // console.log(data)
    const startIndex = activePageVehicle
    const endIndex = startIndex + 10
    setVehicle(data.data?.vehicles)
    setUpdated(!updated)
    setVehiclePage(data.data?.vehicles.slice(startIndex, endIndex))
  }
  const [total, setTotal] = useState('')
  const [id, setId] = useState('')
  const [status, setStatus] = useState('')
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)

  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
    // setId('')
  }
  const [selected, setSelected] = useState(false)
  const [showCreateRentalModalPay, setShowCreateRentalModalPay] = useState(false)
  const openCreateRentalModalPay = (element: any) => {
    setShowCreateRentalModalPay(true)
    setTotal(element.total)
    setId(element._id)
    setSelected(!selected)
    localStorage.setItem('id', element._id)
    // browserHistory.push('/dashboard/payNow')
    // window.location.reload()
  }
  const closeCreateRentalModalPay = () => {
    setShowCreateRentalModalPay(false)
    // setId('')
  }
  const [pdfFile, setPDfFile] = useState('')
  // const [loading, setLoading] = useState(false)

  const handleImage = (event: any) => {
    console.log(event.target.files[0])
    setPDfFile(event.target.files[0])
  }
  const handleSubmit = async () => {
    setLoading(true)
    // const user = localStorage.getItem('id')
    const formData = new FormData()

    formData.append('document', pdfFile)

    try {
      const {
        data: {data, message},
      } = await uploadDocuemnt(user._id, formData)
      // console.log(data)

      // getAllCustomerByIds()
      closeCreateRentalModal()
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      setLoading(false)
      // setLoading(false)

      // browserHistory.push('/vehicle/details/settings')
      // window.location.reload()
    } catch (error) {
      // stepper.current.goNext()
      console.error(error)
      setLoading(false)
      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }
  const sendResetPassword = async () => {
    const {
      data: {data, message},
    } = await requestPassword(user.email)
    // console.log(data)
    Swal.fire({
      timer: 3000,
      text: message,
      icon: 'success',
      showCloseButton: false,
      showConfirmButton: false,
      showDenyButton: false,
    })
  }
  const [customer, setCustomer] = useState<any>(null)

  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('user')
    console.log(user)
    if (user != null) {
      let dataTemp = JSON.parse(user)
      //  setUser(dataTemp)

      const data = await getAllCustomerById(dataTemp._id)

      // console.log(data)
      setCustomer(data.data.customer)
    }
    // setData(data.data?.customers)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  console.log(customer)
  console.log(customer?.tripId.filter((item: {status: string}) => item.status === 'Pending'))
  let code = localStorage.getItem('id')
  const [trips, setTripData] = useState<any>([])
  const [totalTrips, setTotalTrips] = useState(0)
  const [remintotal, setRemainTotal] = useState(0)
  const [remaining, setRemiaing] = useState(0)
  const [clientSecretPayNow, setClientSecretPayNow] = useState('')
  console.log('in', selected)
  const tripById = async () => {
    console.log('in', selected)
    const data = await TripByID(code)
    setTripData(data.data.trips)
    console.log('data.data.trips', data.data.trips)
    setRemiaing(data.data.trips[0].total - data.data.trips[0].deposit)
    let totalCost =
      data.data.trips[0]?.vechicleId?.priceClassification +
      (data.data.trips[0]?.vechicleId?.priceClassification *
        parseInt(data.data.trips[0]?.onwerId.prices?.serviceFee)) /
        100 +
      (data.data.trips[0]?.vechicleId?.priceClassification *
        parseInt(data.data.trips[0]?.onwerId.prices?.salesTax)) /
        100
    console.log('totalCost', totalCost)
    setTotalTrips(totalCost)
    let val = data.data.trips[0].total - data.data.trips[0].deposit
    let totalRemainingCost =
      val +
      parseInt(data.data.trips[0].bookingfee) +
      (val * parseInt(data.data.trips[0]?.onwerId.prices?.serviceFee)) / 100 +
      (val * parseInt(data.data.trips[0]?.onwerId.prices?.salesTax)) / 100

    setRemainTotal(totalRemainingCost)
    console.log('singleCompanies', singleCompanies)
    let key = singleCompanies[0]?.paymentMethods.filter(
      (item: any) => item.paymentType === 'Stripe'
    )
    console.log(totalRemainingCost)
    let stripeData = {}
    if (data.data.trips[0]?.status === 'Pending') {
      stripeData = {cost: totalCost, secretKey: key[0]?.secretKey}
    } else {
      stripeData = {cost: totalRemainingCost.toFixed(0), secretKey: key[0]?.secretKey}
    }
    const stripeCode = await stripePayment(stripeData)
    console.log('stripeCode', stripeData)
    setClientSecretPayNow(stripeCode.data.clientSecret)
    // Create PaymentIntent as soon as the page loads
  }
  useEffect(() => {
    tripById()
  }, [totalTrips, remaining, selected])
  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret: clientSecretPayNow,
    appearance,
  }
  console.log('clientSecretPayNow', clientSecretPayNow)
  return (
    <>
      {user?.role === 'Company' || user?.role === 'Employee' ? (
        <>
          <div className='  gx-xl-8 w-100 mb-xl-8' style={{marginTop: '2rem'}}>
            <div className='row g-5 g-xl-8 '>
              <div className='col-xl-4'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/icons/duotune/general/Group29.svg'
                  href={`/${urlId}/vehicle/add-Rental`}
                  color='danger'
                  iconColor='white'
                  titleColor='white'
                  descriptionColor='white'
                  title='Create Rental'
                  description='Book your next adventure with a few clicks'
                />
              </div>

              <div className='col-xl-4'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/icons/duotune/general/gen008.svg'
                  color='primary'
                  href={`/${urlId}/trips/overview`}
                  iconColor='white'
                  titleColor='white'
                  descriptionColor='white'
                  title='View Trips'
                  description='Take a closer look at your rental history'
                />
              </div>

              <div className='col-xl-4'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-5 mb-xl-8'
                  svgIcon='/media/icons/duotune/graphs/gra005.svg'
                  color='success'
                  iconColor='white'
                  titleColor='white'
                  href={''}
                  descriptionColor='white'
                  title='Sales Stats'
                  description='Check the status of your rental income'
                />
              </div>
            </div>
            <CarAvailabilityCard
              handleFilter={handleFiltersAll}
              setYear={setYear}
              setMake={setMake}
              setDropOff={setDropOff}
              setModel={setModel}
              setEngine={setEngine}
              setPickUP={setPickUP}
              className='card-xl-stretch mb-8'
            />
          </div>
          <div className='mb-9'>
            {vehicle !== undefined && (
              <TablesVehicle
                className='card-xl-stretch mb-xl-8'
                title='Vehicles'
                pagination={true}
                data={vechilePage}
                length={vechilePage.length}
                searchTerm={searchTerm}
                setSearchTerm={handleSearch}
                handleFilter={handleFilter}
                actual={vehicle}
                handlePageChange={handlePageChangeVehicle}
                activePage={activePageVehicle}
                loading={loading}
              ></TablesVehicle>
            )}
          </div>

          <AllTrips
            data={trip}
            loading={loading}
            actual={data}
            handlePageChange={handlePageChangeTrip}
            activePage={activePageTripe}
          />
        </>
      ) : user?.role === 'Admin' ? (
        <CustomerTable className='mb-5 mb-xl-8' data={company} loading={loading}></CustomerTable>
      ) : (
        <>
          <h4 className='mb-3'>Your Tasks</h4>
          <div className='row'>
            <div className='col-sm-4 col-xs-12'>
              {' '}
              <FeedsWidget6
                description='In order to complete your pending rental of the 2021 Lamborghini Urus you must complete the rental agreement'
                title='Complete Rental Agreement'
                text='View Agreement'
                link={
                  legal.filter((item: any) => item.key === 'Rental Agreements')[0]?.document !==
                  undefined
                    ? legal.filter((item: any) => item.key === 'Rental Agreements')[0]?.document
                    : ''
                }
                className='mb-5 mb-xl-8 p-3'
              />
            </div>
            {customer?.CarInsuranceStatus !== true && (
              <div className='col-sm-4 col-xs-12'>
                {' '}
                <FeedsWidgetUpload
                  description=''
                  title='Upload Proof of Car Insurance'
                  text='Upload Now'
                  stripePromise={stripes}
                  className='mb-5 mb-xl-8 p-3'
                  openCreateRentalModal={StripeCustomerInsurance}
                />
              </div>
            )}
            <div className='col-sm-4 col-xs-12'>
              {' '}
              <FeedsWidgetLink
                description='Click here to view '
                title='Terms of Service Agreement'
                text=''
                href={
                  legal.filter((item: any) => item.key === 'Terms Of Service')[0]?.document !==
                  undefined
                    ? legal.filter((item: any) => item.key === 'Terms Of Service')[0]?.document
                    : ''
                }
                link='Corsa Dash Terms of Service'
                className='mb-5 mb-xl-8 p-3'
              />
            </div>
            <div className='col-sm-4 col-xs-12'>
              {' '}
              <FeedsWidgetLink
                title='Refund Policy Agreement'
                description='Click here to view '
                text=''
                href={
                  legal.filter((item: any) => item.key === 'Refund Policy')[0]?.document !==
                  undefined
                    ? legal.filter((item: any) => item.key === 'Refund Policy')[0]?.document
                    : ''
                }
                link='Corsa Dash Refund Policy'
                className='mb-5 mb-xl-8 p-3'
              />
            </div>
            {customer?.photoStatus === false && (
              <div className='col-sm-4 col-xs-12'>
                {' '}
                <FeedsWidgetUpload
                  title='Upload your Photo ID'
                  description='To better serve you, an identity verification is required. Accepted documents: License, State ID, Passport'
                  text='Upload Now'
                  stripePromise={stripes}
                  openCreateRentalModal={StripePhoto}
                  className='mb-5 mb-xl-8 p-3 '
                />
              </div>
            )}

            <div className='col-sm-4 col-xs-12'>
              {' '}
              <FeedsWidgetReset
                title='Reset Your Password'
                description='Welcome to Corsa Dash. Reset your temporary password now.'
                text='Reset Password'
                openCreateRentalModal={sendResetPassword}
                className='mb-5 mb-xl-8 p-3 '
              />
            </div>
          </div>
          <div className='row'>
            {customer?.tripId
              .filter((item: {status: string}) => item.status === 'Pending')
              .map((element: any) => (
                <div className='col-sm-4 col-xs-12'>
                  {' '}
                  <FeedsWidgetPay
                    description='You have a pending rental waiting for you. Pay the deposit now to confirm your booking'
                    title='Pay Rental Deposit'
                    text='Pay Now'
                    openCreateRentalModal={() => openCreateRentalModalPay(element)}
                    amount={element?.total}
                    className='mb-5 mb-xl-8 p-3'
                  />
                </div>
              ))}
          </div>
          <div className='row'>
            {customer?.tripId
              .filter((item: {status: string}) => item.status === 'Confirmed')
              .map((element: any) => (
                <div className='col-sm-4 col-xs-12'>
                  {' '}
                  <FeedsWidgetPay
                    description='Time to pay for your upcoming rental. Click Pay Now to get started.'
                    title=' Rental Payment Required'
                    text='Pay Now'
                    openCreateRentalModal={() => openCreateRentalModalPay(element)}
                    amount={element?.total}
                    className='mb-5 mb-xl-8 p-3'
                  />
                </div>
              ))}
          </div>
        </>
      )}
      {showCreateRentalModalPay && (
        <Modal
          show={showCreateRentalModalPay}
          onHide={closeCreateRentalModalPay}
          size='xl'
          centered
        >
          <Modal.Body>
            <div className='w-100'>
              {clientSecretPayNow && (
                <Elements stripe={stripePromisePayNow} options={options as StripeElementsOptions}>
                  {' '}
                  <PayNow />
                </Elements>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default Ovewview
