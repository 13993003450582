import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Documents from './Documents'
import {Projects} from './Projects'

const profileBreadCrumbs: Array<PageLink> = [
  // {
  //   title: 'Home',
  //   path: '/dashboard',
  //   isSeparator: false,
  //   isActive: false,
  // },
  // {
  //   title: 'Vehicle',
  //   path: '/builder',
  //   isSeparator: false,
  //   isActive: false,
  // },
]

const CustomerPage = () => (
  <Routes>
    <Route
      path='actvity'
      element={
        <>
          <Projects />
        </>
      }
    />
    <Route
      path='agreement'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Agreement</PageTitle>
          <Documents />
        </>
      }
    />
    <Route index element={<Navigate to='/customers/actvity' />} />
  </Routes>
)

export default CustomerPage
