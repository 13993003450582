import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {Registration} from './Registration'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Registration',
    path: '/registation',
    isSeparator: false,
    isActive: false,
  },
]

const BrokerProfilePage = () => (
  <>
    <PageTitle breadcrumbs={profileBreadCrumbs}>Company Regsiter</PageTitle>
    <Registration />
  </>
)

export default BrokerProfilePage
