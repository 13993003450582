import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {Connections} from '../../../../modules/profile/components/Connections'
import {Projects} from '../../../../modules/profile/components/Projects'
import {BrokerProfileHeader} from './ProfileHeader'
import Documents from './Documents'
import Overview from './Overview'
const companyName = localStorage.getItem('companyName') as string
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: `/${companyName}/dashboard/overview`,

    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Brokers',
    path: `/${companyName}/broker/settings`,
    isSeparator: false,
    isActive: false,
  },
]

const BrokerProfilePage = () => (
  <Routes>
    <Route
      element={
        <>
          <BrokerProfileHeader />
          <Outlet />
        </>
      }
    >
      <Route
        path='settings'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Broker Profile</PageTitle>
            <Overview />
          </>
        }
      />
      <Route
        path='activity'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Projects</PageTitle>
            <Projects />
          </>
        }
      />
      <Route
        path='documents'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Customer Profile</PageTitle>
            <Documents />
          </>
        }
      />
      {/* <Route
        path='contact'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
            <Documents />
          </>
        }
      /> */}
      {/* <Route
        path='activity'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Connections</PageTitle>
            <Connections />
          </>
        }
      /> */}
      <Route index element={<Navigate to={`/${companyName}broker/profile/settings`} />} />
    </Route>
  </Routes>
)

export default BrokerProfilePage
