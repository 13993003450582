/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getAllCustomerById, getStat} from '../../../../modules/auth/core/_requests'
import {Invite} from './invite'
import './index.css'
const BrokerProfileHeader: React.FC = () => {
  const location = useLocation()
  const companyName = localStorage.getItem('companyName') as string
  const [customer, setCustomer] = useState<any>(null)
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)
  const [statistice, setStatistice] = useState<any>(null)

  const openCreateRentalModal = () => {
    setShowCreateRentalModal(true)
  }
  const closeCreateRentalModal = () => {
    setShowCreateRentalModal(false)
  }
  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('user')
    console.log(user)
    if (user != null) {
      let dataTemp = JSON.parse(user)
      //  setUser(dataTemp)

      const data = await getAllCustomerById(dataTemp._id)
      const stat = await getStat()
      let stats = {
        sum: stat.data.data.sum,
        customer: stat.data.data.customer,
      }
      setStatistice(stats)
      // console.log(data)
      setCustomer(data.data.customer)
      // setData(data.data?.customers)
    }
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])

  // console.log('customer', customer)
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='w-100 h-100'>
                <img
                  src={customer?.profileImg}
                  alt='Metornic'
                  className='w-100 h-100'
                  style={{objectFit: 'contain', background: 'black', borderRadius: '1rem'}}
                />
              </div>
            </div>
            <div className='col-sm-10'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-4 fw-bolder me-1'>
                      {customer?.firstName}&nbsp; {customer?.lastName}
                    </a>
                    {/* <a href='#'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </a> */}
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-4 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      />
                      {customer?.companyName}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      />
                      {customer?.locations[0]?.address1}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {customer?.email}
                    </a>
                  </div>
                </div>
                {/* */}
                <div
                  className='d-flex justifty-content-end
                '
                >
                  <Link
                    to='#'
                    onClick={openCreateRentalModal}
                    className='btn btn-sm btn-success me-3'
                  >
                    Invite Members
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className='col-sm-4'></div> */}
          </div>
          {/* <div className='d-flex flex-wrap flex-sm-nowrap mb-1'> */}
          <div className='me-7 '>
            {/* </div> */}

            <div className='flex-grow-1'>
              {/* <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      {/* <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{statistice?.sum as string}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Total Earning </div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{statistice?.customer as string}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Total Customers</div> 
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/${companyName}/setting/settings` && 'active')
                  }
                  to={`/${companyName}/setting/settings`}
                >
                  Overview
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/${companyName}/setting/teams` && 'active')
                  }
                  to={`/${companyName}/setting/teams`}
                >
                  Team Members
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/setting/pricing' && 'active')
                  }
                  to='/setting/pricing'
                >
                  Pricing and Fees
                </Link>
              </li> */}
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/setting/documents' && 'active')
                  }
                  to='/setting/documents'
                >
                  Documents
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/${companyName}/setting/subscription` && 'active')
                  }
                  to={`/${companyName}/setting/subscription`}
                >
                  Your Plan
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModal} centered>
          <Modal.Body>
            <Invite closeCreateRentalModal={closeCreateRentalModal} />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export {BrokerProfileHeader}
