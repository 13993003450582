/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import AllTripsTable from '../../../../_metronic/partials/widgets/tables/CustomerActivity'
import {getAllCustomerById} from '../../auth/core/_requests'
import Pagination from 'react-responsive-pagination'
export function Projects() {
  const [customer, setCustomer] = useState<any>([])
  const [data, setData] = useState<any>([])

  let [updated, setUpdated] = useState(false)
  let [loading, setLoading] = useState(true)
  let [activePage, setActivePage] = useState(0)
  const getAllCustomerByIds = async () => {
    const user = localStorage.getItem('id')
    // console.log(user)
    // if (user != null) {
    // let dataTemp = JSON.parse(user)
    //  setUser(dataTemp)

    const data = await getAllCustomerById(user)
    const startIndex = activePage
    const endIndex = startIndex + 10
    setData(data.data?.customer?.tripId)
    setCustomer(data.data?.customer?.tripId.slice(startIndex, endIndex))
    // console.log(data)

    // setData(data.data?.customers)
  }
  const handlePageChange = (pageNumber: number) => {
    // console.log(`active page is ${pageNumber}`)
    const number = (pageNumber - 1) * 10
    setActivePage(pageNumber)
    const startIndex = number
    const endIndex = startIndex + 10

    setCustomer(data.slice(startIndex, endIndex))
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])

  console.log('customer', customer)
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className='d-flex flex-row align-items-center justify-content-between w-100 w-md-auto gap-5'>
          <h3 className='fw-bolder my-2'>Activity</h3>
          {/* <UsersListSearchComponent className='bg-white w-md-425px py-2' /> */}
        </div>

        <div className='d-flex w-100 w-sm-auto align-items-center my-2'>
          {/* <button type='button' className='btn bg-white me-3 btn-sm'>
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 mx-0' />
          </button> */}
          {/* <UsersListFilter className='bg-white btn-sm' /> */}

          {/* begin::Export */}
          <button type='button' className='btn bg-primary btn-sm text-white'>
            {/* <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' /> */}
            Download
          </button>
          {/* end::Export */}
        </div>
      </div>

      <AllTripsTable data={customer} className='mb-5 mb-xl-8'></AllTripsTable>

      <div className='d-flex flex-stack flex-wrap'>
        <Pagination
          current={activePage}
          // itemsCountPerPage={10}
          total={Math.ceil(data.length / 10)}
          // pageRangeDisplayed={5}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  )
}
