export const cars = [
  {
    image: '/media/cars/lamborgini.svg',
    name: 'Lamborgini',
    model: 'Huracan',
    year: '2022',
    vin: '1359085943879245434',
    trips: 25,
    status: 'Maintenance',
  },
  {
    image: '/media/cars/mclaren.svg',
    name: 'McLaren',
    model: '720S',
    year: '2019',
    vin: '1359085943879245434',
    trips: 2,
    status: 'Available',
  },
  {
    image: '/media/cars/bmw.svg',
    name: 'BMW',
    model: 'M5',
    year: '2021',
    vin: '1359085943879245434',
    trips: 0,
    status: 'Ride in Progress',
  },
  {
    image: '/media/cars/ferrari.svg',
    name: 'Ferrari',
    model: 'F8',
    year: '2017',
    vin: '1359085943879245434',
    trips: 12,
    status: 'Available',
  },
  {
    image: '/media/cars/rolls-royce.svg',
    name: 'Rolls Royce',
    model: 'Cullian',
    year: '2021',
    vin: '1359085943879245434',
    trips: 32,
    status: 'Available',
  },
  {
    image: '/media/cars/tesla.svg',
    name: 'Tesla',
    model: 'Model X',
    year: '2019',
    vin: '1359085943879245434',
    trips: 8,
    status: 'Maintenance',
  },
]

export const trips = [
  {
    // image: "/media/cars/lamborgini.svg",
    status: 'Completed',
    vehicle: '2022 Lamborghini Urus',
    customer: 'Jason Bourne',
    timeSpan: 'Jan 1-3',
    pickupLocation: '141 Rainbow Ct. Miami FL',
  },
  {
    // image: "/media/cars/lamborgini.svg",
    status: 'Completed',
    vehicle: '2022 Lamborghini Urus',
    customer: 'Jason Bourne',
    timeSpan: 'Jan 1-3',
    pickupLocation: '141 Rainbow Ct. Miami FL',
  },
  {
    // image: "/media/cars/lamborgini.svg",
    status: 'Completed',
    vehicle: '2022 Lamborghini Urus',
    customer: 'Jason Bourne',
    timeSpan: 'Jan 1-3',
    pickupLocation: '141 Rainbow Ct. Miami FL',
  },
  {
    // image: "/media/cars/lamborgini.svg",
    status: 'Completed',
    vehicle: '2022 Lamborghini Urus',
    customer: 'Jason Bourne',
    timeSpan: 'Jan 1-3',
    pickupLocation: '141 Rainbow Ct. Miami FL',
  },
  {
    // image: "/media/cars/lamborgini.svg",
    status: 'Completed',
    vehicle: '2022 Lamborghini Urus',
    customer: 'Jason Bourne',
    timeSpan: 'Jan 1-3',
    pickupLocation: '141 Rainbow Ct. Miami FL',
  },
  {
    // image: "/media/cars/lamborgini.svg",
    status: 'Completed',
    vehicle: '2022 Lamborghini Urus',
    customer: 'Jason Bourne',
    timeSpan: 'Jan 1-3',
    pickupLocation: '141 Rainbow Ct. Miami FL',
  },
]

export const MockHistory = [
  {
    customer: 'Jared Sellers',
    tripCost: '510,50',
    mileage: 203,
    date: 'Jan 12 - Jan 17',
    status: 'Yet to start',
  },
  {
    customer: 'Jared Sellers',
    tripCost: '54,5013.53',
    mileage: 115,
    date: 'Jan 12 - Jan 17',
    status: 'In Progress',
  },
  {
    customer: 'Jared Sellers',
    tripCost: '54,5013.53',
    mileage: 115,
    date: 'Jan 12 - Jan 17',
    status: 'Completed',
  },
  {
    customer: 'Jared Sellers',
    tripCost: '54,5013.53',
    mileage: 115,
    date: 'Jan 12 - Jan 17',
    status: 'Completed',
  },
  {
    customer: 'Jared Sellers',
    tripCost: '54,5013.53',
    mileage: 115,
    date: 'Jan 12 - Jan 17',
    status: 'Completed',
  },
  {
    customer: 'Jared Sellers',
    tripCost: '-',
    mileage: 0,
    date: 'Mar 1, 2022',
    status: 'Cancelled',
  },
]

export const Goals = [
  {
    time_duration: '10:20 - 11:00',
    time_standard: 'AM',
    description: '9 Degree Project Estimation Meeting',
    lead_by: 'Peter Marcus',
  },
  {
    time_duration: '12:00 - 13:40',
    time_standard: 'PM',
    description: 'Dashboard UI/UX Design Review',
    lead_by: 'Bob',
  },
  {
    time_duration: '16:30 - 17:00',
    time_standard: 'AM',
    description: 'Marketing Campaign Discussion',
    lead_by: 'Mark Morris',
  },
]
export const type = [
  'Wagon',
  'Sedan',
  'SUV',
  'Hatchback',
  'Coupe',
  'Convertible',
  'Pickup Truck',
  'Convertible SUV',
  'Cargo Van',
  'Passenger Van',
  'I4',
  'I3',
  'I5',
  'I6',
  'V10',
  'v6',
  'V8',
  'V12',
  'W12',
  'W16',
  'manual',
  'Naturally Aspirated',
  'Passenger Minivan',
  'Cargo Minivan',
  'Electric Motor',
  'Flat-4',
  'Flat-6',
]
export const year = ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022']

export const make = [
  'Acura',
  'Alfa Romeo',
  'Aston Martin',
  'Audi',
  'Bentley',
  'BMW',
  'Bugatti',
  'Buick',
  'Cadillac',
  'Chevrolet',
  'Chrysler',
  'Daewoo',
  'Dodge',
  'Ferrari',
  'FIAT',
  'Fisker',
  'Ford',
  'Genesis',
  'GMC',
  'Honda',
  'HUMMER',
  'Hyundai',
  'INFINITI',
  'Isuzu',
  'Jaguar',
  'Jeep',
  'Karma',
  'Kia',
  'Lamborghini',
  'Land Rover',
  'Lexus',
  'Lincoln',
  'Lotus',
  'Lucid',
  'Maserati',
  'Maybach',
  'Mazda',
  'McLaren',
  ' Mercedes - Benz',
  'Mercury',
  'MINI',
  'Mitsubishi',
  'Nissan',
  'Oldsmobile',
  'Panoz',
  'Plymouth',
  'Polestar',
  'Pontiac',
  'Porsche',
  'Ram',
  'Rivian',
  'Rolls - Royce',
  'Saab',
  'Saturn',
  'Scion',
  'smart',
  'Spyker',
  'Subaru',
  'Suzuki',
  'Tesla',
  'Toyota',
  'Volkswagen',
  'Volvo',
]
export const transmission = [
  ' 100 hp @ 4400 rpm',
  '100 hp @ 4600 rpm',
  '100 hp @ 4800 rpm',
  '100 hp @ 5000 rpm',
  '100 hp @ 5400 rpm',
  '100 hp @ 5600 rpm',
  '100 hp @ 5750 rpm',
  '100 hp @ 6100 rpm',
  '100 hp @ 6400 rpm',
]
export const derivation = [
  '100 mpg',
  '101 mpg',
  '102 mpg',
  '1020 hp @ 0 rpm',
  '104 mpg',
  '105 mpg',
  '106 mpg',
  '107 mpg',
  '108 hp @ 6000 rpm',
]

export const engine = [
  '4WD',
  'automatic',
  'AWD',
  'FWD',
  'manual',
  'Naturally Aspirated',
  'RWD',
  'Supercharged',
  'Turbocharged',
  'Twincharged',
  'Twin-Turbo',
]
export const egineType = [
  'AWD',
  'Electric Motor',
  'Flat-4',
  'Flat-6',
  'FWD',
  'I3',
  'I4',
  'I5',
  'I6',
  'Naturally Aspirated',
  'Quad-Turbo',
  'Rotary',
]
export const trim = ['1', '1.6', '1.8', '1.9', '2', '2.2', '2.3', '2.4', '2.5', '2.8', '2.9']
// export const model = [
//   'Integra',
//   'MDX',
//   'Giulia',
//   'Stelvio',
//   'A3',
//   'A4',
//   'Q5 Sportback',
//   'RS Q8',
//   'R8',
//   'RS e-tron GT',
//   'S3',
//   'S4',
//   'S5',
//   'e-tron Sportback',
//   '2 Series Gran Coupe',
//   'e-tron GT',
//   'e-tron',
//   '8 Series',
//   'ALPINA B8 Gran Coupe',
//   'M2',
//   'M3',
//   'M4',
//   'M5',
// ]
export const model = ['Coupe', 'Sedan', 'SUV', 'Truck', 'Van']
