import React, {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {TripsPage} from './TripsPage'

const TripsPageWrapper: FC = () => {
  const tripsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={tripsBreadCrumbs}>Trips</PageTitle>
      <TripsPage />
    </>
  )
}

export default TripsPageWrapper
