import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import Documents from './components/Profile/Documents'
import Overview from './components/Profile/Overview'
import {BrokerProfileHeader} from './components/Profile/ProfileHeader'
import {MixedWidget7} from './components/subscription'
import {TeamMember} from './components/teamMember'
const companyName = localStorage.getItem('companyName') as string
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Setting',
    path: '/setting',
    isSeparator: false,
    isActive: false,
  },
]

const BrokerProfilePage = () => (
  <Routes>
    <Route
      element={
        <>
          <BrokerProfileHeader />
          <Outlet />
        </>
      }
    >
      <Route
        path={`/settings`}
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Company Profile</PageTitle>
            <Overview />
          </>
        }
      />
      <Route
        path={`/teams`}
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Team Members</PageTitle>
            <TeamMember
              className='card-xl-stretch mb-xl-8'
              chartColor='primary'
              chartHeight='200px'
            />
          </>
        }
      />
      {/* <Route
        path='pricing'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Pricing and Fees</PageTitle>
            <Prices />
          </>
        }
      /> */}
      <Route
        path={`/documents`}
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
            <Documents />
          </>
        }
      />
      <Route
        path={`/subscription`}
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Subscription</PageTitle>
            <MixedWidget7
              className='card-xl-stretch mb-xl-8'
              chartColor='primary'
              chartHeight='200px'
            />
          </>
        }
      />
      <Route index element={<Navigate to={`/${companyName}/setting/settings`} />} />
    </Route>
  </Routes>
)

export default BrokerProfilePage
