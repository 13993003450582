/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'

import {Field, Form, Formik, FormikValues} from 'formik'
import {createBrowserHistory} from 'history'
import {Card} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  AddTrip,
  TripByID,
  allUsers,
  checkBooking,
  getALLLegal,
  getAllBookingById,
  getAllBrokerUsers,
  getAllCompanyById,
  getAllVehicle,
} from '../../../modules/auth/core/_requests'
import {ICreateAccount, createAccountSchemas, inits} from './CreateAccountWizardHelper'

// import {getCSSVariableValue} from '../../../../assets/ts/_utils'
// import {Dropdown1} from '../../content/dropdown/Dropdown1'
// import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
//
function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.ceil((utc2 - utc1) / _MS_PER_DAY)
}
let diffDays = 0
let days = ''
const AddRentalNew: React.FC = ({}) => {
  const [team, setTeam] = useState<any>([])
  let id = localStorage.getItem('id') as string
  const getAllCustomerByIds = async () => {
    const data = await TripByID(id)
    setTeam(data.data.trips)
    console.log('data', data)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  const handleClick = (id: any) => {
    localStorage.setItem('id', id)
  }
  console.log(team)
  const browserHistory = createBrowserHistory()
  const handleRedirect = (id: any) => {
    const companyName = localStorage.getItem('companyName') as string
    browserHistory.push(`/${companyName}/vehicle/add-Rental`)
    window.location.reload()
    handleClick(id)
    // window.location.href('/vehicle/details/availability')
  }
  const defaultValue = {
    year: 2019,
    month: 10,
    day: 5,
  }

  const [selectedDay, setSelectedDay] = useState('')
  const [selectedEndDay, setSelectedEndDay] = useState('')

  const [data, setData] = useState<any>([])
  const [customer, setCustomer] = useState<any>([])
  const [selectedCustomer, setSelectedCustomer] = useState<any>([])
  const [broker, setBroker] = useState<any>([])
  const [legal, setLegal] = useState<any>([])
  const [company, setCompany] = useState<any>([])
  const [fee, setFee] = useState(0)
  const [taxFee, setTaxFee] = useState(0)
  const [salestax, setSalesTax] = useState(0)
  const [dataIntial, setDataIntial] = useState<ICreateAccount>(inits)
  const [serviceTax, setServiceTax] = useState(0)
  const [total, setTotal] = useState(0)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [loading, setLoading] = useState(false)
  const [hasBooked, setHasBooked] = useState(false)
  const [dailyPrice, setDailyPrice] = useState(0)
  const [adddon, setAddon] = useState(0)
  const [adddonVehicle, setAddonVehicle] = useState<any>([])
  const [initValues] = useState<ICreateAccount>(inits)

  const [value, onChange] = useState(new Date())

  // const [stat, setStatistics] = useState<any>(null)
  const [role, setRole] = useState('')
  const allCustomers = async () => {
    const data = await getAllVehicle()

    setData(data.data?.vehicles)
    const id = localStorage.getItem('id') as string
    data.data?.vehicles?.forEach((element: any) => {
      if (element._id === id) {
        initValues.dailyFee = parseInt(element.dailyPrice)
        console.log(element.dailyPrice)
        initValues.deposit = element.priceClassification
        setDailyPrice(parseInt(element.dailyPrice))
        setAddonVehicle(element.addon)
      }
    })
    const customer = await allUsers()
    setCustomer(customer.data.customers)
    const broker = await getAllBrokerUsers()
    setBroker(broker.data.customers)

    initValues.vehicle = id
    const roleUser = localStorage.getItem('user')
    if (data != null) {
      let dataTemp = JSON.parse(roleUser as string)
      setRole(dataTemp?.role)
    }
    const companyName = localStorage.getItem('companyName')
    const company = await getAllCompanyById(companyName)

    // console.log(data)
    setCompany(company.data.customer)
    const legal = await getALLLegal()
    console.log(legal)
    setLegal(legal.data.data)
    const booking = await getAllBookingById()

    console.log(booking.data.data[0].bookingFee)
    initValues.bookingfee = booking.data.data[0].bookingFee.toString()
  }
  useEffect(() => {
    allCustomers()
  }, [])
  console.log(customer)

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    // setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])
    let newValues = {
      selectedEndDay: dataIntial.selectedStartDate,
      selectedStartDate: dataIntial.selectedStartDate,
    }
    const data = await checkBooking(newValues)
    console.log(data)

    setHasBooked(data.data.bool)
    if (data.data.bool) {
      Swal.fire({
        timer: 3000,
        text: 'This Car is Already Book Between these dates',
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
    } else {
      values.fee = fee
      values.total = total
      values.dailyFee = dailyPrice
      values.salesTax = salestax
      values.serviceTax = serviceTax
      setLoading(true)
      // console.log(values)
      try {
        const {
          data: {data, message},
        } = await AddTrip(values)
        // console.log(data)
        Swal.fire({
          timer: 3000,
          text: message,
          icon: 'success',
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        })
        // actions.resetForm()
        browserHistory.push('/vehicle/manage')
        window.location.reload()
      } catch (error) {
        // console.error(error)
        Swal.fire({
          timer: 3000,
          text: 'Booking Not Added',
          icon: 'error',
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        })
        // setStatus('The Regsiter details are incorrect')
        // setSubmitting(false)
        setLoading(false)
      }
    }
  }

  const handleSelect = (event: any) => {
    console.log(event)
    setSelectedDay(event)
    var date = new Date(event.year, event.month, event.day)
    initValues.selectedStartDate = date.toLocaleDateString()
  }
  const handleSelectEnd = (event: any) => {
    console.log(event)
    setSelectedEndDay(event)
    var date = new Date(event.year, event.month, event.day)
    initValues.selectedEndDate = date.toLocaleDateString()
    // initValues.selectedEndDate = event.target.value
  }

  useEffect(() => {
    console.log('s', dataIntial)
    // dataIntial.bookingfee = company[0]?.prices?.bookingFee
    if (dataIntial?.selectedStartDate != '' && dataIntial?.selectedEndDate != '') {
      const date1 = new Date(dataIntial?.selectedStartDate)
      const date2 = new Date(dataIntial?.selectedEndDate)

      diffDays = dateDiffInDays(date1, date2)
      console.log(diffDays)

      const pay = dailyPrice * diffDays
      console.log(dataIntial.bookingfee)
      setFee(pay)
      if (diffDays != 0) {
        const val = pay + adddon
        let salesFee = (val * parseFloat(company[0]?.prices?.salesTax)) / 100
        setSalesTax(salesFee)
        console.log(salesFee)
        let serviceFee = (val * parseInt(company[0]?.prices?.serviceFee)) / 100
        setServiceTax(serviceFee)
        console.log(serviceFee)
        let tax = parseInt(dataIntial?.bookingfee) + serviceFee + salestax
        setTaxFee(tax)
        console.log(tax)
        console.log(company[0]?.prices?.serviceFee)
        setTotal(val + tax)
      }
    }
  }, [dataIntial, dailyPrice, adddon])
  const getIsbooked = async () => {}
  console.log(adddonVehicle)
  const handleAddon = (event: any) => {
    console.log(event.target.checked)
    if (event.target.checked) {
      setAddon(adddon + parseInt(event.target.value as string))
    } else {
      setAddon(adddon - parseInt(event.target.value as string))
    }
  }
  const handleCustomerSelected = (id: any) => {
    console.log('id', customer)
    let data = customer.filter((item: any) => item._id === id)
    setSelectedCustomer(data)
  }
  console.log('selectedCustomer', selectedCustomer)

  return (
    <div className='row g-5 g-xl-12  '>
      <div className='w-100 d-flex flex-row justify-content-end align-items-center mb-3'>
        <Link
          // onClick={() => handleClick(car._id)}
          to={`/customer/createCustomer`}
          className='btn btn-lg btn-primary mb-3'
        >
          Add Customer
        </Link>
      </div>
      <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
        {(values) => {
          setDataIntial(values.values)
          console.log(values.values)
          return (
            <Form className='' noValidate id='kt_create_account_form'>
              <div className='row mb-5'>
                <div className='col-sm-4 '>
                  <Card style={{borderRadius: '8px'}}>
                    <Card.Body>
                      <Card.Text className='text-muted'>
                        <h4 className='p-3'>Rental Details</h4>
                        <div className='fv-row'>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <label className='col-lg-12 col-form-label fw-bold fs-4'>
                                <span className=''>Selected Vehicle</span>
                              </label>
                              <Field
                                as='select'
                                name='vehicle'
                                value={values.values.vehicle}
                                className='form-select form-select-lg form-select-solid'
                              >
                                <option value=''>Choose Vehicle</option>
                                {data.map((item: any) => (
                                  <option value={item._id}>
                                    <div className='d-flex'>
                                      <img src={item.gallery[0]} alt='' />
                                      <p> {item.title}</p>
                                    </div>
                                  </option>
                                ))}
                                {/* <option value='2'>Honada</option>
              <option value='3'>Civic</option> */}
                              </Field>
                            </div>
                            <div className='col-lg-12'>
                              <label className='col-lg-12 col-form-label fw-bold fs-4'>
                                <span className=''>Selected Customer</span>
                              </label>
                              <Field
                                as='select'
                                name='customerId'
                                value={values.values.customerId}
                                onClick={() => handleCustomerSelected(values.values.customerId)}
                                className='form-select form-select-lg form-select-solid'
                              >
                                <option value=''>Choose Customer</option>
                                {customer.map((item: any) => (
                                  <option value={item._id}>{item.accountName}</option>
                                ))}
                                {/* <option value='2'>Honada</option>
              <option value='3'>Civic</option> */}
                              </Field>
                            </div>
                            <div className='mb-3 row'>
                              <div className='col-lg-12'>
                                <label className='col-lg-12 col-form-label fw-bold fs-4'>
                                  <span className=''> Selected Start Date </span>
                                </label>
                              </div>
                              <div className='col-lg-12'>
                                <Field
                                  type='date'
                                  // onChange={() => setSelectedDay(values.values.selectedStartDate)}
                                  value={values.values.selectedStartDate}
                                  className='form-control form-control-lg form-control-solid'
                                  name='selectedStartDate'
                                />
                              </div>
                            </div>{' '}
                            <div className='mb-3 row'>
                              <div className='col-lg-12'>
                                <label className='col-lg-12 col-form-label fw-bold fs-4'>
                                  <span className=''> Selected End Date </span>
                                </label>
                              </div>
                              <div className='col-lg-12'>
                                <Field
                                  // onChange={() => setSelectedEndDay(values.values.selectedEndDate)}
                                  type='date'
                                  value={values.values.selectedEndDate}
                                  className='form-control form-control-lg form-control-solid'
                                  name='selectedEndDate'
                                />
                              </div>
                            </div>{' '}
                            <label className='col-lg-12 col-form-label fw-bold fs-4'>
                              <span className=''> Daily Pricing</span>
                            </label>
                            <Field
                              type='number'
                              className='form-control form-control-lg form-control-solid'
                              name='dailyFee'
                              value={dailyPrice}
                              onChange={(event: any) =>
                                setDailyPrice(parseInt(event?.target.value))
                              }
                            />
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className='col-sm-8 '>
                  <Card style={{borderRadius: '8px'}}>
                    <Card.Body>
                      <Card.Text className='text-muted'>
                        {adddonVehicle.length > 0 && (
                          <div className='mb-5 mb-xl-10'>
                            <div className='mt-5'>
                              <h3> Additioal Services </h3>
                              <h5 className='text-muted'> Add additional serives to the rental</h5>
                              <p className='text-muted'>
                                {' '}
                                Select one or more products from the list below by ticking the
                                checkbox
                              </p>
                              <p className='text-muted'> Addons </p>
                              <div
                                className=' mb-3'
                                style={{
                                  padding: '1rem',
                                }}
                              >
                                {adddonVehicle.map((item: any) => (
                                  <div className='col-sm-12 col-xs-12'>
                                    <label className='form-check form-check-inline form-check-solid'>
                                      <input
                                        className='form-check-input'
                                        name='status'
                                        type='checkbox'
                                        value={item.price}
                                        onChange={handleAddon}
                                        // checked={{...formik.getFieldProps('status')}.value === 'Booked'}
                                        // defaultChecked={{...formik.getFieldProps('status')}.value === 'Booked'}
                                        // {...formik.getFieldProps('status')}
                                      />
                                      <span className='fw-bold ps-2 fs-4'>{item.title}</span>
                                      <p className='text-muted'>+${item.price}.00</p>
                                    </label>
                                  </div>
                                ))}
                                {/* <label className='form-check form-check-inline form-check-solid'>
                      <input
                        className='form-check-input'
                        name='status'
                        type='radio'
                        value={'1000'}
                        onChange={(event) => setAddon(parseInt(event.target.value))}
                        // checked={{...formik.getFieldProps('status')}.value === 'Booked'}
                        // defaultChecked={{...formik.getFieldProps('status')}.value === 'Booked'}
                        // {...formik.getFieldProps('status')}
                      />
                      <span className='fw-bold ps-2 fs-6'>Personal Driver (2 day)</span>
                      <p className='text-muted'>+$1000.00</p>
                    </label>
                    <label className='form-check form-check-inline form-check-solid'>
                      <input
                        className='form-check-input'
                        name='status'
                        type='radio'
                        value={'1500'}
                        onChange={(event) => setAddon(parseInt(event.target.value))}
                        // checked={{...formik.getFieldProps('status')}.value === 'Booked'}
                        // defaultChecked={{...formik.getFieldProps('status')}.value === 'Booked'}
                        // {...formik.getFieldProps('status')}
                      />
                      <span className='fw-bold ps-2 fs-6'>Personal Driver (3 day)</span>
                      <p className='text-muted'>+$1500.00</p>
                    </label> */}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='mb-9'>
                          <h3 className='mb-3 fs-4 '>Cost Breakdown</h3>
                          <div className='row gap-0 '>
                            <div className='col-lg-3 my-3  flex-center'>
                              <div
                                style={{
                                  padding: '1rem',
                                  border: '1px dashed #acafb7',
                                  borderRadius: '8px',
                                }}
                              >
                                <h3 className='text-center fs-4 text-info'> Daily Pricing</h3>
                                <Field
                                  className='text-center'
                                  type='number'
                                  name='dailyFee'
                                  style={{
                                    border: 'none',
                                    fontSize: '19px',
                                    fontWeight: 'bold',
                                    width: '95%',
                                  }}
                                  value={dailyPrice}
                                  onChange={(event: any) =>
                                    setDailyPrice(parseInt(event?.target.value))
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-lg-3 my-3  flex-center'>
                              <div
                                style={{
                                  padding: '1rem',
                                  border: '1px dashed #acafb7',
                                  borderRadius: '8px',
                                }}
                              >
                                <h3 className='text-center fs-4 text-success'>Total + Addons</h3>
                                <h2 className='text-center'>
                                  ${!Number.isNaN(fee + adddon) ? (fee + adddon).toFixed(2) : 0}
                                </h2>{' '}
                              </div>
                            </div>
                            <div className='col-lg-3 my-3   flex-center'>
                              <div
                                style={{
                                  padding: '1rem',
                                  border: '1px dashed #acafb7',
                                  borderRadius: '8px',
                                }}
                              >
                                <h3 className='text-center fs-4 text-danger'>Taxes & Fees</h3>
                                <h2 className='text-center'>
                                  ${!Number.isNaN(taxFee) ? taxFee.toFixed(2) : 0}
                                </h2>{' '}
                              </div>
                            </div>
                            <div className='col-lg-3 my-3  flex-center'>
                              <div
                                style={{
                                  padding: '1rem',
                                  border: '1px dashed #acafb7',
                                  borderRadius: '8px',
                                }}
                              >
                                <h3 className='text-center fs-4 text-primary'>Total</h3>
                                <h2 className='text-center'>
                                  ${!Number.isNaN(total) ? total.toFixed(2) : 0}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <div className='row p-5'>
                    {' '}
                    <Card style={{borderRadius: '8px'}}>
                      <Card.Body>
                        <Card.Text className='text-muted'>
                          <h4 className='p-3'>Customer Details</h4>
                          <div className=' row'>
                            <div className='col-lg-6'>
                              <div className='col-lg-12'>
                                <label className=' col-form-label fw-bold fs-6'>First Name</label>
                              </div>
                              <div className='col-lg-12'>
                                <Field
                                  type='text'
                                  value={selectedCustomer[0]?.firstName}
                                  className='form-control form-control-lg form-control-solid'
                                  // name='feature'
                                />
                              </div>
                            </div>{' '}
                            <div className='col-lg-6'>
                              <div className='col-lg-12'>
                                <label className=' col-form-label fw-bold fs-6'>Last Name</label>
                              </div>
                              <div className='col-lg-12'>
                                <Field
                                  type='text'
                                  value={selectedCustomer[0]?.lastName}
                                  className='form-control form-control-lg form-control-solid'
                                  // name='feature'
                                />
                              </div>
                            </div>
                          </div>
                          <div className=' row'>
                            <div className='col-lg-12'>
                              <label className=' col-form-label fw-bold fs-6'>Email</label>
                            </div>
                            <div className='col-lg-12'>
                              <Field
                                type='text'
                                value={selectedCustomer[0]?.email}
                                className='form-control form-control-lg form-control-solid'
                                //   name='feature'
                              />
                            </div>
                          </div>{' '}
                          <div className='col-lg-12'>
                            <label className=' col-form-label fw-bold fs-6'>Phone </label>
                          </div>
                          <div className='col-lg-12'>
                            <Field
                              type='text'
                              value={selectedCustomer[0]?.phone}
                              className='form-control form-control-lg form-control-solid'
                              // name='feature'
                            />
                          </div>
                          <div className='mb-3 row'>
                            <div className='col-lg-12'>
                              <label className=' col-form-label fw-bold fs-6'>Country </label>
                            </div>
                            <div className='col-lg-12'>
                              <Field
                                type='text'
                                value={selectedCustomer[0]?.country}
                                className='form-control form-control-lg form-control-solid'
                                name='feature'
                              />
                            </div>
                          </div>{' '}
                        </Card.Text>
                      </Card.Body>
                    </Card>{' '}
                  </div>
                  <div className='d-flex justify-content-end pt-10'>
                    <div className='mr-2'>
                      <button
                        // onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light  text-dark me-3'
                        data-kt-stepper-action='previous'
                      >
                        Cancel Booking
                      </button>
                    </div>

                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={hasBooked || loading}
                      >
                        {!loading && <span className='indicator-label'>Book Now</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                        {/* <span className='indicator-label'>Book Now</span> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {AddRentalNew}
