import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


export function WelcomeScreen() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  return (
      <div className='d-flex flex-column  bgi-size-cover justify-content-center align-items-center p-10'>
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center p-10'>
          {/* begin::Logo */}
          <img alt='Logo' src={toAbsoluteUrl('/media/logo.png')} className='h-40px mb-5' />
          {/* end::Logo */}

          {/* begin::Title */}

            <h3 className='text-white text-center mb-7 text-dark' style={{fontSize: '30px'}}>
   Welcome Aboard
          </h3>
                    <p className='form-label fw-bolder text-dark fs-6 mb-0 text-muted text-center mb-3' style={{fontSize: '30px'}}>
            Plan your blog post by choosing a topic creating <br></br>an outline and checking facts
          </p>
          {/* end::Title */}
 <Link
         to={"/dashboard"}
          className='btn btn-primary mb-5'
        
        >
        Visit Dashboard

        </Link>
          {/* begin::Text */}
          {/* end::Text */}
        <img alt='Logo' src={toAbsoluteUrl('/media/S_confirmation.svg')} />
        </div>
        {/* <div
          className='d-flex w-100 h-50 bgi-size-cover bgi-position-center'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/logos/s_present.png')})`}}
        ></div> */}
        {/* end::Content */}
      </div>
  )
}
