import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {CreateRental} from '../pages/vehicle/CreateRentalEmbeded'
import {EmailVerificationPaument} from '../pages/companyRegistration/EmailPageCompany'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {VerifyScreen} from '../pages/dashboard/Overview/verify'
// import {VerifyAccount} from './components/verifyAccount'
const stripePromise = loadStripe(
  'pk_test_51MeUlmEYhaMiCTEsWfeEuXBFcEWwtBTRZtgr2PAHRZby8D2Idm3opi7pmBV4tbVoVj3mROnIY55sZBIk4spbCLLC00cWlQiJKZ'
)

const {PUBLIC_URL} = process.env
const companyName = localStorage.getItem('companyName') as string
const AppRoutes: FC = () => {
  const [user, setUser] = useState('')
  const [authenticated, setAuthenticated] = useState(() => {
    return localStorage.getItem('auth_token') ? true : false
  })
  useEffect(() => {
    const loginUser = localStorage.getItem('user')
    console.log(loginUser)
    if (loginUser != null) {
      let dataTemp = JSON.parse(loginUser)
      setUser(dataTemp.role)
    }
  }, [])
  // const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='verify' element={<VerifyScreen />} />
          <Route path='createRental' element={<CreateRental />} />

          <Route
            path={`${companyName}/verifyPayment/:id`}
            element={
              <Elements stripe={stripePromise}>
                <EmailVerificationPaument />
              </Elements>
            }
          />

          {authenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              {user === 'Admin' ? (
                <Route index element={<Navigate to='/company/user' />} />
              ) : (
                <Route index element={<Navigate to={`${companyName}/dashboard`} />} />
              )}
            </>
          ) : (
            <>
              <Route path='/*' element={<AuthPage />} />
              {/* <Route path='/*' element={<Navigate to='/' />} /> */}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
