import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
type Props = {
  values: any
}
const Step4: FC<Props> = ({values}) => {
  console.log(values)
  return (
    <div className='w-100'>
      <div className='text-gray-400 fw-bold fs-6  mb-5' style={{textAlign: 'right'}}>
        Having Issues?
        <a href='/dashboard' className='link-primary fw-bolder'>
          {' '}
          Get Help
        </a>
        .
      </div>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Billing Details</h2>
      </div>
      {/* <div className='d-flex flex-column mb-7 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>Name On Card</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify a card holder's name"
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-solid'
          placeholder=''
          name='nameOnCard'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='nameOnCard' />
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>

        <div className='position-relative'>
          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder='Enter card number'
            name='cardNumber'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='cardNumber' />
          </div>

          <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
            <img src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')} alt='' className='h-25px' />
            <img
              src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
              alt=''
              className='h-25px'
            />
            <img
              src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
              alt=''
              className='h-25px'
            />
          </div>
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-md-8 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>Expiration Date</label>

          <div className='row fv-row'>
            <div className='col-6'>
              <Field as='select' name='cardExpiryMonth' className='form-select form-select-solid'>
                <option></option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='11'>11</option>
                <option value='12'>12</option>
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='cardExpiryMonth' />
              </div>
            </div>

            <div className='col-6'>
              <Field as='select' name='cardExpiryYear' className='form-select form-select-solid'>
                <option></option>
                <option value='2021'>2021</option>
                <option value='2022'>2022</option>
                <option value='2023'>2023</option>
                <option value='2024'>2024</option>
                <option value='2025'>2025</option>
                <option value='2026'>2026</option>
                <option value='2027'>2027</option>
                <option value='2028'>2028</option>
                <option value='2029'>2029</option>
                <option value='2030'>2030</option>
                <option value='2031'>2031</option>
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='cardExpiryYear' />
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4 fv-row'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>CVV</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Enter a card CVV code'
            ></i>
          </label>

          <div className='position-relative'>
            <Field
              type='text'
              className='form-control form-control-solid'
              minLength={3}
              maxLength={4}
              placeholder='CVV'
              name='cardCvv'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='cardCvv' />
            </div>

            <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
              <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-2hx' />
            </div>
          </div>
        </div>
      </div> */}
      <CardElement
        className='card form-control form-control-lg form-control-solid'
        options={{
          style: {
            base: {
              backgroundColor: 'white',
            },
          },
        }}
      />
      {/* 
      <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>
      <CardNumberElement className='form-control form-control-lg form-control-solid' />
      <label className='required fs-6 fw-bold form-label mb-2'>Expiration Date</label>
      <CardExpiryElement className='form-control form-control-lg form-control-solid' />
      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
        <span className='required'>CVV</span>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Enter a card CVV code'
        ></i>
      </label>
      <CardCvcElement className='form-control form-control-lg form-control-solid' /> */}
      <div className='d-flex flex-stack'>
        <div className='me-5'>
          <label className='fs-6 fw-bold form-label'>
            You will be charged{' '}
            {values.values.payment === 'personal'
              ? '$99.00'
              : values.values.payment === 'bussiness'
              ? '$199.00'
              : '$999.00'}
            &nbsp; today
          </label>
          <div className='fs-7 fw-bold text-gray-400'>
            Your subscription will renew montly at{' '}
            <a className='text-primary fw-bolder'>
              {values.values.payment === 'personal'
                ? '$99.00'
                : values.values.payment === 'bussiness'
                ? '$199.00'
                : '$999.00'}
            </a>
          </div>
        </div>
      </div>
      <div className='form-check form-check-custom form-check-solid  mt-3'>
        <input className='form-check-input' type='checkbox' required />
        <span className='fs-7 fw-bold text-gray-400'>
          &nbsp;I Agree to Corsa Dash
          <a className='text-primary fw-bolder'>
            Terms & Conditions, Privay Policy and Refund Policy
          </a>{' '}
        </span>
      </div>
    </div>
  )
}

export {Step4}
