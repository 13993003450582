import {FC, useEffect, useState} from 'react'
import {
  AddBooking,
  AddTrip,
  getALLLegal,
  getAllBookingById,
  getAllCompanyUsers,
  getAllCustomerById,
  updateBooking,
} from '../../modules/auth/core/_requests'

import {Button, Modal} from 'react-bootstrap'
import {CustomerTable} from '../dashboard/Overview/companyTable'
import Swal from 'sweetalert2'

const Overview: FC = () => {
  const [company, setCompany] = useState<any>([])

  const [loading, setLoading] = useState(false)
  let [bookingFees, setBookingFee] = useState('')
  let [activePageTripe, setActivePageTrips] = useState(0)
  let [updated, setUpdated] = useState(false)
  const getAllCustomerStatistics = async () => {
    const legal = await getALLLegal()
    console.log(legal)

    try {
      const companies = await getAllCompanyUsers()
      console.log(companies)
      setCompany(companies.data.customers)

      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAllCustomerStatistics()
  }, [])
  console.log('s', company)
  const [showCreateRentalModal, setShowCreateRentalModal] = useState(false)
  const openCreateRentalModal = (element: any) => {
    setShowCreateRentalModal(true)
  }
  const closeCreateRentalModalPay = () => {
    setShowCreateRentalModal(false)
  }
  const [user, setUser] = useState<any | null>(null)
  useEffect(() => {
    const data = localStorage.getItem('user')
    if (data != null) {
      let dataTemp = JSON.parse(data)

      setUser(dataTemp)
    }
  }, [])

  const [booking, setBooking] = useState('')

  const getAllCustomerByIds = async () => {
    const data = await getAllBookingById()

    console.log(data.data.data[0].bookingFee)
    setBookingFee(data.data.data[0].bookingFee.toString())

    setBooking(data.data.data[0]._id)
  }
  useEffect(() => {
    getAllCustomerByIds()
    getAllCustomerStatistics()
  }, [showCreateRentalModal])
  const addBooking = async () => {
    // setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    setLoading(true)
    let values = {bookingFee: bookingFees}
    // console.log(values)
    try {
      const {
        data: {data, message},
      } = await AddBooking(values)
      // console.log(data)
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // actions.resetForm()
      // browserHistory.push('/vehicle/manage')
      // window.location.reload()
      closeCreateRentalModalPay()
    } catch (error) {
      // console.error(error)
      Swal.fire({
        timer: 3000,
        text: 'Booking Fee Not Added',
        icon: 'error',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
      setLoading(false)
    }
  }
  const editBooking = async () => {
    // setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    setLoading(true)
    let values = {bookingFee: bookingFees}
    console.log(booking)
    try {
      const {
        data: {data, message},
      } = await updateBooking(booking, values)
      // console.log(data)
      Swal.fire({
        timer: 3000,
        text: message,
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // actions.resetForm()
      // browserHistory.push('/vehicle/manage')
      // window.location.reload()
      closeCreateRentalModalPay()
    } catch (error) {
      // console.error(error)
      Swal.fire({
        timer: 3000,
        text: 'Booking Fee Not Added',
        icon: 'error',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
      // setStatus('The Regsiter details are incorrect')
      // setSubmitting(false)
      setLoading(false)
    }
  }

  return (
    <>
      <Button onClick={openCreateRentalModal} className='mb-3'>
        Add Booking Fee
      </Button>
      <CustomerTable className='mb-5 mb-xl-8' data={company} loading={loading}></CustomerTable>
      {showCreateRentalModal && (
        <Modal show={showCreateRentalModal} onHide={closeCreateRentalModalPay} size='lg' centered>
          <Modal.Body>
            <div className='w-100'>
              <label className='fs-3 mb-3'>Booking Fee</label>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid p-4 fs-2'
                style={{border: '1px solid'}}
                name='bookingFee'
                onChange={(event) => setBookingFee(event.target.value as string)}
                value={bookingFees}
              />
              {/* <PayNow /> */}
              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={closeCreateRentalModalPay}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    Cancel
                  </button>
                </div>

                <div>
                  {bookingFees === '' ? (
                    <button
                      type='button'
                      onClick={addBooking}
                      className='btn btn-lg btn-primary me-3'
                      disabled={loading}
                    >
                      {!loading && <span className='indicator-label'>Add </span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                      {/* <span className='indicator-label'>Book Now</span> */}
                    </button>
                  ) : (
                    <button
                      type='button'
                      onClick={editBooking}
                      className='btn btn-lg btn-primary me-3'
                      disabled={loading}
                    >
                      {!loading && <span className='indicator-label'>Edit </span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                      {/* <span className='indicator-label'>Book Now</span> */}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default Overview
