import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {ICreateAccount, createAccountSchemas, inits} from './CreateAccountWizardHelper'
import {Step6} from './steps/Step6'
import {
  AddTrip,
  AddTripEmbeded,
  AddVehicle,
  allUsers,
  getAllBrokerUsers,
  getallEmbed,
  getAllVehicle,
} from '../../../modules/auth/core/_requests'
import Swal from 'sweetalert2'
import {createBrowserHistory} from 'history'

const CreateRental = () => {
  const [data, setData] = useState<any>([])
  const [customer, setCustomer] = useState<any>([])
  const [broker, setBroker] = useState<any>([])
  const [fee, setFee] = useState('')
  const [total, setTotal] = useState(0)

  // const [stat, setStatistics] = useState<any>(null)
  const [role, setRole] = useState('')
  const href = window.location.href.split('&')
  console.log(href)

  const allCustomers = async () => {
    const values = {
      companySite: href[0],
    }
    const data = await getallEmbed(values)
    setData(data.data?.vehicles)
    href[1] = href[1].replace('%20', ' ')
    const current = data.data?.vehicles.filter((item: any) => item.vehicleType === href[1])
    console.log(current)
    initValues.vehicle = current[0]._id
    const customer = await allUsers()
    setCustomer(customer.data.customers)
    const broker = await getAllBrokerUsers()
    setBroker(broker.data.customers)

    const roleUser = localStorage.getItem('user')
    if (data != null) {
      let dataTemp = JSON.parse(roleUser as string)
      setRole(dataTemp?.role)
    }
  }
  useEffect(() => {
    allCustomers()
  }, [])
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [loading, setLoading] = useState(false)
  const browserHistory = createBrowserHistory()
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
      // console.log(stepper.current.currentStepIndex)
      if (stepper.current.currentStepIndex === 4) {
        values.fee = fee
        values.total = total
        values.companySite = href[0]
        setLoading(true)
        // console.log(values)
        try {
          const {
            data: {data, message},
          } = await AddTripEmbeded(values)
          // console.log(data)
          Swal.fire({
            timer: 3000,
            text: message,
            icon: 'success',
            showCloseButton: false,
            showConfirmButton: false,
            showDenyButton: false,
          })
          // actions.resetForm()
          // browserHistory.push('/vehicle/manage')
          // window.location.reload()
        } catch (error) {
          // console.error(error)
          Swal.fire({
            timer: 3000,
            text: 'Booking Not Added',
            icon: 'success',
            showCloseButton: false,
            showConfirmButton: false,
            showDenyButton: false,
          })
          // setStatus('The Regsiter details are incorrect')
          // setSubmitting(false)
          setLoading(false)
        }
      }
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Select Vehicle</h3>

                  {/* <div className='stepper-desc fw-semibold'>2023 Lamborghini Urus</div> */}
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Rental Dates</h3>
                  <div className='stepper-desc fw-semibold'>Select the rental dates</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Projected Costs</h3>
                  <div className='stepper-desc fw-semibold'>See the cost for this booking</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 6*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Completed</h3>
                  <div className='stepper-desc fw-semibold'>Confirm your booking</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 6*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {(values) => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 data={data} values={values} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 customer={customer} broker={broker} role={role} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3
                  values={values}
                  data={data}
                  setFee={setFee}
                  fee={fee}
                  setTotal={setTotal}
                  total={total}
                />
              </div>

              {/* <div data-kt-stepper-element='content'>
                <Step4 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 />
              </div> */}

              <div data-kt-stepper-element='content'>
                <Step6 />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totatStepsNumber! - 1 && 'Next'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 1 && 'Submit'}

                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {CreateRental}
