/* eslint-disable use-isnan */
import {Field, Form, Formik, FormikValues} from 'formik'
import {createBrowserHistory} from 'history'
import {useEffect, useRef, useState} from 'react'
import {Button} from 'react-bootstrap'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  AddTrip,
  allUsers,
  checkBooking,
  getALLLegal,
  getAllBookingById,
  getAllBrokerUsers,
  getAllCompanyById,
  getAllVehicle,
} from '../../../modules/auth/core/_requests'
import {ICreateAccount, createAccountSchemas, inits} from './CreateAccountWizardHelper'
import {Calendar} from '@hassanmojab/react-modern-calendar-datepicker'

function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.ceil((utc2 - utc1) / _MS_PER_DAY)
}
let diffDays = 0
let days = ''
const CreateRental = () => {
  const defaultValue = {
    year: 2019,
    month: 10,
    day: 5,
  }

  const [selectedDay, setSelectedDay] = useState(defaultValue)
  const [selectedEndDay, setSelectedEndDay] = useState(defaultValue)
  const browserHistory = createBrowserHistory()
  const [data, setData] = useState<any>([])
  const [customer, setCustomer] = useState<any>([])
  const [broker, setBroker] = useState<any>([])
  const [legal, setLegal] = useState<any>([])
  const [company, setCompany] = useState<any>([])
  const [fee, setFee] = useState(0)
  const [taxFee, setTaxFee] = useState(0)
  const [salestax, setSalesTax] = useState(0)
  const [serviceTax, setServiceTax] = useState(0)
  const [total, setTotal] = useState(0)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [loading, setLoading] = useState(false)
  const [hasBooked, setHasBooked] = useState(false)
  const [dailyPrice, setDailyPrice] = useState(0)
  const [adddon, setAddon] = useState(0)
  const [adddonVehicle, setAddonVehicle] = useState<any>([])
  const [initValues] = useState<ICreateAccount>(inits)

  const [value, onChange] = useState(new Date())

  // const [stat, setStatistics] = useState<any>(null)
  const [role, setRole] = useState('')
  const allCustomers = async () => {
    const data = await getAllVehicle()

    setData(data.data?.vehicles)
    const id = localStorage.getItem('id') as string
    data.data?.vehicles?.forEach((element: any) => {
      if (element._id === id) {
        initValues.dailyFee = parseInt(element.dailyPrice)
        console.log(element.dailyPrice)
        initValues.deposit = element.priceClassification
        setDailyPrice(parseInt(element.dailyPrice))
        setAddonVehicle(element.addon)
      }
    })
    const customer = await allUsers()
    setCustomer(customer.data.customers)
    const broker = await getAllBrokerUsers()
    setBroker(broker.data.customers)

    initValues.vehicle = id
    const roleUser = localStorage.getItem('user')
    if (data != null) {
      let dataTemp = JSON.parse(roleUser as string)
      setRole(dataTemp?.role)
    }
    const companyName = localStorage.getItem('companyName')
    const company = await getAllCompanyById(companyName)

    // console.log(data)
    setCompany(company.data.customer)
    const legal = await getALLLegal()
    console.log(legal)
    setLegal(legal.data.data)
    const booking = await getAllBookingById()

    console.log(booking.data.data[0].bookingFee)
    initValues.bookingfee = booking.data.data[0].bookingFee.toString()
  }
  useEffect(() => {
    allCustomers()
  }, [])
  console.log(customer)
  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    // setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])
    let newValues = {
      selectedEndDay: initValues.selectedStartDate,
      selectedStartDate: initValues.selectedStartDate,
    }
    const data = await checkBooking(newValues)
    console.log(data)

    setHasBooked(data.data.bool)
    if (data.data.bool) {
      Swal.fire({
        timer: 3000,
        text: 'This Car is Already Book Between these dates',
        icon: 'success',
        showCloseButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      })
    } else {
      values.fee = fee
      values.total = total
      values.dailyFee = dailyPrice
      values.salesTax = salestax
      values.serviceTax = serviceTax
      setLoading(true)
      // console.log(values)
      try {
        const {
          data: {data, message},
        } = await AddTrip(values)
        // console.log(data)
        Swal.fire({
          timer: 3000,
          text: message,
          icon: 'success',
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        })
        // actions.resetForm()
        browserHistory.push('/vehicle/manage')
        window.location.reload()
      } catch (error) {
        // console.error(error)
        Swal.fire({
          timer: 3000,
          text: 'Booking Not Added',
          icon: 'error',
          showCloseButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        })
        // setStatus('The Regsiter details are incorrect')
        // setSubmitting(false)
        setLoading(false)
      }
    }
  }

  const handleSelect = (event: any) => {
    console.log(event)
    setSelectedDay(event)
    var date = new Date(event.year, event.month, event.day)
    initValues.selectedStartDate = date.toLocaleDateString()
  }
  const handleSelectEnd = (event: any) => {
    console.log(event)
    setSelectedEndDay(event)
    var date = new Date(event.year, event.month, event.day)
    initValues.selectedEndDate = date.toLocaleDateString()
    // initValues.selectedEndDate = event.target.value
  }

  useEffect(() => {
    console.log(company[0]?.prices?.bookingFee)
    // initValues.bookingfee = company[0]?.prices?.bookingFee
    if (initValues?.selectedStartDate != '' && initValues?.selectedEndDate != '') {
      const date1 = new Date(initValues?.selectedStartDate)
      const date2 = new Date(initValues?.selectedEndDate)

      diffDays = dateDiffInDays(date1, date2)
      console.log(diffDays)

      const pay = dailyPrice * diffDays
      console.log(initValues.bookingfee)
      setFee(pay)
      if (diffDays != 0) {
        const val = pay + adddon
        let salesFee = (val * parseFloat(company[0]?.prices?.salesTax)) / 100
        setSalesTax(salesFee)
        console.log(salesFee)
        let serviceFee = (val * parseInt(company[0]?.prices?.serviceFee)) / 100
        setServiceTax(serviceFee)
        console.log(serviceFee)
        let tax = parseInt(initValues?.bookingfee) + serviceFee + salestax
        setTaxFee(tax)
        console.log(tax)
        console.log(company[0]?.prices?.serviceFee)
        setTotal(val + tax)
      }
    }
  }, [selectedDay, selectedEndDay, dailyPrice, adddon])
  const getIsbooked = async () => {}
  console.log(adddonVehicle)
  const handleAddon = (event: any) => {
    console.log(event.target.checked)
    if (event.target.checked) {
      setAddon(adddon + parseInt(event.target.value as string))
    } else {
      setAddon(adddon - parseInt(event.target.value as string))
    }
  }

  return (
    <>
      <div className='w-100 d-flex flex-row justify-content-end align-items-center mb-3'>
        <Link
          // onClick={() => handleClick(car._id)}
          to={`/customer/createCustomer`}
          className='btn btn-lg btn-primary mb-3'
        >
          Add Customer
        </Link>
      </div>

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {(values) => (
            <Form className='py-20 w-100 w-xl-900px px-9' noValidate id='kt_create_account_form'>
              <div className='fv-row'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <label className='col-lg-4 col-form-label fw-bold fs-4'>
                      <span className=''>Selected Vehicle</span>
                    </label>
                    <Field
                      as='select'
                      name='vehicle'
                      value={values.values.vehicle}
                      className='form-select form-select-lg form-select-solid'
                    >
                      <option value=''>Choose Vehicle</option>
                      {data.map((item: any) => (
                        <option value={item._id}>
                          <div className='d-flex'>
                            <img src={item.gallery[0]} alt='' />
                            <p> {item.title}</p>
                          </div>
                        </option>
                      ))}
                      {/* <option value='2'>Honada</option>
              <option value='3'>Civic</option> */}
                    </Field>
                  </div>
                  <div className='col-lg-12'>
                    <label className='col-lg-4 col-form-label fw-bold fs-4'>
                      <span className=''>Selected Customer</span>
                    </label>
                    <Field
                      as='select'
                      name='customerId'
                      value={values.values.customerId}
                      className='form-select form-select-lg form-select-solid'
                    >
                      <option value=''>Choose Customer</option>
                      {customer.map((item: any) => (
                        <option value={item._id}>{item.accountName}</option>
                      ))}
                      {/* <option value='2'>Honada</option>
              <option value='3'>Civic</option> */}
                    </Field>
                  </div>
                </div>
              </div>
              <div className='row mb-9'>
                <h4 className='mb-3 mt-7'>Select Rental Date and Time</h4>
                <div className='col-lg-6'>
                  {' '}
                  {/* onChange={setSelectedDay} */}
                  <Calendar
                    value={selectedDay}
                    shouldHighlightWeekends
                    onChange={handleSelect}
                    calendarClassName='responsive-calendar'
                  />
                  <div className='col-md-10 col-sm-12'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      {/* <span className=''>Select Time</span> */}
                    </label>
                    <Field
                      as='select'
                      name='seletedStartTime'
                      className='form-select form-select-lg form-select-solid'
                    >
                      <option value=''>Choose Time</option>
                      <option value='12:00 AM'>12:00 AM</option>
                      <option value='12:30 AM'>12:30 AM</option>
                      <option value='1:00 AM'>1:00 AM</option>
                      <option value='1:30 AM'>1:30 AM</option>
                      <option value='2:00 AM'>2:00 AM</option>
                      <option value='2:30 AM'>2:30 AM</option>
                      <option value='3:00 AM'>3:00 AM</option>
                      <option value='3:30 AM'>3:30 AM</option>
                      <option value='4:00 AM'>4:00 AM</option>
                      <option value='4:30 AM'>4:30 AM</option>
                      <option value='5:00 AM'>5:00 AM</option>
                      <option value='5:30 AM'>5:30 AM</option>
                      <option value='6:00 AM'>6:00 AM</option>
                      <option value='6:30 AM'>6:30 AM</option>
                      <option value='7:00 AM'>7:00 AM</option>
                      <option value='8:00 AM'>8:00 AM</option>
                      <option value='9:00 AM'>9:00 AM</option>
                      <option value='10:00 AM'>10:00 AM</option>
                      <option value='11:00 AM'>11:00 AM</option>
                      <option value='12:00 AM'>12:00 AM</option>

                      <option value='12:00 PM'>12:00 PM</option>
                      <option value='12:30 PM'>12:30 PM</option>
                      <option value='1:00 PM'>1:00 PM</option>
                      <option value='1:30 PM'>1:30 PM</option>
                      <option value='2:00 PM'>2:00 PM</option>
                      <option value='2:30 PM'>2:30 PM</option>
                      <option value='3:00 PM'>3:00 PM</option>
                      <option value='3:30 PM'>3:30 PM</option>
                      <option value='4:00 PM'>4:00 PM</option>
                      <option value='4:30 PM'>4:30 PM</option>
                      <option value='5:00 PM'>5:00 PM</option>
                      <option value='5:30 PM'>5:30 PM</option>
                      <option value='6:00 PM'>6:00 PM</option>
                      <option value='6:30 PM'>6:30 PM</option>
                      <option value='7:00 PM'>7:00 PM</option>
                      <option value='8:00 PM'>8:00 PM</option>
                      <option value='9:00 PM'>9:00 PM</option>
                      <option value='10:00 PM'>10:00 PM</option>
                      <option value='11:00 PM'>11:00 PM</option>
                      <option value='12:00 PM'>12:00 PM</option>
                    </Field>
                  </div>
                </div>
                <div className='col-lg-6'>
                  {' '}
                  <Calendar
                    value={selectedEndDay}
                    shouldHighlightWeekends
                    onChange={handleSelectEnd}
                  />
                  <div className='col-md-10 col-sm-12'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      {/* <span className=''>Select Time</span> */}
                    </label>
                    <Field
                      as='select'
                      name='seletedEndTime'
                      className='form-select form-select-lg form-select-solid'
                    >
                      <option value=''>Choose Time</option>
                      <option value='12:00 AM'>12:00 AM</option>
                      <option value='12:30 AM'>12:30 AM</option>
                      <option value='1:00 AM'>1:00 AM</option>
                      <option value='1:30 AM'>1:30 AM</option>
                      <option value='2:00 AM'>2:00 AM</option>
                      <option value='2:30 AM'>2:30 AM</option>
                      <option value='3:00 AM'>3:00 AM</option>
                      <option value='3:30 AM'>3:30 AM</option>
                      <option value='4:00 AM'>4:00 AM</option>
                      <option value='4:30 AM'>4:30 AM</option>
                      <option value='5:00 AM'>5:00 AM</option>
                      <option value='5:30 AM'>5:30 AM</option>
                      <option value='6:00 AM'>6:00 AM</option>
                      <option value='6:30 AM'>6:30 AM</option>
                      <option value='7:00 AM'>7:00 AM</option>
                      <option value='8:00 AM'>8:00 AM</option>
                      <option value='9:00 AM'>9:00 AM</option>
                      <option value='10:00 AM'>10:00 AM</option>
                      <option value='11:00 AM'>11:00 AM</option>
                      <option value='12:00 AM'>12:00 AM</option>

                      <option value='12:00 PM'>12:00 PM</option>
                      <option value='12:30 PM'>12:30 PM</option>
                      <option value='1:00 PM'>1:00 PM</option>
                      <option value='1:30 PM'>1:30 PM</option>
                      <option value='2:00 PM'>2:00 PM</option>
                      <option value='2:30 PM'>2:30 PM</option>
                      <option value='3:00 PM'>3:00 PM</option>
                      <option value='3:30 PM'>3:30 PM</option>
                      <option value='4:00 PM'>4:00 PM</option>
                      <option value='4:30 PM'>4:30 PM</option>
                      <option value='5:00 PM'>5:00 PM</option>
                      <option value='5:30 PM'>5:30 PM</option>
                      <option value='6:00 PM'>6:00 PM</option>
                      <option value='6:30 PM'>6:30 PM</option>
                      <option value='7:00 PM'>7:00 PM</option>
                      <option value='8:00 PM'>8:00 PM</option>
                      <option value='9:00 PM'>9:00 PM</option>
                      <option value='10:00 PM'>10:00 PM</option>
                      <option value='11:00 PM'>11:00 PM</option>
                      <option value='12:00 PM'>12:00 PM</option>
                    </Field>
                  </div>
                </div>
              </div>

              {adddonVehicle.length > 0 && (
                <div className='mb-5 mb-xl-10'>
                  <div className='mt-5'>
                    <h1> Addons (Optional)</h1>
                    <div
                      className=' mb-3'
                      style={{
                        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                        padding: '1rem',
                      }}
                    >
                      {adddonVehicle.map((item: any) => (
                        <div className='col-sm-4 col-xs-12'>
                          <label className='form-check form-check-inline form-check-solid'>
                            <input
                              className='form-check-input'
                              name='status'
                              type='checkbox'
                              value={item.price}
                              onChange={handleAddon}
                              // checked={{...formik.getFieldProps('status')}.value === 'Booked'}
                              // defaultChecked={{...formik.getFieldProps('status')}.value === 'Booked'}
                              // {...formik.getFieldProps('status')}
                            />
                            <span className='fw-bold ps-2 fs-4'>{item.title}</span>
                            <p className='text-muted'>+${item.price}.00</p>
                          </label>
                        </div>
                      ))}
                      {/* <label className='form-check form-check-inline form-check-solid'>
                      <input
                        className='form-check-input'
                        name='status'
                        type='radio'
                        value={'1000'}
                        onChange={(event) => setAddon(parseInt(event.target.value))}
                        // checked={{...formik.getFieldProps('status')}.value === 'Booked'}
                        // defaultChecked={{...formik.getFieldProps('status')}.value === 'Booked'}
                        // {...formik.getFieldProps('status')}
                      />
                      <span className='fw-bold ps-2 fs-6'>Personal Driver (2 day)</span>
                      <p className='text-muted'>+$1000.00</p>
                    </label>
                    <label className='form-check form-check-inline form-check-solid'>
                      <input
                        className='form-check-input'
                        name='status'
                        type='radio'
                        value={'1500'}
                        onChange={(event) => setAddon(parseInt(event.target.value))}
                        // checked={{...formik.getFieldProps('status')}.value === 'Booked'}
                        // defaultChecked={{...formik.getFieldProps('status')}.value === 'Booked'}
                        // {...formik.getFieldProps('status')}
                      />
                      <span className='fw-bold ps-2 fs-6'>Personal Driver (3 day)</span>
                      <p className='text-muted'>+$1500.00</p>
                    </label> */}
                    </div>
                  </div>
                </div>
              )}
              <div className='mb-9'>
                <h3 className='mb-3 fs-4 '>Cost Breakdown</h3>
                <div className='row gap-0 '>
                  <div className='col-lg-3 my-3  flex-center'>
                    <div
                      style={{
                        padding: '1rem',
                        border: '1px dashed #acafb7',
                        borderRadius: '8px',
                      }}
                    >
                      <h3 className='text-center fs-4 text-info'> Daily Pricing</h3>
                      <Field
                        className='text-center'
                        type='number'
                        name='dailyFee'
                        style={{border: 'none', fontSize: '19px', fontWeight: 'bold', width: '95%'}}
                        value={dailyPrice}
                        onChange={(event: any) => setDailyPrice(parseInt(event?.target.value))}
                      />
                    </div>
                  </div>
                  <div className='col-lg-3 my-3 flex-center'>
                    <div
                      style={{
                        padding: '1rem',
                        border: '1px dashed #acafb7',
                        borderRadius: '8px',
                      }}
                    >
                      <h3 className='text-center fs-4 text-success'>Total + Addons</h3>
                      <h2 className='text-center'>
                        ${!Number.isNaN(fee + adddon) ? (fee + adddon).toFixed(2) : 0}
                      </h2>{' '}
                    </div>
                  </div>
                  <div className='col-lg-3 my-3  flex-center'>
                    <div
                      style={{
                        padding: '1rem',
                        border: '1px dashed #acafb7',
                        borderRadius: '8px',
                      }}
                    >
                      <h3 className='text-center fs-4 text-danger'>Taxes & Fees</h3>
                      <h2 className='text-center'>
                        ${!Number.isNaN(taxFee) ? taxFee.toFixed(2) : 0}
                      </h2>{' '}
                    </div>
                  </div>
                  <div className='col-lg-3 my-3 flex-center'>
                    <div
                      style={{
                        padding: '1rem',
                        border: '1px dashed #acafb7',
                        borderRadius: '8px',
                      }}
                    >
                      <h3 className='text-center fs-4 text-primary'>Total</h3>
                      <h2 className='text-center'>
                        ${!Number.isNaN(total) ? total.toFixed(2) : 0}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-5 mb-3'>
                <h3 className='fs-4'>Rental Agreements</h3>
                <p className='fs-4 text-gray-600'>
                  Your default rental agreements will be sent out to the customer following the
                  completion of this booking. To make changes to these agreements, head over to the
                  Legal section.”
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <Field
                      type='radio'
                      className='btn-check'
                      name='payment'
                      value='personal'
                      id='kt_create_account_form_account_type_personal'
                    />
                    <label
                      style={{backgroundColor: '#00a3ff1c', border: '1px solid #00A3FF'}}
                      className='btn d-flex justify-content-between mb-3 btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center '
                      htmlFor='kt_create_account_form_account_type_personal'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/Duotone.svg'
                        className='svg-icon-3x me-5'
                      />

                      <span className='d-block fw-bold text-start'>
                        <span className='text-dark fw-bolder d-block fs- mb-2'>
                          Corsa Dash Auto Agreement
                        </span>
                        <span className='text-gray-400  fs-6'>
                          Click review agreements to review our rental agreements before proceeding
                          to booking
                        </span>
                      </span>
                      <a
                        href={
                          legal.filter((item: any) => item.key === 'Rental Agreements')[0]?.document
                        }
                        className='btn btn-lg btn-primary mb-3 fs-7'
                      >
                        Choose Agreement
                      </a>
                      {/* <Link
                        to={legal.filter((item: any) => item.key === 'Refund Policy')[0]?.document}
                        className='btn btn-lg btn-primary mb-3'
                      >
                        Review Agreement
                      </Link> */}
                    </label>
                  </div>
                </div>
              </div>
              <div className='inline-block'>
                <h3 className='fs-4'>Confirm Your Booking</h3>
              </div>

              {/* 
            
            <div data-kt-stepper-element='content'>
              <Step2 customer={customer} broker={broker} role={role} />
            </div> */}

              {/* <div data-kt-stepper-element='content'>
              <Step3
                values={values}
                data={data}
                setFee={setFee}
                fee={fee}
                setTotal={setTotal}
                total={total}
              />
            </div> */}

              {/* <div data-kt-stepper-element='content'>
                <Step4 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 />
              </div> */}

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    // onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light  text-dark me-3'
                    data-kt-stepper-action='previous'
                  >
                    Cancel Booking
                  </button>
                </div>

                <div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary me-3'
                    disabled={hasBooked || loading}
                  >
                    {!loading && <span className='indicator-label'>Book Now</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                    {/* <span className='indicator-label'>Book Now</span> */}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export {CreateRental}
