/* eslint-disable jsx-a11y/anchor-is-valid */
import {ErrorMessage, Field} from 'formik'
import {type} from 'os'
import {FC} from 'react'
type Props = {
  user: any
  role: string
}
const Step1: FC<Props> = ({user, role}) => {
  // console.log(user)
  return (
    <div className='w-100'>
      <div className='text-gray-400 fw-bold fs-6  mb-5' style={{textAlign: 'right'}}>
        Having Issues?
        <a href='/dashboard' className='link-primary fw-bolder'>
          {' '}
          Get Help
        </a>
        .
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className=''>Your Name</span>
            </label>
            <Field
              type='text'
              // value=''
              className='form-control form-control-lg form-control-solid'
              name='accountName'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='accountName' />
            </div>
          </div>
        </div>
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Email Address</span>
          </label>
          <Field
            type='email'
            // value=''
            className='form-control form-control-lg form-control-solid'
            name='email'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='email' />
          </div>
        </div>
        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Password </span>
          </label>
          <Field
            type='password'
            // value=''
            className='form-control form-control-lg form-control-solid'
            name='password'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='password' />
          </div>
        </div>

        <div className='col-lg-12'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Mobile Number</span>
          </label>
          <Field
            type='text'
            // value=''
            className='form-control form-control-lg form-control-solid'
            name='phone'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='phone' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
