/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup} from '../../../../_metronic/layout/core'
import {CardsWidget17, ListsWidget26, TablesWidget13} from '../../../../_metronic/partials/widgets'
import {TablesVehicle} from '../../../../_metronic/partials/widgets/tables/tableVehicle'
import {
  AddTrip,
  filterVehicle,
  getAllBrokerStat,
  getAllVehicle,
  getAllVehicleStat,
} from '../../../modules/auth/core/_requests'
import CarAvailabilityCard from '../../dashboard/Overview/CarAvailabilityCard'
import {initialQueryState, useDebounce} from '../../../../_metronic/helpers'
import {useQueryRequest} from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'

let trips: any[] = []
const Manage: React.FC = () => {
  const [tab, setTab] = useState('Sidebar')
  const [config, setConfig] = useState<ILayout>(getLayoutFromLocalStorage())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)
  const [year, setYear] = useState<string>('')
  const [make, setMake] = useState<string>('')
  const [model, setModel] = useState<string>('')
  const [engine, setEngine] = useState<string>('')
  const [pickUp, setPickUP] = useState<string>('')
  const [DropOff, setDropOff] = useState<string>('')
  type Org = {[key: string]: string}
  const handleFiltersAll = async () => {
    const obj: Org = {}

    if (year !== '') {
      obj['year'] = year
      // obj.mode = mode
    }
    if (model !== '') {
      obj['model'] = model
      // obj.mode = mode
    }
    if (make !== '') {
      obj['make'] = make
      // obj.mode = mode
    }
    if (engine !== '') {
      obj['engine'] = engine
      // obj.mode = mode
    }
    if (pickUp !== '') {
      obj['availibityStartDate'] = pickUp
      // obj.mode = mode
    }
    if (DropOff !== '') {
      obj['availibityEndDate'] = DropOff
      // obj.mode = mode
    }
    // console.log(obj)
    const data = await filterVehicle(obj)
    // console.log(data)
    const startIndex = activePage
    const endIndex = startIndex + 10
    setData(data.data?.vehicles)
    setUpdated(!updated)
    setVehicle(data.data?.vehicles.slice(startIndex, endIndex))
  }
  let [activePage, setActivePage] = useState(1)

  const updateConfig = () => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
      window.location.reload()
    } catch (error) {
      setConfig(getLayoutFromLocalStorage())
      setConfigLoading(false)
    }
  }

  const reset = () => {
    setResetLoading(true)
    setTimeout(() => {
      setConfig(getLayoutFromLocalStorage())
      setResetLoading(false)
    }, 1000)
  }
  const [data, setData] = useState<any>([])
  let [updated, setUpdated] = useState(false)
  const [vehicle, setVehicle] = useState<any>([])
  const [stat, setStatistics] = useState<any>(null)
  const [earning, setEarning] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const allCustomers = async () => {
    setLoading(true)
    try {
      const data = await getAllVehicle()

      const startIndex = activePage - 1
      const endIndex = startIndex + 10
      setData(data.data?.vehicles)
      setVehicle(data.data?.vehicles.slice(startIndex, endIndex))
      data.data?.vehicles.forEach((item: any) => {
        if (item?.trips !== undefined) {
          item?.trips.forEach((element: any) => {
            trips.push(element)
          })
        }
      })
      setLoading(false)
      // console.log('sss', trips)
      let total = 0
      let totalCost: any[] = []
      trips.forEach((item) => {
        totalCost.push(item.total)
        total += item.total
      })
      let min = Math.min(...totalCost)
      let statistice = {
        total: total,
        avgTotal: (total / trips.length).toFixed(2),
        lowCost: min,
        // totalCustomer: data.data?.customerListing?.totalCustomer,
      }
      // console.log(statistice)
      setEarning(statistice)
    } catch (e) {
      setLoading(false)
    }
  }
  console.log('vehicle', vehicle)
  const handlePageChange = () => {
    // console.log(`active page is ${pageNumber}`)
    let num = 0
    const number = activePage * 10
    setActivePage(activePage + 1)
    const startIndex = number
    const endIndex = startIndex + 10

    setVehicle(data.slice(num, endIndex))
  }
  const getAllCustomerStatistics = async () => {
    const data = await getAllVehicleStat()
    // console.log(data)
    let statistice = {
      availableCustomer: data.data?.customerListing?.availableCustomer,
      blockListedCustomer: data.data?.customerListing?.blockListedCustomer,
      notActiveCustomer: data.data?.customerListing?.notActiveCustomer,
      totalCustomer: data.data?.customerListing?.totalCustomer,
      bookedCustomer: data.data?.customerListing?.bookedCustomer,
    }
    // console.log(statistice)
    setStatistics(statistice)
    // setData(data.data?.customers)
  }
  useEffect(() => {
    allCustomers()
    getAllCustomerStatistics()
  }, [])
  useEffect(() => {}, [updated])
  const handleFilter = (val: number) => {
    if (val === 1) {
      let dataNew = data.sort(function (a: any, b: any) {
        if (a.vehicleType < b.vehicleType) {
          return -1
        }
        if (a.vehicleType > b.vehicleType) {
          return 1
        }
        return 0
      })
      setVehicle(dataNew)
      setUpdated(!updated)
    } else if (val === 2) {
      let dataNew = data.sort(function (a: any, b: any) {
        if (a.vehicleType > b.vehicleType) {
          return -1
        }
        if (a.vehicleType < b.vehicleType) {
          return 1
        }
        return 0
      })
      setVehicle(dataNew)
      setUpdated(!updated)
    }
  }
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const handleSearch = (val: string) => {
    // console.log(val.length)
    // if (val !== '') {
    setSearchTerm(val)
    if (val.length !== 0) {
      let dataNew = data.filter((item: any) => item.vehicleType.includes(val))
      setVehicle(dataNew)
      setUpdated(!updated)
    } else if (val.length === 0) {
      // console.log('aya')
      const number = activePage * 10
      // setActivePage(pageNumber)
      const startIndex = number
      const endIndex = startIndex + 10

      setVehicle(data.slice(startIndex, endIndex))
      // setCustomer(customer)
      setUpdated(!updated)
    }

    // }
  }

  return (
    <>
      {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <CardsWidget17 stat={stat} className='h-md-100 mb-5 mb-xl-10' />
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <ListsWidget26 earning={earning} className='h-lg-100' />
        </div>
      </div> */}

      <CarAvailabilityCard
        handleFilter={handleFiltersAll}
        setYear={setYear}
        setMake={setMake}
        setDropOff={setDropOff}
        setModel={setModel}
        setEngine={setEngine}
        setPickUP={setPickUP}
        className='card-xl-stretch mb-8'
      />
      {/* {data != undefined && ( */}
      <TablesVehicle
        className='card-xl-stretch mb-xl-8'
        title=''
        pagination={true}
        data={vehicle}
        actual={data}
        searchTerm={searchTerm}
        length={0}
        setSearchTerm={handleSearch}
        handleFilter={handleFilter}
        handlePageChange={handlePageChange}
        activePage={activePage}
        loading={loading}
      ></TablesVehicle>
      {/* )} */}
    </>
  )
}

export default Manage
