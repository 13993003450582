import * as Yup from 'yup'

export interface ICreateAccount {
  title: string
  description: string
  key: string
  document: string
}

const createAccountSchemas = [
  Yup.object({
    title: Yup.string().required().label('Title Is Required'),
  }),
  Yup.object({
    description: Yup.string().required().label('Description Is Required'),
  }),
]

const inits: ICreateAccount = {
  title: '',
  description: '',
  key: '',
  document: '',
}

export {createAccountSchemas, inits}
