import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import Overview from './Overview'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {useEffect} from 'react'
import {PayNow} from './Overview/paynow'
const stripePromise = loadStripe(localStorage.getItem('publisherKey') as string)

const profileBreadCrumbs: Array<PageLink> = [
  // {
  //   title: 'Home',
  //   path: '/dashboard',
  //   isSeparator: false,
  //   isActive: false,
  // },
  // {
  //   title: 'Vehicle',
  //   path: '/builder',
  //   isSeparator: false,
  //   isActive: false,
  // },
]
const companyName = localStorage.getItem('companyName') as string
const data = new Date().toDateString()
console.log(data)
const DashboardPage = () => (
  <Routes>
    <Route
      path={`overview`}
      element={
        <>
          <h3 className='fs-4'>Dashboard &nbsp;|&nbsp; {data.replace(',', '')} </h3>
          <Elements stripe={stripePromise}>
            <Overview stripePromise={stripePromise} />
          </Elements>
        </>
      }
    />
    <Route
      path={`payNow`}
      // path='payNow'
      element={
        <>
          {/* <PageTitle breadcrumbs={profileBreadCrumbs}>PayNow</PageTitle> */}

          <PayNow />
        </>
      }
    />
    <Route index element={<Navigate to={`/${companyName}/dashboard/overview`} />} />
  </Routes>
)

export default DashboardPage
