import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'

const App = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const user = localStorage.getItem('user')
      console.log(user)
      if (user != null) {
        let dataTemp = JSON.parse(user)
        if (dataTemp.payment === '' && dataTemp.role === 'Company') {
          localStorage.clear()
          window.location.reload()
        }
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          {/* <AuthInit> */}
          <Outlet />
          <MasterInit />
          {/* <h4
            style={{
              background: 'black',
              color: 'white',
              textAlign: 'center',
              fontSize: ' 18px',
              padding: ' 1rem',
              bottom: '0px',
              left: '0px',
              right: '0px',
              marginBottom: '0px',
              position: 'fixed',
            }}
          >
            2023 Corsa Dash All rights reserved
          </h4> */}
          {/* </AuthInit> */}
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
