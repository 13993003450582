import clsx from 'clsx'
import {createBrowserHistory} from 'history'
import {useEffect, useState} from 'react'
import {json} from 'stream/consumers'
import Swal from 'sweetalert2'
import {resend} from '../../../../app/modules/auth/core/_requests'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-50px symbol-md-50px'
const btnIconClass = 'svg-icon-1'
let role = ''
const Navbar = () => {
  const browserHistory = createBrowserHistory()
  const [authenticated, setAuthenticated] = useState(() => {
    return localStorage.getItem('auth_token') ? true : false
  })
  const [user, setUser] = useState<any | null>(null)
  useEffect(() => {
    const data = localStorage.getItem('user')
    if (data != null) {
      let dataTemp = JSON.parse(data)
      setUser(dataTemp)
    }
  }, [])
  console.log('user', user)
  // const handleResend = async () => {
  //   const {
  //     data: {data, message},
  //   } = await resend(user.email)
  //   // console.log(data)
  //   Swal.fire({
  //     timer: 3000,
  //     text: message,
  //     icon: 'success',
  //     showCloseButton: false,
  //     showConfirmButton: false,
  //     showDenyButton: false,
  //   })
  // }

  const handleNavigation = () => {
    const companyName = localStorage.getItem('companyName') as string
    console.log(user.role)
    if (user.role === 'Company') {
      browserHistory.push(`/${companyName}/setting/settings`)
      window.location.reload()
    } else {
      browserHistory.push(`/${companyName}/profile/settings`)
      window.location.reload()
    }
  }

  const logout = () => {
    const data = localStorage.getItem('user')
    if (data != null) {
      let dataTemp = JSON.parse(data)
      role = dataTemp.role
    }
    console.log(role)
    const companyName = localStorage.getItem('companyName')
    if (role === 'Company') {
      console.log('hel2')
      browserHistory.push(`/login`)
      localStorage.clear()
      window.location.reload()
    }
    if (role === 'Admin') {
      browserHistory.push(`/login`)
      localStorage.clear()
      window.location.reload()
    } else if (role === 'Customer') {
      console.log('hel')
      browserHistory.push(`/${companyName}/login`)
      localStorage.clear()
      window.location.reload()
    }
  }
  const {config} = useLayout()
  console.log(user)
  return (
    <>
      <div className='app-navbar flex-shrink-0'>
        {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

        <div className={clsx('app-navbar-item', itemClass)}>
          {/* <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
            <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          </div> */}
        </div>

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}
        <div className='d-flex flex-row align-items-center h-100 ms-2'>
          <div className='d-flex flex-column' onClick={handleNavigation}>
            <p className='text-white m-0 text-end' style={{fontWeight: 600}}>
              {user != null ? user.accountName : ''}
            </p>
            {/* <p className='text-white m-0 text-end'>{user != null ? user.role : ''}</p> */}
          </div>
          <div className={clsx('app-navbar-item', itemClass)}>
            <div
              onClick={handleNavigation}
              className={clsx('cursor-pointer symbol', userAvatarClass)}
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
            >
              <img src={user?.profileImg} alt='' className='bg-black' />
            </div>

            {/* <HeaderUserMenu /> */}
            <div className='menu-item px-5'>
              <a onClick={logout} className='menu-link px-5'>
                Sign Out
              </a>
            </div>
          </div>
        </div>

        {config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-35px h-35px'
              id='kt_app_header_menu_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {Navbar}
