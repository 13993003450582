/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {MockHistory} from '../../../../utils/consts'
import {IHistory} from '../../../../utils/types'
import {Card} from 'react-bootstrap'

type Props = {
  className: string
  data: any
}
const companyName = localStorage.getItem('companyName') as string
const TablesWidget12: React.FC<Props> = ({className, data}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {data?.length === 0 ? (
          <Card style={{borderRadius: '8px'}}>
            <Card.Body>
              <Card.Text>
                <h4>No History Available</h4>
                <p className='text-muted'>
                  <a href='#' className='text-muted me-2 fw-bold' id='kt_user_follow_button'>
                    <span className='indicator-label'>
                      You can add Trips by clicking here{' '}
                      <Card.Link href={`/${companyName}/vehicle/add-Rental`} className=' px-4'>
                        Create Vehicle
                      </Card.Link>
                    </span>
                  </a>
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className=''>CUSTOMER</th>
                  <th className=''>TRIP COST</th>
                  {/* <th className=''>MILEAGE</th> */}
                  <th className=''>DATES</th>
                  <th className=''>STATUS</th>
                  <th className='text-end'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data?.map((history: any) => (
                  <tr key={history._id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to='/crafted/pages/profile/overview'
                            className='text-dark fw-bold text-hover-primary fs-6'
                          >
                            {history?.customerId?.accountName}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className='text-dark fw-bold d-block fs-6'>{history?.total}</p>
                    </td>
                    {/* <td className=''>
                    <p className='text-dark fw-bold d-block fs-6'>{history?.tripId[0].total}</p>
                  </td> */}
                    <td>
                      <p className='text-dark fw-bold d-block fs-6'>
                        {moment(history?.selectedStartDate).format('MMM Do YYYY')}--
                        {moment(history?.selectedEndDate).format('MMM Do YYYY')}
                        {/* {history?.selectedStartDate}--{history?.selectedEndDate} */}
                      </p>
                    </td>
                    <td>
                      <span
                        className={`badge ${
                          history?.status === 'Yet to start'
                            ? 'badge-light'
                            : history?.status === 'Pending'
                            ? 'badge-light'
                            : history?.status === 'In Progress'
                            ? 'badge-light-primary'
                            : history?.status === 'Completed'
                            ? 'badge-light-success'
                            : history?.status === 'Cancelled'
                            ? 'badge-danger'
                            : history?.status === 'Booked'
                            ? 'badge-danger'
                            : ''
                        } fs-7 fw-semibold`}
                      >
                        {history?.status}
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget12}
