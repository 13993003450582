/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {Col, Row} from 'react-bootstrap'

type Props = {
  className: string
  stat: any
  tripTotal: any
  monthsArray: any
  updated: boolean
  current: number
  handleChange: (val: number) => void
}

const ChartsWidget2: React.FC<Props> = ({
  className,
  handleChange,
  stat,
  current,
  monthsArray,
  tripTotal,
  updated,
}) => {
  // console.log(tripTotal)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, monthsArray, tripTotal))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, updated])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-1 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-4 mb-1'>Trip Summary</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <a
            onClick={() => handleChange(0.5)}
            className={
              current === 0.5
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted  px-4 me-1'
            }
            id='kt_charts_widget_2_year_btn'
          >
            12 Hours
          </a>

          <a
            onClick={() => handleChange(1)}
            className={
              current === 1
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted  px-4 me-1'
            }
            id='kt_charts_widget_2_month_btn'
          >
            Day
          </a>

          <a
            onClick={() => handleChange(7)}
            className={
              current === 7
                ? 'btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                : 'btn btn-sm btn-color-muted  px-4 me-1'
            }
            id='kt_charts_widget_2_week_btn'
          >
            Week
          </a>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* start::Stats Cards */}
      <Row className='m-5 mb-0'>
        <Col className='text-center'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder text-success'>Completed Trips </div>
            <div className='fw-bold fs-1 text-dark'>{stat?.completedTrip}</div>
          </div>
        </Col>
        <Col className='text-center'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder text-primary'>Trips In Progress </div>
            <div className='fw-bold fs-1 text-dark'>{stat?.inProgressTrips}</div>
          </div>
        </Col>
        <Col className='text-center'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder text-danger'>Cancelled Trips </div>
            <div className='fw-bold fs-1 text-dark'>{stat?.cancelledTrips}</div>
          </div>
        </Col>
      </Row>

      {/* end::Stats Cards */}

      {/* begin::Body */}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Activity Chart</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-secondary active px-4 me-1'
            id='kt_charts_widget_2_year_btn'
          >
            Agent
          </a>

          {/* <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_2_month_btn'
          >
            Client
          </a> */}

          {/* <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_2_week_btn'
          >
            Week
          </a> */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '305px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget2}

function getChartOptions(height: number, monthsArray: any, tripTotal: any): ApexOptions {
  // console.log(tripTotal, 'kj')
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')

  return {
    series: [
      {
        name: 'Net Profit',
        data: tripTotal,
      },
      {
        name: 'Revenue',
        data: [76, 85, 101, 98, 87, 105],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
