import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {createPrices, login} from '../../../modules/auth/core/_requests'
import {notification} from 'antd'
const initialValues = {
  budgetNotes: '',
  salesTax: '',

  bookingFee: '',
  serviceFee: '',
}
type Props = {
  customer: any
}

const Prices: React.FC<Props> = ({customer}) => {
  console.log(customer)
  initialValues.budgetNotes = customer?.prices.budgetNotes

  initialValues.salesTax = customer?.prices.salesTax
  // initialValues.bookingFeeType = customer?.prices.bookingFeeType
  initialValues.bookingFee = customer?.prices.bookingFee
  initialValues.serviceFee = customer?.prices.serviceFee
  useEffect(() => {
    initialValues.budgetNotes = customer?.prices.budgetNotes

    initialValues.salesTax = customer?.prices.salesTax
    // initialValues.bookingFeeType = customer?.prices.bookingFeeType
    initialValues.bookingFee = customer?.prices.bookingFee
    initialValues.serviceFee = customer?.prices.serviceFee
  }, [initialValues])
  console.log(initialValues)
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: initialValues,
    //   validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const data = await createPrices(values)
        notification.success({
          message: data.data.message,
          onClick: () => {
            console.log('Notification Clicked!')
          },
          duration: 4,
        })
      } catch (e: any) {
        // console.log(e.response.data.message)
        notification.error({
          message: e.response.data.message,
          onClick: () => {
            console.log('Notification Clicked!')
          },
          duration: 4,
        })
        // saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
      }
    },
  })

  console.log({...formik.getFieldProps('salesTax')})
  console.log(formik.values.salesTax)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_notifications'
        aria-expanded='true'
        aria-controls='kt_account_notifications'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Prices and Fee</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/* <div className='row mb-12'>
              <label className='col-lg-4 col-form-label required fw-bold fs-4'>Booking Notes</label>

              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  // place/holder='First name'
                  {...formik.getFieldProps('budgetNotes')}
                  value={{...formik.getFieldProps('budgetNotes')}.value}
                />
                {formik.touched.budgetNotes && formik.errors.budgetNotes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.budgetNotes}</div>
                  </div>
                )}
              </div>
            </div> */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-4'>
                Sales Tax Percentage
              </label>
              <div className='col-lg-8'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  //   placeholder='Last name'
                  {...formik.getFieldProps('salesTax')}
                  // value={formik.values.salesTax}
                  value={{...formik.getFieldProps('salesTax')}.value}
                />
                {formik.touched.salesTax && formik.errors.salesTax && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.salesTax}</div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-4'>
                Booking Fee Type
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  //   placeholder='Company name'
                  {...formik.getFieldProps('bookingFeeType')}
                  value={{...formik.getFieldProps('bookingFeeType')}.value}
                />
                {formik.touched.bookingFeeType && formik.errors.bookingFeeType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.bookingFeeType}</div>
                  </div>
                )}
              </div>
            </div> */}
            {/* 
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-4'>
                <span className='required'>Booking Fee</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  // placeholder='Phone number'
                  {...formik.getFieldProps('bookingFee')}
                  value={{...formik.getFieldProps('bookingFee')}.value}
                />
                {formik.touched.bookingFee && formik.errors.bookingFee && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.bookingFee}</div>
                  </div>
                )}
              </div>
            </div> */}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-4'>
                <span className='required'>Service Fee Percentage</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  // placeholder='Car Insurance Provider'
                  {...formik.getFieldProps('serviceFee')}
                  value={{...formik.getFieldProps('serviceFee')}.value}
                />
                {formik.touched.serviceFee && formik.errors.serviceFee && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.serviceFee}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className='btn btn-transparent text-muted me-2'>Discard</button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {Prices}
