/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTSVG} from '../../../../helpers'

type Props = {
  className: string
  earning: any
}
function addCommas(nStr: string) {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}
const ListsWidget26 = ({className, earning}: Props) => {
  const rows: Array<{description: string; status: 'up' | 'down'; value: string}> = [
    {description: 'Avg. Trip Cost', status: 'up', value: earning?.avgTotal},
    {description: 'Lowest User Check', status: 'down', value: earning?.lowCost},
    {description: 'Avg. Net Profit', status: 'up', value: earning?.avgTotal},
  ]
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            {/* <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span> */}:
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              ${addCommas(earning?.total)}
            </span>
          </div>
          <span className='text-gray-400 pt-3 fw-semibold fs-6'>Total Earnings</span>
        </div>
      </div>
      <div className='card-body pt-5'>
        {rows.map((row, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div className='d-flex flex-stack justify-content-between'>
              <p className='text-gray-800 fw-semibold fs-6 me-2 m-0'>{row.description}</p>
              <p className=' fs-6 fw-bold me-2 m-0'>
                {row.status === 'up' ? (
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr007.svg'
                    className='svg-icon-3 me-1 svg-icon-success'
                  />
                ) : row.status === 'down' ? (
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr006.svg'
                    className='svg-icon-3 me-1 svg-icon-danger'
                  />
                ) : (
                  ''
                )}{' '}
                ${addCommas(row.value)}
              </p>
            </div>
            {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export {ListsWidget26}
