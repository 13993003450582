/* eslint-disable jsx-a11y/anchor-is-valid */
import {createBrowserHistory} from 'history'
import React from 'react'
import {Button, Card} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Spinner from '../../../../app/pages/brokers/components/BrokerTable/Spinner'
type Props = {
  className: string
  data: any
  loading: boolean
}
const companyName = localStorage.getItem('companyName') as string
function addCommas(nStr: string) {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}
const TablesVehicle10: React.FC<Props> = ({className, data, loading}) => {
  // console.log(data)
  const browserHistory = createBrowserHistory()
  const handleClick = (id: any) => {
    localStorage.setItem('id', id)
  }
  const handleRedirect = (id: any) => {
    const companyName = localStorage.getItem('companyName') as string
    browserHistory.push(`/${companyName}/vehicle/add-Rental`)
    window.location.reload()
    handleClick(id)
    // window.location.href('/vehicle/details/availability')
  }
  return (
    <div>
      {/* begin::Body */}
      <div>
        {/* begin::Table container */}
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <div>
            {data.length === 0 ? (
              <Card style={{borderRadius: '8px'}}>
                <Card.Body>
                  <Card.Text>
                    <h4>No Vehicle Available</h4>
                    <p className='text-muted'>
                      <a href='#' className='text-muted me-2 fw-bold' id='kt_user_follow_button'>
                        <span className='indicator-label'>
                          You can add vehicles by clicking here{' '}
                          <Card.Link
                            href={`/${companyName}/vehicle/add-new-vehicle`}
                            className=' px-4'
                          >
                            Create Vehicle
                          </Card.Link>
                        </span>
                      </a>
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            ) : (
              <>
                <div className='row'>
                  {data.map((car: any) => (
                    <div className='col-sm-6 col-md-6 col-lg-4 col-xs-12  mb-5'>
                      <Card style={{borderRadius: '8px'}}>
                        {car.gallery[0] !== '' ? (
                          <Link
                            to={`/${companyName}/vehicle/details/overview`}
                            onClick={() => handleClick(car._id)}
                          >
                            <img
                              style={{borderRadius: '8px'}}
                              src={car.gallery[0]}
                              // height={210}
                              width={'100%'}
                              className='position-relative'
                              alt=''
                            />
                          </Link>
                        ) : (
                          <Link
                            to={`/${companyName}/vehicle/details/overview`}
                            onClick={() => handleClick(car._id)}
                          >
                            <img
                              style={{borderRadius: '8px'}}
                              width={'100%'}
                              src={'/media/placeholderimage.png'}
                              className='position-relative'
                              alt=''
                            />
                          </Link>
                        )}
                        <Button
                          className={`badge position-absolute ${
                            car?.status === 'In Progress'
                              ? 'badge-light-danger'
                              : car?.status === 'Available'
                              ? 'badge-light-success'
                              : car?.status === 'BlockListed'
                              ? 'badge-light-primary'
                              : car?.status === 'Booked'
                              ? 'badge-light-primary'
                              : ''
                          } fs-7 fw-semibold`}
                          style={{top: '10%', left: '70%'}}
                        >
                          {car?.status}
                        </Button>

                        <Card.Body>
                          <Link
                            to={`/${companyName}/vehicle/details/overview`}
                            onClick={() => handleClick(car._id)}
                          >
                            <Card.Text>
                              <h4> {car?.title}</h4>
                              <p className='text-muted'>
                                <a
                                  href='#'
                                  className='text-muted me-2 fw-bold'
                                  id='kt_user_follow_button'
                                >
                                  <span className='indicator-label'>${car.dailyPrice}/day</span>
                                </a>
                              </p>
                            </Card.Text>
                          </Link>
                          <div className='d-flex justify-content-between'>
                            <Button
                              disabled={car?.status === 'In Progress' ? true : false}
                              // href=''
                              onClick={() => handleRedirect(car._id)}
                              className='btn btn-bg-success btn-color-white btn-sm px-4'
                            >
                              Create Rental
                            </Button>
                            <Card.Link
                              onClick={() => handleClick(car._id)}
                              href={`/${companyName}/vehicle/edit-Vehicle`}
                              className='btn btn-bg-light btn-color-muted btn-sm px-4'
                            >
                              Edit Vehicle
                            </Card.Link>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              </>
              // <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              //   <thead>
              //     <tr className='fw-bold text-muted'>
              //       <th className=''></th>
              //       <th className=''>YEAR</th>
              //       <th className=''>MAKE</th>
              //       <th className=''>MODEL</th>
              //       {/* <th className=''>VEHICLE TYPE</th> */}
              //       <th className=''>VIN</th>
              //       <th className=''>Milage</th>
              //       <th className=''>STATUS</th>
              //       <th className='text-end'>DETAILS</th>
              //     </tr>
              //   </thead>

              //   <tbody>
              //     {' '}
              //     {data.map((car: any) => (
              //       <tr key={car.model}>
              //         <td>
              //           <div className='symbol symbol-60px me-5'>
              //             {car.vehicleImg !== '' ? (
              //               <img
              //                 src={car.vehicleImg}
              //                 //   className='rounded-circle'
              //                 alt=''
              //               />
              //             ) : (
              //               <img
              //                 src={'/media/placeholderimage.png'}
              //                 //   className='rounded-circle'
              //                 alt=''
              //               />
              //             )}
              //           </div>
              //         </td>
              //         <td>
              //           <div className='d-flex align-items-center'>
              //             <div className='d-flex justify-content-start flex-column'>
              //               <p className='text-dark fw-bold fs-6'>{car?.year}</p>
              //             </div>
              //           </div>
              //         </td>

              //         <td>
              //           <div className='d-flex justify-content-start flex-column'>
              //             <Link
              //               to='/vehicle/details/availability'
              //               onClick={() => handleClick(car?._id)}
              //               className='text-dark fw-bold text-hover-primary fs-6'
              //             >
              //               {car?.make}
              //             </Link>
              //           </div>
              //         </td>
              //         <td>
              //           <div className='d-flex justify-content-start flex-column'>
              //             <Link
              //               to='/vehicle/details/availability'
              //               onClick={() => handleClick(car?._id)}
              //               className='text-dark fw-bold text-hover-primary fs-6'
              //             >
              //               {car?.model}
              //             </Link>
              //           </div>
              //         </td>
              //         <td>
              //           <div className='d-flex align-items-center'>
              //             <div className='d-flex justify-content-start flex-column'>
              //               <p className='text-dark fw-bold fs-6'>{car?.vin}</p>
              //             </div>
              //           </div>
              //         </td>
              //         <td>
              //           <div className='d-flex align-items-center'>
              //             <div className='d-flex justify-content-start flex-column'>
              //               <p className='text-dark fw-bold fs-6'>{addCommas(car?.milleage)}</p>
              //             </div>
              //           </div>
              //         </td>
              //         <td>
              //           <span
              //             className={`badge ${
              //               car?.status === 'In Progress'
              //                 ? 'badge-light-danger'
              //                 : car?.status === 'Available'
              //                 ? 'badge-light-success'
              //                 : car?.status === 'BlockListed'
              //                 ? 'badge-light-primary'
              //                 : car?.status === 'Booked'
              //                 ? 'badge-light-primary'
              //                 : ''
              //             } fs-7 fw-semibold`}
              //           >
              //             {car?.status}
              //           </span>
              //         </td>
              //         <td className='text-end'>
              //           <Link
              //             to='/vehicle/details/availability'
              //             onClick={() => handleClick(car?._id)}
              //             className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
              //           >
              //             Action
              //           </Link>
              //         </td>
              //       </tr>
              //     ))}
              //   </tbody>
              //   {/* end::Table body */}
              // </table>
            )}
          </div>
        )}
        {/* end::Table */}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesVehicle10}
