/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {createBrowserHistory} from 'history'
import {GETTRIPBYID, TripByID, getAllTeam} from '../../modules/auth/core/_requests'
import {Button, Card} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {trips} from '../../../utils/consts'
// import {getCSSVariableValue} from '../../../../assets/ts/_utils'
// import {Dropdown1} from '../../content/dropdown/Dropdown1'
// import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
//

const DetailTrips: React.FC = ({}) => {
  const [team, setTeam] = useState<any>([])
  let id = localStorage.getItem('id') as string
  const getAllCustomerByIds = async () => {
    const data = await TripByID(id)
    setTeam(data.data.trips)
    console.log('data', data)
  }
  useEffect(() => {
    getAllCustomerByIds()
  }, [])
  const handleClick = (id: any) => {
    localStorage.setItem('id', id)
  }
  console.log(team)
  const browserHistory = createBrowserHistory()
  const handleRedirect = (id: any) => {
    const companyName = localStorage.getItem('companyName') as string
    browserHistory.push(`/${companyName}/vehicle/add-Rental`)
    window.location.reload()
    handleClick(id)
    // window.location.href('/vehicle/details/availability')
  }
  return (
    <div className='row g-5 g-xl-12  '>
      <div>
        <div className='d-flex justify-content-end mb-5'>
          <Button
            // disabled={car?.status === 'In Progress' ? true : false}
            // href=''
            onClick={() => handleRedirect(team.vechicleId._id)}
            className='btn btn-bg-success btn-color-white btn-sm px-4'
          >
            Create Rental
          </Button>
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col-sm-4 '>
          <Card style={{borderRadius: '8px'}}>
            <Card.Body>
              <Card.Text className='text-muted'>
                <h4 className='p-3'>Order Details</h4>
                <div className='row p-3' style={{borderBottom: '1px solid #d1d1d1c2'}}>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    Date Added
                  </div>
                  <div className='col-sm-6'>{team[0]?.createdAt.split('T')[0]}</div>
                </div>
                <div className='row p-3' style={{borderBottom: '1px solid #d1d1d1c2'}}>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    Payment Method
                  </div>
                  <div className='col-sm-6'>Online</div>
                </div>
                <div className='row p-3'>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    Vehicle Name
                  </div>
                  <div className='col-sm-6'>{team[0]?.vechicleId.title}</div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className='col-sm-4 '>
          <Card style={{borderRadius: '8px'}}>
            <Card.Body>
              <Card.Text className='text-muted'>
                <h4 className='p-3'>Customer Details</h4>
                <div className='row p-3' style={{borderBottom: '1px solid #d1d1d1c2'}}>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    Customer
                  </div>
                  <div className='col-sm-6'>
                    {team[0]?.onwerId.firstName}&nbsp;{team[0]?.onwerId.lastName}
                  </div>
                </div>
                <div className='row p-3' style={{borderBottom: '1px solid #d1d1d1c2'}}>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    &nbsp; Email
                  </div>
                  <div className='col-sm-6'>{team[0]?.onwerId.email}</div>
                </div>
                <div className='row p-3'>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    Phone
                  </div>
                  <div className='col-sm-6'>{team[0]?.onwerId.phone}</div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className='col-sm-4 '>
          <Card style={{borderRadius: '8px'}}>
            <Card.Body>
              <Card.Text className='text-muted'>
                <h4 className='p-3'>Document</h4>
                <div className='row p-3' style={{borderBottom: '1px solid #d1d1d1c2'}}>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    Rental Aggreement
                  </div>
                  <div className='col-sm-6'>Completed</div>
                </div>
                <div className='row p-3' style={{borderBottom: '1px solid #d1d1d1c2'}}>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    Insurance Policy
                  </div>
                  <div className='col-sm-6'>
                    {team[0]?.onwerId.CarInsuranceStatus ? 'Verified' : 'Not Verified'}
                  </div>
                </div>
                <div className='row p-3'>
                  <div className='col-sm-6'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-1 svg-icon-muted'
                    />
                    Payment
                  </div>
                  <div className='col-sm-6'>
                    {team[0]?.onwerId.paymentCleared ? 'Cleared' : 'Not Cleared'}
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className='row p-5'>
        {' '}
        <Card style={{borderRadius: '8px'}}>
          <Card.Body>
            <Card.Text className='text-muted'>
              <h4 className='p-0'>Rental</h4>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className=' '>
                    <th>Vehicle Name</th>
                    <th>Unit Price</th>
                    <th>Qty</th>
                    <th></th>
                    <th>Total</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <p className='text-dark  text-hover-muted fs-6 mb-0'>
                        {team[0]?.vechicleId?.title}{' '}
                      </p>
                      <p className='text-muted  text-hover-muted fs-7'>
                        Rental Date: {team[0]?.selectedStartDate}
                        {'- '} {team[0]?.selectedEndDate}
                      </p>
                    </td>
                    <td>
                      <a href='#' className='text-muted  text-hover-muted fs-6'>
                        $ {team[0]?.vechicleId?.dailyPrice}
                      </a>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'></p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'></p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'>
                        {' '}
                        ${team[0]?.vechicleId?.dailyPrice}
                      </p>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <a href='#' className='text-dark  text-hover-muted fs-6'>
                        Same Delivery Date
                      </a>
                    </td>
                    <td></td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'></p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'>$ 500</p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'>$ 500</p>
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <a href='#' className='text-muted  text-hover-muted fs-6'></a>
                    </td>
                    <td></td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'></p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'>Sub Total</p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'>
                        {parseFloat(team[0]?.serviceFee) + parseFloat(team[0]?.salesTax)}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-muted  text-hover-muted fs-6'></a>
                    </td>
                    <td></td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'></p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'>Service Fee</p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'> ${team[0]?.serviceFee}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-muted  text-hover-muted fs-6'></a>
                    </td>
                    <td></td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'></p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'>Sales Tax </p>
                    </td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'> ${team[0]?.salesTax}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-muted  text-hover-muted fs-6'></a>
                    </td>
                    <td></td>
                    <td>
                      <p className='text-muted  d-block mb-1 fs-6'></p>
                    </td>
                    <td>
                      <p className='text-dark fw-bold  d-block mb-1 fs-6'>Grand Total</p>
                    </td>
                    <td>
                      <p className='text-dark fw-bold   d-block mb-1 fs-6'> ${team[0]?.total}</p>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </Card.Text>
          </Card.Body>
        </Card>{' '}
      </div>
    </div>
  )
}

export {DetailTrips}
